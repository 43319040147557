import React, { useRef, useState, useEffect } from 'react';
import NavBar from './NavBar'
import './App.css';

function Contact() {
  const [nameVal, setN] = useState('');
  const [titleInp, setT] = useState('');
  const [bodyInp, setD] = useState('');


  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Contact Us</p>
      </header>

      <div className="App">
        <section >
          <div className="contact-container">
            <form action="https://api.web3forms.com/submit" method="POST" className='contact-left'>

              <input type="hidden" name="access_key" value="8e92cf29-8dc8-47a9-8b0f-431a50b8bfcf" />
              <label style={{ color: "white" }}>If you are using the contact form to buy a card please mention the name of the card in the description</label>
              <br></br>
              <input type="text" name="name" placeholder='Your Name' required className="contact-inputs" /> <br></br>
              <input type="email" name="email" placeholder='Your Email' required className="contact-inputs" /><br></br>
              <textarea name="message" placeholder="Your Message" required className="contact-inputs"></textarea>
              <br></br>
              <button type="submit">Submit Form</button>

            </form>
          </div>

        </section>
      </div>
    </>
  );
}

export default Contact;
