import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article5() {

  return (
    <>
      <NavBar />

      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <header><img
            src="https://cdn.discordapp.com/attachments/1092690018193121290/1180081550356918372/image.png?ex=657c1f96&is=6569aa96&hm=f748e7db9bbe80b1333dae63127a475a7f215dc70ac49951a2d5bc575443e7df&" />
          </header>
          <br></br>
          <h2><span style={{ fontWeight: "bold" }}>ARTICLE:</span> Grab That Slab #1: Slabs for under $50 USD
          </h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-30">November 30, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
          <p>&#160;</p>
          <p>&#160;</p>

          <p>Card collections come in all shapes and sizes. Some like to have their cards stored safely in a binder like
            myself. But some likes graded cards, also know as 'slabs.' And if you are looking to start collections these
            plastic preserved Pokémon it might seem outrageously expensive but I'm here to help! I'm gonna start featuring a
            list of different slabs, all 10s, all under $50. So let's begin!

          </p>
          <br></br><br></br>
          <p><b>1. Mew (VSTAR Universe, 183/172) PSA 10: $35 </b>
          </p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1179247579649814579/IMG_2787.png?ex=657916e4&is=6566a1e4&hm=5eedb02b4ee016e248bce2428632aa441d618bce01ca54e52dcfd2267043b316&"
            alt="" className="images" />
          <br></br>
          <p>
            Featuring an adorable mew sleeping as other Pokémon watch, you'll be hard pressed to find someone who doesn't
            like it. It's the kind of card you will always notice something new whenever you look at it.

          </p>
          <br></br><br></br>
          <p><b>
            2. Alcremie VMax (Shining Fates, 073/072) PSA 10: $30
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1179247580299927642/IMG_2788.jpg?ex=657916e4&is=6566a1e4&hm=73c1c8c553ba742959eb896e619043436cdf6af58b85b8e49562ae20617a17c6&"
            alt="" className="images" />
          <br></br>
          <p>Everyone's favorite ice cream Pokémon stands tall as a colorful towering cake! With Rainbow Rares being retired
            since the end of the Sword and Shield this is a good time to start looking at adding them to your collection and
            at only $30 this is a nice stares into the world of rainbow cards.

          </p>
          <br></br><br></br>
          <p><b>3. Blissey V (Silver Tempest, TG22/TG30) PSA 10: $31
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1179247580643872818/IMG_2789.webp?ex=657916e4&is=6566a1e4&hm=f3eef053a6176f351c8823755e2ea03f702216bd3b2638895bfdfd27f49bc893&"
            alt="" className="images" />
          <br></br>
          <p>Long before Paldea gave us the Pokémon equivalent of a rest stop, we had the iconic and all important Pokémon
            Center which were largely staffed by Nurse Joy and a Chansey or Blissey. And this card captures what most people
            think of when it's time to take a break and heal their team.
          </p>
          <br></br><br></br>
          <p><b>4. Hisuian Zoroark VSTAR (VSTAR Universe, 234/172) PSA 10: $37
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1179247581038125086/IMG_2790.jpg?ex=657916e4&is=6566a1e4&hm=c5ad274f2200ccbbf43ec2d0268f4d2ad5520477a4531d18d712d24598bbfc58&"
            alt="" className="images" />
          <br></br>
          <p>I really don't have much to say on this card, the art speaks for itself with the environment around Zoroark
            almost coming alive the longer you look at it.


          </p>
          <br></br><br></br>
          <p><b>5. Cynthia's Ambition (Brilliant Stars, 169/172) CGC 10: $35

          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1179247581386264657/IMG_2791.webp?ex=657916e4&is=6566a1e4&hm=07e9042cb036b8898b3f67cff9a127fd90c698970c448bcf6cf6aefe68099a21&"
            alt="" className="images" />
          <br></br>
          <p>I wanted to end on at least one CGC 10 and I decided to pick what is arguably one of if not the strongest
            regional champion to date (I don't care what the anime says.) She's beautiful, she's powerful, and for less than
            the cost of an ETB you can have her in a 10.


          </p>
          <br></br><br></br>
          <p>And that's it? Did you like this article? Do you have a special type of card or grading company you want me to
            look at for the next edition? Email us at CollectingCommunity@gmail.com and let us know!
          </p>
          <br></br><br></br>
        </section>
      </main>
    </>
  )
}

export default Article5;
