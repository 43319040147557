import React from 'react'
import './articlesfeed.css';
import NavBar from './NavBar'
import { Link } from 'react-router-dom'


function Articles() {

  const search_card = async () => {
    let input = document.getElementById('searchbar').value
    input = input.toLowerCase();
    let x = document.getElementsByClassName('article');

    for (var i = 0; i < x.length; i++) {
      if (!x[i].innerHTML.toLowerCase().includes(input)) {
        x[i].style.display = "none";
      }
      else {
        x[i].style.display = "list-item";
      }
    }
  }

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Staff Articles</p>
      </header>

      <div className="center-container">
        <div className="search-container">
          <input id="searchbar" onKeyUp={search_card} type="text" name="search" placeholder="Search Article..."></input>
        </div>
      </div>
      <main className="articles">

        <section className="article-list">
          <article className="article">
            <img
              src="https://cdn.discordapp.com/attachments/884994368912629790/1201728524738633869/Untitled_design_5.png?ex=65cadfe7&is=65b86ae7&hm=f44d05f8103e6d14b198d568682e79ab6dfde59cdacfaf5b7e7c2c30b7de0895&"
              alt="Gard" className="headimage" />
            <br></br><br></br><br></br>
            <h2><Link to="/articles/article8">Article: Bargain Beauties #3</Link></h2>
            <p>Published on: <time datetime="2023-11-24">January 29, 2024</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> W3nderful</p>
            <br></br>
            <p>Hi shiny cardboard collectors! Here...</p>
            <Link to="/articles/article8">Read more...</Link>
          </article>
          <article className="article">
            <img
              src="https://cdn.discordapp.com/attachments/884994368912629790/1189065415104659536/Untitled_design_4.png?ex=659cce76&is=658a5976&hm=596b7d4535412aadeff7a56408a9171db3d7c073d7c90a9d22d938e2b4389a35&"
              alt="Gard" className="headimage" />
            <br></br><br></br><br></br>
            <h2><Link to="/articles/article7">Article: Bargain Beauties #2</Link></h2>
            <p>Published on: <time datetime="2023-11-24">December 26, 2023</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
            <br></br>
            <p>Special Illustration Rares...</p>
            <Link to="/articles/article7">Read more...</Link>
          </article>
          <article className="article">
            <img
              src="https://cdn.discordapp.com/attachments/884994368912629790/1182923504329437264/IMG_2838.jpg?ex=6586765d&is=6574015d&hm=87d1495193efec2f88d60c7062e435f0687a406b4fccaad91f3c7f8a7d59fdb3&"
              alt="Gard" className="headimage" />
            <br></br><br></br><br></br>
            <h2><Link to="/articles/article6">Article: I Choose You, Charizard: Why does Charizard...</Link></h2>
            <p>Published on: <time datetime="2023-12-08">December 8, 2023</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
            <br></br>
            <p>Whether you are a longtime Pokémon fan...</p>
            <Link to="/articles/article6">Read more...</Link>
          </article>
          <article className="article">
            <img
              src="https://cdn.discordapp.com/attachments/884994368912629790/1180438261315944488/Untitled_design_3.png?ex=657d6bcc&is=656af6cc&hm=3a8b853afc682806e02447cf12d711b5fb8885d4fe4f09c1601698f881914f7e&"
              alt="Gard" className="headimage" />
            <br></br><br></br><br></br>
            <h2><Link to="/articles/article5">Article: Grab That Slab #1</Link></h2>
            <p>Published on: <time datetime="2023-11-30">November 30, 2023</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
            <br></br>
            <p>Card collections come in all shapes...</p>
            <Link to="/articles/article5">Read more...</Link>
          </article>
          <article className="article">
            <img
              src="https://cdn.discordapp.com/attachments/867970381426880562/1178810656942739517/Untitled_design_2.png?ex=65777ff9&is=65650af9&hm=b899423069cca7e5a49e5bf3fcb16ac0b43e8fb853e75faac2315fc194fc8044&"
              alt="Gard" className="headimage" />
            <br></br><br></br><br></br>
            <h2><Link to="/articles/article4">Article: Bargain Beauties #1</Link></h2>
            <p>Published on: <time datetime="2023-11-24">November 24, 2023</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
            <br></br>
            <p>Card collecting is expensive and...</p>
            <Link to="/articles/article4">Read more...</Link>
          </article>
          <article className="article">
            <img
              src="https://cdn.discordapp.com/attachments/884994368912629790/1175741861743300608/IMG_2732.png?ex=656c55f0&is=6559e0f0&hm=8299b9cf7758f4f01fcbf614edd0722dc9225f09563fbdd7023e198f202c088e&"
              alt="Gard" className="headimage" />
            <br></br><br></br><br></br>
            <h2><Link to="/articles/article3">Article: It Ain't Easy Being Mean...</Link></h2>
            <p>Published on: <time datetime="2023-11-19">November 19, 2023</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
            <br></br>
            <p>One of the mainstays of the Pokémon games ...</p>
            <Link to="/articles/article3">Read more...</Link>
          </article>


          <article className="article">
            <img
              src="https://cdn.discordapp.com/attachments/884994368912629790/1173168867850326046/IMG_2691.jpg?ex=6562f9a6&is=655084a6&hm=33ed40bd5e00ebd6795a5f0d66fc6802cbd35721a26ded699c48c58ca750f44b&"
              alt="Gard" className="headimage" />
            <h2><Link to="/articles/article2">Article: When everything is premium nothing is...</Link></h2>
            <p>Published on: <time datetime="2023-11-11">November 11, 2023</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
            <br></br>
            <p>If you have been a part of the Pokémon TCG hobby ...</p>
            <Link to="/articles/article2">Read more...</Link>
          </article>

          <article className="article">
            <img
              src="https://cdn.discordapp.com/attachments/867970381426880562/1170560855067349154/Untitled13_20231104201027.png?ex=65597cbf&is=654707bf&hm=1315107be5536ed6e86153e47f6a779224783da5f4ddd1f1fa0a41eed59bdfaf&"
              alt="Gard" className="headimage" />
            <h2><Link to="/articles/article1">Article: Paradox Rip-Off? ...</Link></h2>
            <p>Published on: <time datetime="2023-11-04">November 4, 2023</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
            <br></br>
            <p>Paradox Rift has finally arrived bringing ...</p>
            <Link to="/articles/article1">Read more...</Link>
          </article>
        </section>

        <header>
          <p>Collecting<span >Community</span> Community Articles</p>
        </header>

        <section className="article-list">
          <article className="article">
            <img
              src="https://archives.bulbagarden.net/media/upload/thumb/0/01/Gen_VII_Pseudo_Legendary_Artwork.png/300px-Gen_VII_Pseudo_Legendary_Artwork.png"
              alt="Gard" className="headimage" />
            <br></br><br></br>
            <h2><Link to="/articles/article7_com">Community Article: Who could be a Pseudo-Legendary?</Link></h2>
            <p>Published on: <time datetime="2023-12-27">January 29, 2024</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> Pokeviolins</p>
            <br></br>
            <p>As I decide which non-pseudo best...</p>
            <Link to="/articles/article7_com">Read more...</Link>
          </article>
          <article className="article">
            <img
              src="https://play-lh.googleusercontent.com/3UpKaqsS-3LDEQJqoNLXkj61eiA-_-h77heP22dYOy-WR4PSha3O_tPK57w4wZ4jIXII"
              alt="Gard" className="headimage" />
            <br></br><br></br>
            <h2><Link to="/articles/article6_com">Community Article: Pokémon Mobile Games</Link></h2>
            <p>Published on: <time datetime="2023-12-27">December 27, 2023</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> Pokeviolins</p>
            <br></br>
            <p>You want a Pokémon game...</p>
            <Link to="/articles/article6_com">Read more...</Link>
          </article>
          <article className="article">
            <img
              src="https://i.redd.it/6kekbegmlcz81.png"
              alt="Gard" className="headimage" />
            <br></br><br></br>
            <h2><Link to="/articles/article5_com">Community Article: Rating Pika Copies</Link></h2>
            <p>Published on: <time datetime="2023-12-08">December 8, 2023</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> Pokeviolins</p>
            <br></br>
            <p></p>
            <Link to="/articles/article5_com">Read more...</Link>
          </article>
          <article className="article">
            <img
              src="https://i.ytimg.com/vi/buvmz3yJuNQ/sddefault.jpg"
              alt="Gard" className="headimage" />
            <br></br><br></br>
            <h2><Link to="/articles/article4_com">Community Article: Pokémon Game Mechanics Ranked</Link></h2>
            <p>Published on: <time datetime="2023-12-08">December 8, 2023</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> Dayne Hultman</p>
            <br></br>
            <p>In this article I...</p>
            <Link to="/articles/article4_com">Read more...</Link>
          </article>
          <article className="article">
            <img
              src="https://hips.hearstapps.com/digitalspyuk.cdnds.net/16/08/1456483171-pokemon2.jpg"
              alt="Gard" className="headimage" />
            <br></br><br></br>
            <h2><Link to="/articles/article3_com">Community Poem: Gotta Catch 'Em All, the Adventure Await</Link></h2>
            <p>Published on: <time datetime="2023-12-08">December 8, 2023</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> Croustylle</p>
            <br></br>
            <p>A Pikachu with rosy cheeks...</p>
            <Link to="/articles/article3_com">Read more...</Link>
          </article>
          <article className="article">
            <img
              src="https://cdn.discordapp.com/attachments/867970381426880562/1182921286058512394/IMG_2837.webp?ex=6586744c&is=6573ff4c&hm=0ef01d1642fe41521b58763095609e67ec36068add5bdf8b3474857809f937e7&"
              alt="Gard" className="headimage" />
            <br></br><br></br>
            <h2><Link to="/articles/article2_com">Community Poem: W3nderful Haikus</Link></h2>
            <p>Published on: <time datetime="2023-12-08">December 8, 2023</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> W3nderful</p>
            <br></br>
            <p>Watch CC read...</p>
            <Link to="/articles/article2_com">Read more...</Link>
          </article>

          <article className="article">
            <img
              src="https://cdn.discordapp.com/attachments/1089607481342300202/1178126956768804975/6804f94f08de92dd38c664a70a6decfa.png?ex=6575033b&is=65628e3b&hm=7e7a54f6ee9d8c961dfc75ab1eb5111dc3555303bd22c6cd3f74f3773a8bca3e&"
              alt="Gard" className="headimage" />
            <br></br><br></br>
            <h2><Link to="/articles/article1_com">Community Article: Top 10 Most Overshadowed Pokémon in Gen 1</Link></h2>
            <p>Published on: <time datetime="2023-11-25">November 25, 2023</time></p>
            <p><span style={{ fontWeight: "bold" }}>Author:</span> Pokeviolins</p>
            <br></br>
            <p></p>
            <Link to="/articles/article1_com">Read more...</Link>
          </article>

        </section >
      </main>
    </>
  )
}

export default Articles;
