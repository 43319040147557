
import React, { useRef, useState, useEffect } from 'react';

import { Routes, Route, Link } from 'react-router-dom'
import NavBar from './NavBar'
import Store from './Store'
import App from "./App"
import Articles from './Articles'
import Rules from './Rules'
import Events from './Events'
import Commands from './Commands'
import Members from './Members'
import Patches from './Patches'
import About from './About'
import Article1 from './Article1'
import Article2 from './Article2'
import Article3 from './Article3'
import Article4 from './Article4'
import Article5 from './Article5'
import Article6 from './Article6'
import Article7 from './Article7'
import Article8 from './Article8'
import Article1_com from './Article1_com'
import Article2_com from './Article2_com'
import Article3_com from './Article3_com'
import Article4_com from './Article4_com'
import Article5_com from './Article5_com'
import Article6_com from './Article6_com'
import Article7_com from './Article7_com'
import AdminApp from './adminApp'
import Suggest from './Suggest'
import Contact from './Contact'



function Home() {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/commands" element={<Commands />} />
      <Route path="/members" element={<Members />} />
      {/* <Route path="/store" element={<Store />} /> */}
      <Route path="/articles" element={<Articles />} />
      <Route path="/rules" element={<Rules />} />
      <Route path="/events" element={<Events />} />
      <Route path="/updates" element={<Patches />} />
      <Route path="/about" element={<About />} />
      <Route path="/articles/article1" element={<Article1 />} />
      <Route path="/articles/article2" element={<Article2 />} />
      <Route path="/articles/article3" element={<Article3 />} />
      <Route path="/articles/article4" element={<Article4 />} />
      <Route path="/articles/article5" element={<Article5 />} />
      <Route path="/articles/article6" element={<Article6 />} />
      <Route path="/articles/article7" element={<Article7 />} />
      <Route path="/articles/article8" element={<Article8 />} />


      <Route path="/articles/article1_com" element={<Article1_com />} />
      <Route path="/articles/article2_com" element={<Article2_com />} />
      <Route path="/articles/article3_com" element={<Article3_com />} />
      <Route path="/articles/article4_com" element={<Article4_com />} />
      <Route path="/articles/article5_com" element={<Article5_com />} />
      <Route path="/articles/article6_com" element={<Article6_com />} />
      <Route path="/articles/article7_com" element={<Article7_com />} />

      <Route path="/admin" element={<AdminApp />} />
      <Route path="/suggest" element={<Suggest />} />
      <Route path="/contact" element={<Contact />} />




    </Routes>
  )
}

export default Home