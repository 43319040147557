import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article3() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <h2><span style={{ fontWeight: "bold" }}>ARTICLE:</span> It Ain't Easy Being Mean: The Downfall of 'Evil' Teams
          </h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-19">November 19, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1175741861743300608/IMG_2732.png?ex=656c55f0&is=6559e0f0&hm=8299b9cf7758f4f01fcbf614edd0722dc9225f09563fbdd7023e198f202c088e&"
            alt="" className="images" />
          <p>
            One of the mainstays of the Pokémon games is the presence of a team of villains who commit nefarious crimes
            using Pokémon which they often stole from good honest people. Kanto and Johto suffered under Team Rocket and
            Giovanni who used Pokémon to make money in unethical ways (slowpoketails anyone?). Hoenn was caught in a war
            between Team Aqua and Team Magma who wanted to terraform the world into a waterless desert or endless ocean.
            Sinnoh was under attack by Team Galactic and it's leader Cyrus who wanted to destroy the universe and remake it
            into a cold emotionless and spiritless place with him as it's new god. And Ghetsis manipulated the orphan N and
            his gift to communicate with Pokémon along with the naivety of the well-meaning Team Plasma members with
            promises to 'liberate' Pokémon from their trainers, but not for the better treatment of Pokémon but so he would
            be the only one with Pokémon at his command and he could rule the region. And when that fails he tries to freeze
            everything in ice and is just an overall jerk.
            <br></br><br></br>
            However during the journey through Kalos we start to see a slight decline in the evilness of would-be villains
            with Lysandre and Team Flare wanting to get rid of humans to return the world to its pristine natural beauty.
            And while certainly no one will argue that genocide isn't evil, the fact he seems to largely be motivated by
            caring about nature and the environment just makes him slightly less selfish than Ghetsis and Cyrus. Then we see
            a sharper drop still in Alola with Guzma and Team Skull who's entire evil plan is largely just being annoying to
            those taking the island trials because they don't like it, really the worst thing about them is their butchering
            of human language. Then comes Team Yell who are the Galarian equivalent of the soccer hooligan stereotype. Their
            goal was trying to stop trainers from battling the gym leaders so Marnie would be the only one able to challenge
            Leon, which implies they think Marnie is terrible and can't beat any rivals in which case how would she beat
            Leon?
            <br></br><br></br>
            This brings us to Paldea and Team Star, Team Star are a bunch of students from your new school who other than
            being painfully dorky even by evil team standards really aren't antagonistic. They are a little heavy handed in
            their recruitment but largely the group sticks to their various bases and don't cause any real issues in Paldea.
            They have no villainous goals, they don't steal, they aren't that antagonistic when you challenge them and after
            one defeat they give up and don't swear vengeance. And the backstory of their leaders is a bunch of students got
            bullied, the school failed to help, and then they stood up to their bullies and everyone was like “woah now, how
            dare you defend yourself against be bullied?!” Then they drop out and other similar outcast students follow them
            and thus Team Star is formed. So you're telling me the 'evil' team is a bunch of bullied students ignored by the
            adults who feel the school has no place for them and will never really welcome them, and rather than talk I have
            to go into their bases and beat them up to make them come back to what is clearly not a great school. Really I'm
            the bad guy here.
            <br></br><br></br>
            Now Team Star isn't all bad, the designs and personalities of the leaders were cool and I would have loved to
            see them as gym leaders or Elite 4 members instead of poor Larry having to do double-duty along with the crybaby
            teacher. But I feel like part of the Pokémon games fun was being a hero and saving the world from some grand
            threat perpetrated by nefarious organizations that seem to lurk in the shadows of every city you visit. Alola at
            least had The Aether Foundation and Team Rainbow Rocket, and Galar had Chairman Rose. But Paldea? The big
            villain isn't even revealed till the very end which happens to be a robot who wants you to beat it to keep time
            traveling Pokémon from escaping Area Zero, because Jigglypuff with a ponytail is really going be more dangerous
            than a Gardevoir that can conjure black holes. The quest to help Arven save his Mabostiff was more interesting
            and it had some sense of urgency with the “I gotta save this poor hurt Pokémon.”
            <br></br><br></br>
            Now that being said I wonder if it's just me, maybe people like the more chill storylines and I am just being an
            old fuddy-duddy. I'd love to hear you comments and thoughts. Do you agree or disagree? Do you think I got
            something wrong? Do you have ideas on what could make the plots of future games more interesting? Let us know at
            CollectingCommunity@gmail.com and it might make it into a future series where I read mail from the community.

          </p>
        </section>
      </main>

    </>
  )
}

export default Article3;