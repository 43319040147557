import React, { useRef, useState, useEffect } from 'react';
import NavBar from './NavBar'
import './App.css';


const { Octokit } = require("@octokit/core");

const octokit = new Octokit({
  auth: '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'
})

function Suggest() {
  const [nameVal, setN] = useState('');
  const [titleInp, setT] = useState('');
  const [bodyInp, setD] = useState('');


  const onSubmit = async (e) => {
    e.preventDefault();
    await octokit.request('POST /repos/{owner}/{repo}/issues', {
      owner: 'SuperViolinsADS',
      repo: 'collectorscorner.github.io',
      title: titleInp + " - " + nameVal,
      body: bodyInp,
    })
    setT('');
    setD('');
    setN('');
    alert("Thank you for your feedback!");
  }

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Suggestions</p>
      </header>

      <div className="App">
        <section className="contact-container">
          <form onSubmit={onSubmit} className="contact-left">
            <label className="signin">Submit a Bug Report or Feature Suggestion</label><br></br><br></br>
            <input
              type="text"
              required
              value={nameVal}
              onChange={(e) => setN(e.target.value)}
              placeholder='Your Name' className="contact-inputs"></input><br></br><br></br>
            <input
              type="text"
              required
              value={titleInp}
              onChange={(e) => setT(e.target.value)}
              placeholder="Bug/Feature Title" className="contact-inputs"></input><br></br><br></br>
            <textarea
              type="text"
              required
              value={bodyInp}
              onChange={(e) => setD(e.target.value)}
              placeholder="Bug/Feature Description" className="contact-inputs"></textarea><br></br><br></br>
            <button type="submit">Submit</button>
          </form>

        </section>
      </div>
    </>
  );
}

export default Suggest;
