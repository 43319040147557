import React from 'react'
import './patches.css';
import NavBar from './NavBar'


function Patches() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Updates</p>
      </header>
      <main className="updates">
        <section className="update">
          <h2><span style={{ fontWeight: "bold" }}>PATCH NOTES 2.1.0:</span></h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2024-02-08">March 1, 2024</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Authors:</span> SuperViolinsADS</p>
          <p>&#160;</p>
          <p>We're back once again with our next patch notes! Some really awesome new functionality coming in this one!</p>
          <p>&#160;</p>
          <h3>New Features</h3>
          <p>Holiday Game Updates</p>
          <p>Developer Note: <i>We've made several new updates to the holiday game, increasing the number of puzzle pieces required to complete puzzle cards, but also significantly increasing the puzzle chances.</i></p>
          <br></br>
          <p>Sign in UI improvements</p>
          <p>Developer Note: <i>We've improved the UI for signing into the chat, to look nicer and easier to read</i></p>
          <br></br>
          <p>Mining Game Updates</p>
          <p>Developer Note: <i>We've introduced many new powerups to the mining game where you can save up your CC Coins in order to purchase some amazing new powerups. These will give you a massive boost in progressing to upgrade your pickaxe!</i></p>
          <br></br>
          <p>New !sellpuzzle command</p>
          <p>Developer Note: <i>We have introduced a brand new way for you to sell your puzzle pieces for diamonds. That means if you don't want a specific puzzle card or already have it, you can repurpose the diamonds to obtain a different puzzle card in the future!</i></p>
          <br></br>
          <p>New Replying Functionality (Beta)</p>
          <p>Developer Note: <i>This is the big one of this update! When you hover over a message (or tap it in mobile), you will see a little arrow icon pop up next to that message. You can then click or tap that icon to
            reply to that message. Then the next message you send will show that you are replying to that message in the chat. Simply click or tap the (x) in the reply preview box to cancel the reply. Please let us know if you encounter any issues with this feature since it's still in Beta. </i></p>
          <br></br>
          <h3>Bug Fixes</h3>
          <br></br>
          <p>Fixed a bug causing images to stop showing for a lot of people.</p>
          <br></br>
          <h3>Known Bugs</h3>
          <p>The Youtube Feed page is down right now. We are working on a fix for this</p>
          <p>If two people send messages at the same time, you may see two holiday game spawns at the same time. This doesn't affect the functionality of the game and is only a visual bug</p>
          <p>Similar to the above bug, when two people send battle choices at the same time, the game goes into a deadlocked mode. Unfortunately the only way to fix this is to cancel the battle and retry.</p>
          <p>If you put a space, tab or enter before a command, the command won't register</p>
        </section>
        <br></br><br></br>
        <section className="update">
          <h2><span style={{ fontWeight: "bold" }}>PATCH NOTES 2.0.0:</span></h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2024-02-08">February 8, 2024</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Authors:</span> SuperViolinsADS</p>
          <p>&#160;</p>
          <p>We're back once again with our next patch notes! We know it's been a while since the last one, but we've been hard at work. This is one of the biggest updates to the site to date! Hope you have been enjoying all the brand new features. Many more to come with some really exciting plans in the future.</p>
          <p>&#160;</p>
          <h3>New Features</h3>
          <p>New Commands Reference Page</p>
          <p>Developer Note: <i>On this page, we have detailed information about all commands available in the chat as well as explanations of all the site games.</i></p>
          <br></br>
          <p>Members Page</p>
          <p>Developer Note: <i>On this page we list all our Membership perks, links to sign up and a thank you section where we thank all our supporting members!</i></p>
          <br></br>
          <p>!help command</p>
          <p>Developer Note: <i>Easily sends you a link to the command reference page for help with commands and games</i></p>
          <br></br>
          <p>Member and Admin badges</p>
          <p>Developer Note: <i>We've added badges for all the members (as well as name colors). Furthermore, all admins have a unique custom badge</i></p>
          <br></br>
          <p>Rules have been updated - Please read the rules :)</p>
          <br></br>
          <p>!rules command</p>
          <p>Developer Note: <i>Sends a link to the rules page for quick access</i></p>
          <br></br>
          <p>New articles</p>
          <p>Developer Note: <i>Check out W3nderful's first ever article as part of the site admin team! Furthermore, we also have a brand new community article</i></p>
          <br></br>
          <p>Store removed and repurposed</p>
          <p>Developer Note: <i>With the introduction of site memberships, we've decided to remove the store and repurpose the cards we had listed there for other future plans</i></p>
          <br></br>
          <p>New Announcements and Mail Room Channels</p>
          <p>Developer Note: <i>We have created new technology to sending-restricted channels. Announcements will be where all
            important site announcements are posted and only site mods can send announcements. Additionally, we have the Mail Room channel, where CC will post pictures of cards as he mails them out.

            These awesome new channels with new technology may make it possible to soon have more interesting channels, such as a member-exclusive channel 👀</i></p>
          <br></br>
          <p>!upgradereq and !upgrade details</p>
          <p>Developer Note: <i>We've made a small visual upgrade to the !upgrade and !upgradereq commands. When you successfully upgrade your pickaxe, an image of your new pickaxe will display in the chat as well as your badge changing.
            Furthermore, if you are not a mod or member, as you level up, your name will start to change colors at certain levels! Finally, the !upgradereq command will show you your current pickaxe as well as the pickaxe you are trying to obtain in your next upgrade.</i></p>
          <br></br>
          <p>Reward ads in the mining game</p>
          <p>Developer Note: <i>We have extended reward ads to the mining game now, so if you get unlucky and roll nothing when mining, you will be given the opportunity to mine again after viewing an ad.</i></p>
          <br></br>
          <p>Puzzle piece purchases</p>
          <p>Developer Note: <i>We've introduced some brand new awesome functionality allowing you to spend diamonds from the holiday game to purchase puzzle pieces.</i></p>
          <br></br>
          <p>Announcement bar</p>
          <p>Developer Note: <i>This new bar will appear in all channels with realtime udpates containing any important announcements made on the site. You will be able to hide the announcement once you see it.</i></p>
          <br></br>
          <p>Custom Art Listings</p>
          <p>Developer Note: <i>We've listed some example custom art pieces for purchase in the About Us page. Come visit if you're interested in buying some custom art!</i></p>
          <br></br>
          <p>Brand new !buypuzzle command</p>
          <p>Developer Note: <i>We have introduced a new command that allows you to buy puzzle pieces in the holiday game using diamonds. More details are in the Commands Reference</i></p>
          <br></br>
          <p>Puzzle chance increase</p>
          <p>Developer Note: <i>This upcoming week, we are focusing the holiday game on puzzles! Due to this, we have doubled the chance of getting puzzle pieces in the holiday game. Enjoy!</i></p>
          <br></br>
          <p>HUGE Mining Game Updates</p>
          <p>Developer Note: <i>We have made some major updates to the mining game. Many times we have seen furnaces spawn while nobody can do anything with them. Now we have added a feature that allows you to use the furnace
            to craft materials. Using the !craft command, you will be able to use some of the materials you own to craft better materials. You can do this if a furnace spawns and you don't have enough items to upgrade your pickaxe.
            However, there are some limits on what you are allowed to craft based on your pickaxe level, so don't go trying to craft diamonds if you haven't upgraded your pickaxe enough.
            Another major update to the mining game is that you can start earning CC Coins through the game. You can see how many coins you have using !bal.
            We have also introduced a brand new shop which will contain various mining game powerups that you can buy using CC Coins. To see the shop use !shop and to buy from it use !buy [id] using the id of the item you want to buy from the shop.
            We've only added one powerup so far but more awesome stuff will come in future updates!!</i>
          </p>
          <h3>Bug Fixes</h3>
          <p>Removed notifications from many channels, which helps improve chat loading lag.</p>
          <br></br>
          <p>The 3 dot dropdown menu now disappears after deleting messages</p>
          <br></br>
          <h3>Known Bugs</h3>
          <p>The Youtube Feed page is down right now. We are working on a fix for this</p>
          <p>If two people send messages at the same time, you may see two holiday game spawns at the same time. This doesn't affect the functionality of the game and is only a visual bug</p>
          <p>Similar to the above bug, when two people send battle choices at the same time, the game goes into a deadlocked mode. Unfortunately the only way to fix this is to cancel the battle and retry.</p>
          <p>If you put a space, tab or enter before a command, the command won't register</p>
        </section>
        <br></br><br></br>
        <section className="update">
          <h2><span style={{ fontWeight: "bold" }}>PATCH NOTES 1.9.0:</span></h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2024-01-18">January 18, 2024</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Authors:</span> SuperViolinsADS</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <h3>New Features</h3>
          <p>New !repeatpokemoji and !repeattrivia Commands!</p>
          <p>Developer Note: <i>These brand new commands let you see the current trivia and pokemoji question that may have gotten lost in the chat.</i></p>
          <br></br>
          <p>The Return of Live Chat</p>
          <p>Developer Note: <i>Since we had issues with lag before and have now implemented a fix for it, we have brought back the live chat!</i></p>
          <br></br>
          <p>New !raffle giveaway commands</p>
          <p>Developer Note: <i>We have brought our own version of the Streamlabs !raffle setup to the site! During livestreams you may see an image and some text telling you to enter the raffle using the new !raffle command!</i></p>
          <br></br>
          <p>Update to Message Buttons</p>
          <p>Developer Note: <i>We've made a much needed update to the delete message buttons and converted it to the 3 dots you know and love, built with animation!</i></p>
          <br></br>
          <p>Scrolling Navigation Bar</p>
          <p>Developer Note: <i>The nav bar was getting a little bulky so we've shrunken it down to be more managable and uses a scroll bar.</i></p>
          <br></br>
          <p>New Contact Us Page</p>
          <p>Developer note: <i>We've added a brand new Contact us page where you can contact us directly via email! Note you can currently also use this to purchase some select cards showing up in
            holiday game ads!</i></p>
          <br></br>
          <p>Brand New Pickaxe Game!</p>
          <p>Developer Note: <i>NOW THE MOMENT YOU'VE ALL BEEN WAITING FOR!!!! Many of you have asked "What is coal for?" Well this game is your answer! How does the game work?
            Similar to the holiday game, you will see spawns of various materials in the Games channel. Then your job is to use !mine to mine these materials. You will have a chance to get the
            material as well as some xp. As your xp increases, your mining level increases. Sometime in a future update, your name will change color every 5 levels. Then you may see a furnace spawn instead of
            one of the materials. This is your opportunity to upgrade your pickaxe! Every pickaxe upgrade has an item requirement. You can see this by typing !upgradereq. To upgrade your pickaxe, you type !upgrade.
            Why upgrade your pickaxe? Well, certain items can't be mined by certain pickaxe levels. This means you need to upgrade your pickaxe in order to mine higher level materials. Better materials
            also have a chance to award higher amounts of xp! Finally to see your level, type !lvl.</i></p>
          <br></br>
          <h3>Bug Fixes</h3>
          <br></br>
          Chat shouldn't lag as much anymore, so we won't have to clear it all the time. However, older messages will not be visible. In the future we'll make a way for you to load older messages
          <h3>Known Bugs</h3>
          <p>The 3 dots dropdown menu doesn't disappear after deleting a message.</p>
          <p>The Youtube Feed page is down right now. We are working on a fix for this</p>
          <p>If two people send messages at the same time, you may see two holiday game spawns at the same time. This doesn't affect the functionality of the game and is only a visual bug</p>
          <p>Similar to the above bug, when two people send battle choices at the same time, the game goes into a deadlocked mode. Unfortunately the only way to fix this is to cancel the battle and retry.</p>
          <p>If you put a space, tab or enter before a command, the command won't register</p>
        </section>
        <br></br><br></br>
        <section className="update">
          <h2><span style={{ fontWeight: "bold" }}>PATCH NOTES 1.8:</span></h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2024-01-10">January 10, 2024</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Authors:</span> SuperViolinsADS</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <h3>New Features</h3>
          <p>Brand New Channels!</p>
          <p>Developer Note: <i>We've added brand new channels this week! The Self-Promo channel where you can tell everyone about your brand new YouTube videos and Showcase channels, where you can show off your pulls and your art! We've also converted
            the Live Chat channel to a Counting channel for the time being.</i></p>
          <br></br>
          <p>Claim command</p>
          <p>Developer Note: <i>Type !claim to see information on how to claim your prizes!</i></p>
          <br></br>
          <p>General Command Update</p>
          <p>Developer Note: <i>Commands are no longer case-sensitive. You now can write !shake, !SHAKE, or even !ShaKE</i></p>
          <br></br>
          <p>Holiday Game Updates</p>
          <p>Developer Note: <i>We've made some new updates to the holiday game including a brand new format for leaderboards. Furthermore, we have a new feature that allows you
            to get another shake if you view an ad. These shakes do expire when the next spawn happens though.</i></p>
          <br></br>
          <p>Notifications For All Channels</p>
          <p>Developer Note: <i>We recently added notifications to the Games channel, and now this week we've added them for all channels!</i></p>
          <br></br>
          <p>Brand new game! Pokemoji</p>
          <p>Developer Note: <i>We have a brand new game, which was suggested by PokeTemp (Awesome suggestion btw). When you use the !pokemoji command, you will get a random
            question with a few emojis representing a Pokemon. Your goal is to !guess what pokemon it is. So if you think it's Squirtle, write !guess squirtle and then
            Chopi will tell you if you are correct!</i></p>
          <h3>Bug Fixes</h3>
          <br></br>
          <p>Fixed bug that sends 2 messages at once due to lag.</p>
          <h3>Known Bugs</h3>
          <p>The Youtube Feed page is down right now. We are working on a fix for this</p>
          <p>If two people send messages at the same time, you may see two holiday game spawns at the same time. This doesn't affect the functionality of the game and is only a visual bug</p>
          <p>Similar to the above bug, when two people send battle choices at the same time, the game goes into a deadlocked mode. Unfortunately the only way to fix this is to cancel the battle and retry.</p>
          <p>If you put a space, tab or enter before a command, the command won't register</p>
        </section>
        <br></br><br></br>
        <section className="update">
          <h2><span style={{ fontWeight: "bold" }}>PATCH NOTES 1.7:</span></h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2024-01-04">January 4, 2024</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Authors:</span> SuperViolinsADS</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <p>Welcome to a brand new year! Happy New Year everybody! We have so many new exciting things coming soon and can't wait to share with you all!</p>
          <br></br><br></br>
          <h3>New Features</h3>
          <br></br>
          <p>Holiday Game Updates</p>
          <p>Developer Note: <i>We've made some new updates to the holiday game including a brand new puzzle leaderboard. We also now keep track of coal since this will be used
            in the future with a brand new feature we're working on! Stay tuned! We're really excited about this. The holiday game will also now stop giving puzzle pieces when someone
            finishes the puzzle. You will instead get 2 diamonds if you happen to roll for a puzzle piece.</i></p>
          <br></br>
          <p>Site Version Checker</p>
          <p>Developer Note: <i>In order for new site updates to take effect, you need to refresh the page. This often becomes an issue for bug fixes.
            As a result, we've built in a brand new smart system that is able to detect when you're on an outdated version of the website and tells you to refresh the site before you can
            continue using it. This will make sure all bugs that are fixed will be fixed for everyone so we don't have to spam the chat to ensure everyone refreshes the page!</i></p>
          <br></br>
          <p>Games Channel</p>
          <p>Developer Note: <i>We have a brand new Games Channel! This is where you can play the holiday game, trivia and any more future games we add to the site. We also have added functionality restricting commands to specific channels
            No more holiday spam in the General channel or battle spam outside the Battles channel.</i></p>
          <br></br>
          <p>Chat Disable Functionality</p>
          <p>Developer Note: <i>We've added a new system that allows us to disable the chat without disabling the rest of the website. This will allow you to still use the website while we're working on fixing a major chat bug.</i></p>
          <br></br>
          <p>General Backend Upgrades</p>
          <p>Developer Note: <i>We've upgraded our backend to be able to handle more members and more chatting. This makes sure the chat doesn't completely stop working when we reach too much activity. Reads won't be limiting us anymore!</i></p>
          <br></br>
          <p>Textbox Changes</p>
          <p>Developer Note: <i>We've added new functionality to the chat textbox that will automatically resize it as your message gets longer, so you can see more of your message text. Hopefully this helps improve your experience using the chat.</i></p>
          <br></br>
          <p>Suggestions Page</p>
          <p>Developer Note: <i>We now have a brand new Suggestions page! Here you can submit any suggestions and report any bugs to us and they will be sent directly to us so we can review them. We've already received a few awesome suggestions!!</i></p>
          <br></br>
          <p>Basic Notifications</p>
          <p>Developer Note: <i>We've added a brand new notification function to the Games channel, mainly for the holiday game. Now if you are in any other channel and a new message is sent in the Games channel, you will see a little notification icon indicating that a new message has appeared in that channel!
            While this basic functionality is currently only available in the Games channel, we'll soon be adding this to all channels so you'll always know if someone is sending messages elsewhere!</i></p>
          <br></br>
          <h3>Bug Fixes</h3>
          <br></br>
          <p>Scroll should now lock when you scroll up and a new message is sent. You can press the New Message button to scroll back down when a new message is received</p>
          <br></br>
          <p>Accidental banning: We have put in place many additional blocks to ensure people aren't banned on accident</p>
          <br></br>
          <p>Fixed a bug causing an infinite spawn loop for the holiday game</p>
          <br></br>
          <p>Fixed a bug allowing anybody to delete Chopi's messages</p>
          <br></br>
          <h3>Known Bugs</h3>
          <p>Sometimes, 2 messages are sent when you hit the send button due to a lag spike</p>
          <p>The Youtube Feed page is down right now. We are working on a fix for this</p>
          <p>If two people send messages at the same time, you may see two holiday game spawns at the same time. This doesn't affect the functionality of the game and is only a visual bug</p>
          <p>Similar to the above bug, when two people send battle choices at the same time, the game goes into a deadlocked mode. Unfortunately the only way to fix this is to cancel the battle and retry.</p>
        </section>
        <br></br><br></br>
        <section className="update">
          <h2><span style={{ fontWeight: "bold" }}>PATCH NOTES 1.6:</span></h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-12-26">December 26, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Authors:</span> SuperViolinsADS</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <br></br><br></br>
          <h3>New Features</h3>
          <p>Trivia</p>
          <p>Developer Note: <i>We've add a new trivia game to the site! Use !trivia to get a new question from our pool of over 200 questions! Then to pick your answer use !pick and the number corresponding to it. Enjoy!</i>
          </p>
          <br></br>
          <p>Brand New Staff Article</p>
          <p>Developer Note: <i>We have another new article on the article page posted by our very own Savin Brass. This
            marks the launch of a brand new article series, "Grab That Slabs"! Check it
            out! We also have a new article about why so many charizard cards are printed from Savin's perspective!
            Finally, we have the second installment of the Bargain Beauties series!</i></p>
          <br></br>
          <p>New Community Articles</p>
          <p>Developer Note: <i>We have added a bunch of new articles and poems to the site all submitted by you all from the community!</i></p>
          <br></br>
          <p>Subscription form</p>
          <p>Developer Note: <i>We have now added a form to the about us page of the site as well as the chat page where you can submit an email to receive our weekly newsletter!</i></p>
          <br></br>
          <p>Site Optimization</p>
          <p>Developer Note: <i>We have put in countless hours to make the site experience as smooth as possible. In order to do that, we've completely updated the behind the scenes code on the site
            so it works more smoothly</i></p>
          <br></br>
          <p>Holiday Game</p>
          <p>Developer Note: <i>Now introducing the HOLIDAY GAME!!! You have all probably heard about this amazing new game on the site. Delibirds will frequently spawn on the site and it is your job to shake to find some loot. Maybe you'll find some diamonds. Maybe a puzzle piece. Maybe just coal. Each week during this holiday season, CC will be hosting giveaways where the top collectors in this game will win an amazing card!</i></p>
          <h3>Bug Fixes</h3>
          <br></br>
          <p>Fixed some battle functional bugs with admin cancelling.</p>
          <br></br>
          <p>Fixed a style bug with inconsistent formatting of the dropdown page bar</p>
          <br></br>
          <p>Fixed a bug with the discord invite link sent by the !discord command</p>
          <br></br>
          <p>We believe we have identified the cause of the lag in Live Chat. We have re-enabled it, but if it starts to lag
            again, we will disable again. Future updates are in the works meant to fully address and prevent future
            occurrences of this kind of lag.</p>
          <br></br>
          <h3>Known Bugs</h3>
          <p>Scroll does not lock when you scroll up and a new message is sent</p>
          <p>Sometimes, 2 messages are sent when you hit the send button due to a lag spike</p>
          <p>The Youtube Feed page is down right now. We are working on a fix for this</p>
          <p>Accidental banning: We will put in place many additional blocks to ensure people aren't banned on accident</p>
        </section>
        <br></br><br></br>
        <section className="update">
          <h2><span style={{ fontWeight: "bold" }}>PATCH NOTES 1.5:</span></h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-27">November 27, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Authors:</span> SuperViolinsADS</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <p>Hey everyone! Welcome to another week at CollectingCommunity! We have a HUGE new update this week and lots of
            exciting things happening soon!</p>
          <br></br><br></br>
          <h3>New Features</h3>
          <p>Battle Game Images</p>
          <p>Developer Note: <i>The battle game now has images depicting what happens in the battle! Hope you like em!</i>
          </p>
          <br></br>
          <p>!chopi command</p>
          <p>Developer Note: <i>You have a question and believe in Chopi himself more than the magic 8 ball? Go ahead and
            ask Chopi by using the !chopi command!</i></p>
          <br></br>
          <p>Battle Data Migration</p>
          <p>Developer Note: <i>We have now migrated the data used for battle commands to a new database! This is sure to
            significantly reduce the number of reads required, which you may have heard us talking about reads in chat
            during streams. This is also opening up exciting new opportunities as we migrate more data to this new
            database. No more ending the stream early because we have too many reads!!!!</i></p>
          <br></br>
          <p>Community Articles Section</p>
          <p>Developer Note: <i>We have added a brand new section to our Articles page, the Community Articles section!!
            This section will consist of
            articles submitted to us by the community. We already have a brand new article submitted by my very own
            brother, Pokeviolins! Hope you can check it out. We strongly encourage you to submit your own articles to us
            and we will start to see more articles pop up on the site from you guys!
          </i></p>
          <br></br>
          <p>Brand New Staff Article</p>
          <p>Developer Note: <i>We have another new article on the article page posted by our very own Savin Brass. This
            marks the launch of a brand new article series, "Bargain Beauties"! Check it
            out!</i></p>
          <br></br>
          <p>CollectingCommunity Marketplace Beta</p>
          <p>Developer Note: <i>This week we are launching the beta for our CollectingCommunity Marketplace! Come by and
            check it out! We are putting up some cards that you can check out! And very soon, we will be seeing more major
            updates to the Marketplace such as a working payment system and cart system. Stay tuned for exciting
            updates!</i></p>
          <br></br>
          <p>Marketplace and Article Searching System</p>
          <p>Developer Note: <i>Along with the release of new content updates in the Marketplace and Articles pages, we also
            have a brand new functional update thanks to Valor's hard work! We now have a functional search bar in both
            pages that lets you search for cards and articles!</i></p>
          <br></br>
          <h3>Bug Fixes</h3>
          <br></br>
          <p>Fixed some battle functional bugs with admin cancelling.</p>
          <br></br>
          <p>Fixed a style bug with inconsistent formatting of the dropdown page bar</p>
          <br></br>
          <p>Fixed a bug with the discord invite link sent by the !discord command</p>
          <br></br>
          <p>We believe we have identified the cause of the lag in Live Chat. We have re-enabled it, but if it starts to lag
            again, we will disable again. Future updates are in the works meant to fully address and prevent future
            occurrences of this kind of lag.</p>
          <br></br>
          <h3>Known Bugs</h3>
          <p>Scroll does not lock when you scroll up and a new message is sent</p>
          <p>Sometimes, 2 messages are sent when you hit the send button due to a lag spike</p>
          <p>The Youtube Feed page is down right now. We are working on a fix for this</p>
        </section>
        <br></br><br></br>
        <section className="update">
          <h2><span style={{ fontWeight: "bold" }}>PATCH NOTES 1.4:</span></h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-04">November 20, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Authors:</span> SuperViolinsADS</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <h3>New Features</h3>
          <p>Battle Game</p>
          <p>Developer Note: <i>Chopi can now host a new battle game! In this game, you can challenge your friends to a
            battle and duke it out to see who wins. To challenge someone type "!battle name". For example, if I want to
            challenge CC, I would write "!battle CC" in the chat. Then to accept the battle write "!accept". The game will
            continue to prompt you further afterwards! This simple Rock-Paper-Scissor style game allows you to choose your
            battle type. May the best player win!
          </i></p>
          <br></br>
          <p>About Us Page</p>
          <p>Developer Note: <i>Here you can find information about the CollectingCommunity team, our primary roles and what
            we do to develop the site.</i></p>
          <p>New Article!</p>
          <br></br>
          <h3>Known Bugs</h3>
          <p>Scroll does not lock when you scroll up and a new message is sent</p>
          <p>Sometimes, 2 messages are sent when you hit the send button due to a lag spike</p>
          <p>The Youtube Feed page is down right now. We are working on a fix for this</p>
          <p>Live chat is lagging a lot, so I've temporarily disabled it til we can locate the source of the problem</p>
        </section>
        <br></br><br></br>
        <section className="update">
          <h2><span style={{ fontWeight: "bold" }}>PATCH NOTES 1.3:</span></h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-04">November 16, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Authors:</span> SuperViolinsADS and Savin Brass</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <h3>New Features</h3>
          <p>Markdown capabilities in chat</p>
          <p>Developer Note: <i>This brand new feature allows everyone to apply some cool formatting to messages! Now links
            posted will
            become clickable! You can also bold, italicize, or strikethrough your text. And many other interesting new
            formatting features are available with this update.
          </i></p>
          <br></br>
          <p>Events page</p>
          <p>Developer Note: <i>We've got an all new events page! This page will keep you up to date on all upcoming
            conventions and meet ups for the Pokémon enthusiast. Currently we will only be showing in-person events but
            will
            expand to online events as well as covering other collecting related conventions.</i></p>
          <br></br>
          <h3>Known Bugs</h3>
          <p>Scroll does not lock when you scroll up and a new message is sent</p>
          <p>Sometimes, 2 messages are sent when you hit the send button due to a lag spike</p>
          <p>The Youtube Feed page is down right now. We are working on a fix for this</p>
        </section>
        <br></br><br></br>
        <section className="update">
          <h2><span style={{ fontWeight: "bold" }}>PATCH NOTES 1.2:</span></h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-04">November 11, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> SuperViolinsADS</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <h3>New Features</h3>
          <p>Patch Notes!!</p>
          <p>Developer Note: <i>We've decided to start creating weekly updates through patch notes describing all the new
            features
            for that
            week as well as bug fixes!</i></p>
          <br></br>
          <p>New Article in the Articles page</p>
          <br></br>
          <h3>Bug Fixes</h3>
          <p>Fixed bug preventing images from being uploaded in messages in all chat channels</p>
          <br></br>
          <h3>Known Bugs</h3>
          <p>Scroll does not lock when you scroll up and a new message is sent</p>
          <p>Sometimes, 2 messages are sent when you hit the send button due to a lag spike</p>
        </section>
        <br></br><br></br>
        <section className="update">
          <h2><span style={{ fontWeight: "bold" }}>PATCH NOTES 1.1:</span></h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-04">November 9, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> SuperViolinsADS</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <h3>New Features</h3>
          <p>Image Upload Enabled for all channels</p>
          <p>Developer Note: <i>Last week, we enabled image upload in the Video Giveaway channel, so now it has been enabled
            for all 3 chat channels</i></p>
          <br></br>
          <p>CHOPI the chat bot</p>
          <p>Developer Note: <i>We have added a new bot that responds to various different commands! Currently available
            public commands include <br></br>
            <b>!roll</b>: Allows you to roll a dice between two numbers <br></br>
            <b>!8ball</b>: Allows you to query the magic 8ball to get a response <br></br>
            <b>!discord</b>: Sends the CollectingCommunity Discord link in the chat<br></br>
            <b>!hug</b>: You hug someone/something <br></br>
            Furthermore, some admin commands have been enabled to run giveaways, sending some images you may have seen in
            the chat
          </i>
          </p>
          <br></br>
          <p>Name coloring</p>
          <p>Developer Note: <i>Now names can be colored! Based on roles certain names in the chat will now be colored! As
            more roles are added,
            we can include more name colors!
          </i></p>
          <br></br>
          <p>Collapsable Navigation Bar</p>
          <p>Developer Note: <i>The navigation bar was causing many weird visual bugs when viewing the site in mobile, we've
            changed it to be collapsable, hopefully helping
            mobile users!
          </i>
          </p>
          <br></br>
          <p>Mobile Scaling</p>
          <p>Developer Note: <i>We've noticed that the mobile view of the site is super skewed, so we've worked to rescale
            the site to be better on mobile. Still have some work to do on this, but it should be considerably better than
            before</i></p>
          <br></br>
          <p>Link Livestream to Live Chat</p>
          <p>Developer Note: <i>We know it's tough to have to keep switching between our chat and the YouTube live stream,
            so now we've linked the
            live stream directly in house inside the live chat! So now you can hang out in our live chat while still
            viewing CollectingCommunity livestreams!
          </i></p>
          <br></br>
          <p>Articles!</p>
          <p>Developer Note: <i>Now introducing the Articles Page!! Featuring a feed of all our articles on the site!
            Currently we have one article written by our very own Savin Brass! Check it out!</i></p>
          <br></br>
          <h3>Bug Fixes</h3>
          <p>Attempted to help address a bug of sending multiple messages when an message send takes too long, this is most
            apparent for large image uploads</p>
          <br></br>
        </section>
      </main>
    </>
  )
}

export default Patches;
