import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article7_com() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <h2><span style={{ fontWeight: "bold" }}>ARTICLE: Who could be a Pseudo-Legendary?</span>
          </h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-12-08">January 29, 2024</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> PokeViolins</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <p>
            As I decide which non-pseudo best could be a pseudo, I have some reasons and rules to follow on who can be eligible.
          </p>
          <br></br>
          <p><b>Rule 1:</b> The Pokémon must be a three stage Pokémon due to the fact that all real pseudo are. So even though Arcanine was one of my original thoughts, it's disqualified.
          </p>
          <br></br>
          <p><b>Rule 2:</b> This Pokémon cannot be a starter Pokémon, or a legendary Pokémon due to the fact that they already have rules in the games and anime. I thought of Marshtomp and Charizard, but they don't count.
          </p>
          <br></br>
          <p><b>Rule 3:</b> This rule could be slightly excused, but the Pokémon overall should be either a dragon type, or some sort of powerful and monster-y Pokémon.
          </p>
          <br></br>
          <p><b>Pokemon that I thought about: </b>Machamp, Rhyperior, Gardevoir, Flygon, Luxray, Conkeldurr, Seismitoad, Eelektross, Haxorus, Coalossal, Grimmsnarl, Garganacl
          </p>
          <br>
          </br>
          <h4>5. Grimmsnarl
          </h4>
          <br></br>
          <img
            src="https://assets.pokemon.com/assets/cms2/img/pokedex/full/861.png"
            alt="" className="images" />
          <br></br>
          <p>
            Grimmsnarl definitely looks like a beast and monster that could definitely be a Pseudo. It even looks like it's extremely powerful, and it having the dark and fairy type adds to it being a special Pokémon. It's a Pokémon that someone could certainly be afraid of, and I feel that it fits all the rules and would make a great Pseudo, especially with the creativity of the Pokémon and the difference between each stage of evolution.

          </p>
          <br></br>
          <h4>4. Eelektross
          </h4>
          <br></br>
          <img
            src="https://assets.pokemon.com/assets/cms2/img/pokedex/full/604.png"
            alt="" className="images" />
          <br></br>
          <p>You might be questioning why this Pokémon is here, but it feels like a powerful beast Pokémon. An electric eel is a very special animal, and the use of a Pokémon with it is great. If I'm up to date Eelektross' evolution line is the only one with electric and water type. This Pokémon might not fit my third rule perfectly, but I'd say it partly qualifies as a beast. </p>
          <br></br>
          <h4>3. Rhyperior
          </h4>
          <br></br>
          <img
            src="https://assets.pokemon.com/assets/cms2/img/pokedex/detail/464.png"
            alt="" className="images" />
          <br></br>
          <p>This huge Pokémon was already massively powerful at its first stage, but as it evolves twice its power doubles. Rhyperior is one of the most powerful Pokémon, and that should already qualify it to be a Pseudo. The Pokémon itself is massive and it has decent typings in power which together lead it to this list. When I found out the Rhydon had an evolution I was struck, and only a Pseudo-Legendary should be that powerful.
          </p>
          <br></br>
          <h4>2. Flygon
          </h4>
          <br></br>
          <img
            src="https://assets.pokemon.com/assets/cms2/img/pokedex/full/330.png"
            alt="" className="images" />
          <br></br>
          <p>You guys all knew this was going to be on this list. Not only does it have the dragon type, but it's extremely powerful, and fits the description perfectly. It's basically a beast Pokémon and it has the perfect typings of dragon and ground, not t9 mention that it can fly like practically every Pseudo-Legendary. If Flygon weren't on this list I don't know what would be.

          </p>
          <br></br>
          <h4>1. Haxorus
          </h4>
          <br></br>
          <img
            src="https://assets.pokemon.com/assets/cms2/img/pokedex/full/612.png"
            alt="" className="images" />
          <br></br>
          <p>I honestly thought this Pokémon was a Pseudo-Legendary when I saw it, and to this day, I don't understand why it isn't one. It is a powerful beast that levels up accordingly and it's a dragon type. This Pokémon has high stats and is extremely powerful. There's no reason for it to not be a Pseudo-Legendary. It has three stages, it's a dragon type, and it's definitely not a starter or legendary. Haxorus without a doubt should be a Pseudo-Legendary.
          </p>
          <br></br>
        </section>
      </main>

    </>
  )
}

export default Article7_com;
