import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article3_com() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <h2><span style={{ fontWeight: "bold" }}>POEMS:
          </span> Gotta Catch 'Em All, the Adventure Await</h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-12-08">December 8, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> Croustylle</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <img
            src="https://hips.hearstapps.com/digitalspyuk.cdnds.net/16/08/1456483171-pokemon2.jpg"
            alt="" className="images" />
          <p>A Pikachu with rosy cheeks,</p>
          <p>A Charmander with a flame that speaks,</p>
          <p>A Squirtle with a shell so blue,</p>
          <p>A Bulbasaur with a flower anew.</p>
          <br></br>
          <p>These are the Pokemon that bring us joy,</p>
          <p>With their powers, they're never coy.</p>
          <p>They light up our days and make us smile,</p>
          <p>And make our lives all the more worthwhile.</p>
          <br></br>
          <p>With Pikachu's thunderbolt and Charmander's fire,</p>
          <p>Squirtle's water gun and Bulbasaur's vine whip,</p>
          <p>We can conquer any challenge that comes our way,</p>
          <p>And find happiness in every single day.</p>
          <br></br>
          <p>So let's catch 'em all and hold em tight,</p>
          <p>And never let go of that shining light.</p>
          <p>For with Pokemon by our side,</p>
          <p>We'll always have happiness as our guide.</p>
          <br></br>

        </section>
      </main>

    </>
  )
}

export default Article3_com;
