import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article6_com() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <h2><span style={{ fontWeight: "bold" }}>ARTICLE: </span> Pokémon Mobile Games
          </h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-12-27">December 27, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> PokeViolins</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <p>You want a Pokémon game that you can play on mobile for free? Well guess what! There are plenty of games for Pokémon that you can just simply download on your phone!
          </p>
          <br>
          </br>
          <h4>Pokémon Go
          </h4>
          <br></br>
          <img
            src="https://upload.wikimedia.org/wikipedia/en/thumb/c/c2/Pok%C3%A9mon_Go_-_screenshot_of_map.png/159px-Pok%C3%A9mon_Go_-_screenshot_of_map.png"
            alt="" className="images" />
          <br></br>
          <p>Let's start with the most common one. In Pokémon Go, you venture around
            to collect all the Pokémon you can. A Pokémon shows up on the screen,
            and you tap on it. After that, you throw the PokeBall, Great Ball, Ultra
            ball, or even Master Ball to catch the Pokémon! After you catch it, you
            catch more to get candies so you can evolve it! You can also use berries
            to help catch the Pokémon. Razz berries make it easier to catch. Pinap
            berries help you receive more candies from it. Finally, the Nanab berry
            makes the Pokémon move a lot less. There are always events going on which
            increases the chances to catch specific Pokémon at that time. There are
            three teams you choose from, and whichever team you choose is whatever
            you're fighting for in gym matches. If the gym is not your color, you
            defeat all the Pokémon at the gym to make it your color, and you put
            one of your Pokémon in the gym to protect it. Once the Pokémon is
            defeated, it returns to you. PokeStops are all over the place and you
            spin them to help you get items. Raids are when you and other trainers
            team up and try to catch a powerful Pokémon. The Pokémon in raids are
            determined by the current event. You can also catch shiny Pokémon,
            and Pokémon of different forms. Pokémon Go is best in the city where there
            are plenty of gyms and PokeStops. Usually you'd have to be very
            active on the game to have fun playing it!

          </p>
          <br></br>
          <h4>Pokémon Unite
          </h4>
          <br></br>
          <img
            src="https://assets.nintendo.com/image/upload/ar_16:9,b_auto:border,c_lpad/b_white/f_auto/q_auto/dpr_2.0/c_scale,w_400/ncom/software/switch/70010000043292/b9bf0b6fa28685bce9818d75e57ac0a90398441dfd36430cec617e5169412fb4"
            alt="" className="images" />
          <br></br>
          <p>
            Although few Pokémon are in Pokémon Unite, it's an extremely exciting team
            battle with two teams. You try to score points in goals by defeating waiting
            npc Pokémon, while also making sure your opponent doesn't score on your goal.
            Each Pokémon has a role. Speedster, All Rounder, Attacker, Supporter, and
            Defender. Play all your popular Pokémon to earn points, and have fun!
            There are many maps to play on, and ways to play. The smallest games are 3v3,
            and the largest are 5v5. You earn coins to buy clothing, or Pokémon licenses
            which allow you to play the Pokémon you buy! Pokémon unite works well to play
            anywhere to have fun playing. If you want a challenging Pokémon game against
            other real people, choose Pokémon Unite.

          </p>
          <br></br>
          <h4>Pokémon Masters
          </h4>
          <br></br>
          <img
            src="https://play-lh.googleusercontent.com/b7qRWt8DAGGlMtwtEC2QsT6SevhvriG4zxezSKL04IuGp5BpvUgRWNN1Tg75lQfAGULn=w526-h296-rw"
            alt="" className="images" />
          <br></br>
          <p>You start the game with Pikachu and one of the gen 1 starters. You
            then go on a quest to get other trainers such as Misty and Brock to
            battle with you against evil bad guys, and gym leaders which later join
            you after you defeat them. You can also roll a wheel to have a trainer
            in the real games join you on your quest. Eventually in the game, you
            defeat Giovanni and earn him. Professor Oak and Mew join you early in the
            game according to script. You can level up yourself and your companions
            to have better success in battle against other groups of 3 or more. This
            game is not interacting with other real life trainers, it's just an
            adventure game to try to complete all the missions.
          </p>
          <br></br>
          <h4>Pokémon Quest
          </h4>
          <br></br>
          <img
            src="https://play-lh.googleusercontent.com/XKVApDb2mz6BusRs7ncQBkQpGkIT_y2C7lieVfJal9oEt4O5WVRtwx7VQsaaG-fUyZk=w526-h296-rw"
            alt="" className="images" />
          <br></br>
          <p>This game features only the first 151 Pokémon. You start off with a
            choice of Pikachu, Eevee, Squirtle, Charmander, Meowth, or Bulbasaur.
            After your choice, you will soon get Rattata and Pidgey. Next would be
            Growlithe or Vulpix as you play the tutorial of the game. Much like
            Pokémon Master, you go on a quest to beat all the levels, however,
            you get Pokémon not by completing the quests, but by using the berries
            you get from the quest to cook something and attract Pokémon. Each
            recipe attracts different Pokémon based on their color, or typings.
            You level the Pokémon up by taking them on the level where you first
            battle the smaller Pokémon, and then the boss of that level. You take
            three Pokémon with you on each quest which each have either one or
            two moves that you click on to attack after every cool down. You also
            earn attack and hp buffs that you can use on your Pokémon to battle
          </p>
          <br></br>
          <h4>PokeArt
          </h4>
          <br></br>
          <img
            src="https://play-lh.googleusercontent.com/VrVhFjFE6d6Anmhj0KcqUav9CcbxIWgoh-xu22jgc89EdVscK3cK8w_z0StooftFEg=w526-h296-rw"
            alt="" className="images" />
          <br></br>
          <p>
            This isn't a game, but you use it as a Pokémon card maker. You select
            what type of card you want, such as Vstar, Vmax, etc. You then choose
            the typing of the card, such as fighting, fire, water, etc. You can also
            add abilities depending on what card it is, and Pokémon moves. You
            then would draw the Pokémon, or insert a picture. If you want to, you
            could choose a card you made to put in the weekly voting for the best card.
            You can vote for your favorite cards on the list, and your own.

          </p>
          <br></br>
          <h4>Pokémon Tv
          </h4>
          <br></br>
          <img
            src="https://play-lh.googleusercontent.com/Dpbnnhe0WwFJ8PjyATI6fev67J7Todtakctz7nu4PIi28Gbm8qRpb6Crr7f6wtqK3g=w240-h480-rw"
            alt="" className="images" />
          <br></br>
          <p>
            In this app you can watch the current anime that's happening, and whatever
            anime that is currently on the rotation. The season that's happening at
            the moment continues for 1-2 months. You can also watch the TCG Pokémon
            card matches happening at the time. You can find things extremely hard to find anywhere else on there.

          </p>
          <br></br>
          <h4>Pokémon TCG Live
          </h4>
          <br></br>
          <img
            src="https://cdn.vox-cdn.com/thumbor/hcgxFCB_eFVleKOl_6d-dmKFNVk=/0x0:1920x1080/1200x800/filters:focal(807x387:1113x693)/cdn.vox-cdn.com/uploads/chorus_image/image/70541132/Screen_Shot_2022_02_23_at_10.47.31_AM.0.png"
            alt="" className="images" />
          <br></br>
          <p>
            I barely played this game, but from what I did play the game is where you collect
            Pokémon sets online and use them to battle in a Pokémon card online duel.
            You rank up from winning and face harder opponents. You can earn your
            favorite Pokémon cards and Pokémon as you play the game.

          </p>
          <br></br>
          <h4>Pokémon Cafe
          </h4>
          <br></br>
          <img
            src="https://play-lh.googleusercontent.com/nrGXC69xhwUkpH_Jp8J_bn4cK6MdJhawRTXERs4iPmLTOZTYVgDe6Im_ENoIqT26gg=w526-h296-rw"
            alt="" className="images" />
          <br></br>
          <p>
            Pokémon Cafe isn't much of a Pokémon game, but its a cute little game to play if you don't
            want to do battling. You do what the game tells you to do between spinning Pokémon
            based on what the recipe is. There are more and more recipes as you progress in the game.

          </p>
          <br></br>
          <h4>Copy Games

          </h4>
          <br></br>
          <p>
            This is a list of mobile games very similar to the real Pokémon.
            Monster Super League, Monster Masters EX, Drakomon Masters, EvoCreo,
            Haypi Monsters, and Dynamons World.

          </p>
          <br></br>
        </section>
      </main>

    </>
  )
}

export default Article6_com;
