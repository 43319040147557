import React, { useRef, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import './NavBar.css';


function NavBar() {

  const [page, setPageValue] = useState('chat');

  const open = async () => {
    var x = document.getElementById("links");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
    x = document.getElementById("scrollindic");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }
  return (
    <div className="navbar">
      <header >
        <div ></div>
        <h1 className="logo">Collecting<span>Community</span> <p style={{ display: "inline", fontSize: "10px" }}>Version <span>2.1.03</span></p> </h1>
      </header>
      <div className="topnav">
        <a><div className="link"><NavLink to="/" activeClassName="activePage">Chat</NavLink></div></a>
        <div id="links">
          <a ><div className="link" ><NavLink to="/commands" activeClassName="activePage">Command Reference</NavLink></div></a>
          <a ><div className="link" ><NavLink to="/members" activeClassName="activePage">Memberships</NavLink></div></a>
          {/* <a ><div className="link" ><NavLink to="/store" activeClassName="activePage">Marketplace</NavLink></div></a> */}
          <a ><div className="link" onClick={() => { setPageValue("articles"); open(); }}><NavLink to="/articles" activeClassName="activePage" >Articles</NavLink></div></a>
          <a><div className="link"><NavLink to="/updates" activeClassName="activePage" >Updates</NavLink></div></a>
          <a><div className="link"><NavLink to="/rules" activeClassName="activePage" >Rules</NavLink></div></a>
          <a><div className="link"><NavLink to="/events" activeClassName="activePage">Events</NavLink></div></a>
          <a><div className="link"><NavLink to="/about" activeClassName="activePage" >About Us</NavLink></div></a>
          <a><div className="link"><NavLink to="/suggest" activeClassName="activePage" >Suggestions</NavLink></div></a>
          <a><div className="link"><NavLink to="/contact" activeClassName="activePage" >Contact Us</NavLink></div></a>

        </div>

        <div className="link"><a href="javascript:void(0);" className="icon" onClick={open}>
          <i className="fa fa-bars"></i>
        </a></div>
      </div>
      <div id="scrollindic">
        <div className="link">
          <a>▼ ▼ ▼</a>
        </div>
      </div>
    </div >
  )
}

export default NavBar;
