import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article8() {

  return (
    <>
      <NavBar />

      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <header><img
            src="https://cdn.discordapp.com/attachments/867970381426880562/1178806419634524292/BarginB.png?ex=65777c07&is=65650707&hm=bf4222f3ad059275a89bbfca8b58f6d6ae2a6ac0f306ea42a9081f55070e6f43&" />
          </header>
          <br></br>
          <h2><span style={{ fontWeight: "bold" }}>ARTICLE:</span> Bargain Beauties #3
          </h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-24">January 29, 2024</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> W3nderful</p>
          <p>&#160;</p>
          <p>&#160;</p>

          <p>
            Hi shiny cardboard collectors! Here is another edition of Bargain Beauties. I have listed 5 beautiful Pokemon cards that are all below $10 and will definitely be eye catching in anyone's binder. Not only are they affordable, they are very versatile and perfect for those who collect cute cards like me! They are listed in no particular order and let's dive into it:


          </p>
          <br></br><br></br>
          <p><b>1. Shaymin EX (Full Art) from Legendary Treasures: Radiant Collection: $7.94
          </b>
          </p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1201727758515048498/image.png?ex=65cadf30&is=65b86a30&hm=7a6b59da4c5ebf1d08db9ed13c618cd001778247e38aa6e25708c5f27bd835b1&"
            alt="" className="images" />
          <br></br>
          <p>This beautiful vintage Shaymin card is from the Black and White Era and can you believe it is only $8? A single booster back of Legendary Treasures in which this card can be pulled from is worth $80 today. As one of the top tier pulls in that set, it is a no brainer that this card is such a bargain!


          </p>
          <br></br><br></br>
          <p><b>
            2. Vileplume GX from Cosmic Eclipse: $3.88
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1201727758875492442/image.png?ex=65cadf30&is=65b86a30&hm=19d906a38528dca8427b2ffe75c181a4418916919fedeea8fb9e8f242b54c3c8&"
            alt="" className="images" />
          <br></br>
          <p>Cosmic Eclipse is one of my favorite sets in the Sun & Moon series. It featured some gorgeous full art cards of Pokémon with a trainer. Vileplume is not a popular favorite among a lot of collectors but at roughly $4, this affordable GX is a great addition to your collection.


          </p>
          <br></br><br></br>
          <p><b>3. Celebi V (Full Art) from Chilling Reign: $3.23
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1201727759236464651/image.png?ex=65cadf30&is=65b86a30&hm=d455a1a5ad74292f3eea3767cb8c6cc91afddc5fa541db00cbd04eeab84de2d3&"
            alt="" className="images" />
          <br></br>
          <p>Despite this being a normal full art Pokemon card in the Sword and Shield series, there is no doubt that this card is absolutely beautiful. The background colors are so vibrant, eye-catching, and it costs only a little over $3. Look at how cute Celebi is!


          </p>
          <br></br><br></br>
          <p><b>4. Eevee - Jungle (First Edition): $3.54
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1201727759542394880/image.png?ex=65cadf30&is=65b86a30&hm=5974d79152fba340d1c93ec046d9d03d441404ce13cc49801ddfcda9921eb5f0&"
            alt="" className="images" />
          <br></br>
          <p> As a big fan of Eevee myself (besides Togepi), this vintage base set card is a must have in your collection. This is the first ever eevee design that is printed and you never really see such designs in modern sets anymore. Nothing beats a cute card that costs less than a cup of Starbucks coffee!


          </p>
          <br></br><br></br>
          <p><b>5. Reshiram & Charizard GX: $9.76

          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1201727759920152626/image.png?ex=65cadf30&is=65b86a30&hm=1005e09cf2f637dd2972a7cc9de70f01b05d958170146654b636700230e40be2&"
            alt="" className="images" />
          <br></br>
          <p> Saving the best for last: this card is a promo card in the English version but actually is an expensive pull in the Japanese special set (Tag Team GX All Stars). At less than $10, this card is a great addition to a lot of collectors, especially those who love gold and Charizard.


          </p>
          <br></br>
          <br></br>
        </section>
      </main>
    </>
  )
}

export default Article8;
