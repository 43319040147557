import React from 'react'
import './rules.css';
import NavBar from './NavBar'


function Rules() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Rules</p>
      </header>
      <main className="rulesMain">
        <section className="scroll-box">
          <h3>General Rules</h3>
          <br></br>
          <p><b>We expect all members of the Collecting Community in all its platforms to have a basic level of human decency and common sense. Attempting to cheat or engage in toxic behavior because it is not explicitly covered in the rules will not be tolerated and will still be met with warnings and punishments.
          </b></p>
          <br></br><br></br>
          <p>1. By using the Collecting Community website you agree to follow the rules listed here. Violations of the rules may lead to temporary suspension or full termination of your account and access to the website with or without warning depending on the severity of the violation. The rules will be updated regularly and it is your duty as a user to stay up to date with the rules.</p>
          <br></br><br></br>
          <p>2. No hate speech: Examples include but are not limited to: racial/sexist/LGTBQ+ slurs and jokes. Anything designed to incite violence or hate towards a person or group. Anything that is done to willfully said to antagonize and insult another member of the Collecting Community. In short, don't be jerk.
          </p>
          <br></br><br></br>
          <p>3. No mature topics and/or language: This is a site and community designed to be family friendly and allow everyone to meet and share a love of collecting. Discussions of topics such as politics, war, vaccines, religion, sex, are not allowed and we recommend you find a site catering to those matters if that's your focus. Swearing and adult jokes are also not allowed.
          </p>
          <br></br><br></br>
          <p>
            4. No Gofundmes, Kickstarters, or any sort of crowdfunding may be discussed mentioned and/or advertised on Collecting Community without prior staff review and approval. Advertising for other sites, products, and services must also be reviewed and approved by Collecting Community stuff.

          </p>
          <br></br><br></br>
          <p>5. No toxic behavior. This includes but is not limited to the activities mentioned in the no hate speech rule, as well as stalking another member, spamming, begging, whining/complaining, degrading or insulting something, hostile behavior, being disrespectful, being a sore loser, repeatedly asking when mail will be sent out.
          </p>
          <br></br><br></br>
          <p>
            6. The Collecting Community staff are there to enforce the rules and users are expecting to comply with requests made by the staff so long as they do not violate any laws or rules of the sites. If you feel a staff member is being unprofessional or making unreasonable request please reach out at CollectingCommunity@gmail.com
          </p>
          <br></br><br></br>
          <p>7. We at Collecting Community will do our best to keep users safe on the site but have no control over the actions of our users outside of the collecting community site. Interact with others on your best judgment.
          </p>
          <br></br><br></br>
          <p>8. All contests and giveaways held by Collecting Community and its staff are limited to 1 entry and prize per mailing address unless otherwise specified. A person may only win the on-site puzzle game once in one week (a new week starts at the end of the Thursday livestreams.) Attempts to circumvent with additional accounts even if operated by other individuals will be seen as cheating and may result in forfeiture of entries and prizes as well as possible suspension or banning of your account
          </p>
          <br></br><br></br>
          <p>9. If allowed you may enter and potentially win giveaways in a livestream or site event as much as you like, but CC reserves the right to redraw a person he feels has won too much in an attempt to ensure everyone has a chance at getting a prize. Attempts to circumvent with additional accounts even if operated by other individuals will be seen as cheating and may result in forfeiture of entries and prizes as well as possible suspension or banning of your account
          </p>
          <br></br><br></br>
          <p>10. Please only use the suggestion feature for legitimate suggestions. Making suggestions/requests for more ways to get free cards will be ignored and may lead to you being banned from making suggestions in the future. Keep in mind that it make take some time before you see your suggestion on the site. Keep a lookout on the Updates page where we post a list of all updates made to the site
          </p>
          <br></br><br></br>
          <p>11. Collecting Community does not have access to magical instant delivery portals or supersonic carrier pigeons. CC and his staff also have lives and jobs, jobs that make the money to pay for the giveaway and shipping. So unless you are CC's next door neighbor or you would like to pay for next day shipping, your winnings will take time to reach you.
          </p>
          <h3>Rules of Referral Contests</h3>
          <br></br>
          <p>1. All referrals must be an actual person with their own account. Attempts to use multiple accounts to cheat will result in disqualification and ban.</p>
          <br></br><br></br>
          <p>2. You are not allowed to poach referrals from a CC livestream or the comments sections of his videos/shorts/stream vods/community posts, or using the CC website or discord to harass someone into saying you referred them. This will result in disqualification and a ban.
          </p>
          <br></br><br></br>
          <p>3. Unless offered by CC, all prizes are final and requests for a different and/or additional prizes will be met with a no, a warning, and if repeatedly done will result in a disqualification.
          </p>
          <br></br><br></br>
          <p>4. A referral will only count if a unique person joins the Collecting Community website, tells us “…” referred me, and begin playing the holiday hunt game until they procure at least one diamond. Mods will note who they said referred them and keep track.
          </p>

        </section >
      </main>

    </>
  )
}

export default Rules;
