import React from 'react'
import './aboutus.css';
import NavBar from './NavBar'
import $ from 'jquery';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { useState } from 'react';
import jsonp from 'jsonp';

function About() {

  return (
    <>
      <NavBar />
      <div className="aboutPage">
        <header>
          <p>Collecting<span>Community</span> Staff</p>
        </header>

        <div className="mission-container">
          <h1 className="mission-title">Our Mission</h1>
          <p className="mission-text">
            "The Collecting Community's goal is to establish a safe fun and useful environment for collectors of everything and anything. A way to track collections easier, to meet and chat with fellow collectors and safely arrange trades, to play games and enjoy all kinds of content created by our staff and users, and of course to create an actual community of passionate and wonderful people who enjoy collecting and aren't just trying to shake people down for money. Starting with it's four founding staff members and their shared loved of Pokémon this site quickly takes shape and continues to evolve and become bigger and better with every passing day. To learn more about the staff please check out below."
          </p>
        </div>
        <br></br>
        <h1 style={{ color: "#f8f8f8", fontSize: "25px", textAlign: "center", backgroundColor: "#000", width: "100%" }}>Custom Artwork</h1>
        <div style={{ display: "block", backgroundColor: "#000", minWidth: "100%" }}><div style={{ display: "inline-block" }}><img style={{ maxHeight: "300px" }} src="https://cdn.discordapp.com/attachments/867970381426880562/1204653814326632518/IMG_9573.jpg?ex=65d5844a&is=65c30f4a&hm=16550368ffbe6c0d9ed8b1eded3287419c20ce33efc8f24466d1b1955af8a3a8&"></img>
          <p style={{ color: "#f8f8f8", fontSize: "25px", textAlign: "center", backgroundColor: "#000", width: "100%" }}>$10: Eevee</p></div><div style={{ display: "inline-block" }}><img style={{ maxHeight: "300px" }} src="https://cdn.discordapp.com/attachments/867970381426880562/1204653828436140083/IMG_9574.jpg?ex=65d5844e&is=65c30f4e&hm=7a2dda27d352d6f93bd2341326966261b5d0e0726b041557fd61647b06c7b470&"></img>
            <p style={{ color: "#f8f8f8", fontSize: "25px", textAlign: "center", backgroundColor: "#000", width: "100%" }}>$10: Pikachu</p></div><div style={{ display: "inline-block" }}><img style={{ maxHeight: "300px" }} src="https://cdn.discordapp.com/attachments/867970381426880562/1206846769703419944/IMG_9657.jpg?ex=65dd7ea4&is=65cb09a4&hm=7ba49dfd64c07d91e4b600aaf679f2dc9119eb7148bcaaef25de8d243db47884&"></img>
            <p style={{ color: "#f8f8f8", fontSize: "25px", textAlign: "center", backgroundColor: "#000", width: "100%" }}>$10: Dragonite</p></div><div style={{ display: "inline-block" }}><img style={{ maxHeight: "300px" }} src="https://cdn.discordapp.com/attachments/867970381426880562/1204653896983646269/IMG_9571.png?ex=65d5845e&is=65c30f5e&hm=390ceb26baeb1976d2f7afed9f7290c0071ddd8391881dad0de1fc69177fd464&"></img>
            <p style={{ color: "#f8f8f8", fontSize: "25px", textAlign: "center", backgroundColor: "#000", width: "100%" }}>$50: Framed Mewtwo</p></div></div>
        <a style={{ color: "#f8f8f8", fontSize: "25px", textAlign: "center", backgroundColor: "#000", width: "100%", display: "block" }} href="https://paypal.me/PokeGamble?country.x=US&locale.x=en_US">Buy Custom Art</a>
        <br></br>
        <h1
          style={{ color: "#f8f8f8", fontSize: "25px", textAlign: "center", backgroundColor: "#000", width: "100%" }}>
          About the Team Behind the Website</h1>
        <div className="carousel-container">
          <Carousel
            autoPlay="true"
            infiniteLoop="true"
            className="crsl"
          >
            <div className="carousel-item"><img
              src="https://media.discordapp.net/attachments/873039673038098463/1175943017237061693/About_Us_Concept.png?ex=656d1147&is=655a9c47&hm=6fde17d74a9f37f810803ab8389fa66fbe0db53b44801f16b9f57acab0c31477&=&width=1045&height=588"
              alt="Image 1" /></div>
            <div className="carousel-item"><img
              src="https://media.discordapp.net/attachments/873039673038098463/1175947467737550938/About_Us_Concept_3.png?ex=656d156c&is=655aa06c&hm=ad38901c2ea9006613a95668afc60fc4ac0fa2a491608e811ffca4191f8bec11&=&width=1045&height=588"
              alt="Image 2" /></div>
            <div className="carousel-item"><img
              src="https://media.discordapp.net/attachments/873039673038098463/1175947524561981571/About_Us_Concept_2.png?ex=656d1579&is=655aa079&hm=88d752e919f2c5f938d1336b18a530e383767ad676f942c0770fc2ac5d3e8754&=&width=1045&height=588"
              alt="Image 3" /></div>

            <div className="carousel-item"><img
              src="https://cdn.discordapp.com/attachments/867970381426880562/1189077684962197606/image.png?ex=659cd9e4&is=658a64e4&hm=d8c0fb3dad24c979a13d19e78b6b5cbc09fb705950d1654ab5975e857bf22eef&"
              alt="Image 4" /></div>
          </Carousel>
        </div>

        <MailChimp />

        <h1
          style={{ color: "#f8f8f8", fontSize: "25px", textAlign: "center", backgroundColor: "#000", margin: "50px" }}>
          Follow our Socials:</h1>

        <div class="social-media-section">
          <a href="https://www.youtube.com/@CollectingCommunity" className="social-media-link" target="_blank">
            <img src="https://media.discordapp.net/attachments/867970381426880562/1181472716130361445/Simple_Professional_Name_Introduction_LinkedIn_Profile_Picture__3_-removebg-preview.png?ex=65812f36&is=656eba36&hm=f19897ce460231f2eab86f3d52f6bb3facf965cf685c1b048875272460ad8480&=&format=webp&quality=lossless&width=588&height=588" alt="YouTube" className="social-media-icon" />
          </a>

          <a href="https://www.instagram.com/ccwebsite_com/" className="social-media-link" target="_blank">
            <img src="https://media.discordapp.net/attachments/867970381426880562/1181472716423958558/Simple_Professional_Name_Introduction_LinkedIn_Profile_Picture__4_-removebg-preview.png?ex=65812f36&is=656eba36&hm=74514eef74c0c9576cab7e376b6ebb014713faa482abbd26db6d3ace2576766b&=&format=webp&quality=lossless&width=588&height=588" alt="Instagram" className="social-media-icon" />
          </a>

          <a href="https://www.tiktok.com/@ccommunity1?_t=8huzt8yTGO3&_r=1" className="social-media-link" target="_blank">
            <img src="https://media.discordapp.net/attachments/867970381426880562/1181472715761258566/Simple_Professional_Name_Introduction_LinkedIn_Profile_Picture__2_-removebg-preview.png?ex=65812f36&is=656eba36&hm=760ab230f72b3568203e7cfce3c36bab3a15b0e1bf74c672c6b7d08aac9b3693&=&format=webp&quality=lossless&width=588&height=588" alt="TikTok" className="social-media-icon" />
          </a>

          <a href="https://discord.gg/w5Xh2FgWT4" className="social-media-link" target="_blank">
            <img src="https://media.discordapp.net/attachments/867970381426880562/1181472761068146770/Simple_Professional_Name_Introduction_LinkedIn_Profile_Picture__5_-removebg-preview.png?ex=65812f40&is=656eba40&hm=3daa2b10398c23b0b1300aac5f435a1da8c25e4066f953d8e4fb4f639730a552&=&format=webp&quality=lossless&width=588&height=588" alt="Discord" className="social-media-icon" />
          </a>

          <a href="https://twitter.com/CollectC_Com" className="social-media-link" target="_blank">
            <img src="https://media.discordapp.net/attachments/867970381426880562/1181472761319796777/Simple_Professional_Name_Introduction_LinkedIn_Profile_Picture__1_-removebg-preview.png?ex=65812f41&is=656eba41&hm=34322617d713bfd1d45e7087c63dcc4a6eb633034b2a01083f3bf73a658c6105&=&format=webp&quality=lossless&width=588&height=588" alt="Twitter" className="social-media-icon" />
          </a>
        </div>
      </div >


    </>
  )
}


function MailChimp() {
  const [email, setEmail] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    const url = 'https://pokegamble.us21.list-manage.com/subscribe/post-json?u=5b9ead3d4817c9f781a41fceb&amp;id=ee72d549be&amp;f_id=00a3eae6f0';
    jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
      console.log('data', data);
      const { msg } = data;

      alert(msg);
    });
  };

  return (
    <div style={{ color: "#f8f8f8", fontSize: "25px", textAlign: "center", backgroundColor: "#000", margin: "50px" }}>
      <form onSubmit={onSubmit}>
        <label className="newsletter">Subscribe to our Newsletter</label><br></br>
        <label className="newsletter">Email </label>
        <input
          type="email"
          name="EMAIL"
          required
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}
export default About;
