import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import $ from 'jquery';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import TextareaAutosize from 'react-textarea-autosize';
import { useForm } from 'react-hook-form';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';


const firestore = firebase.firestore();


const sendQuery = async (URL, query) => {
  const url = URL;
  const formData = new FormData();
  formData.append('query', query);
  // for (var key of formData.entries()) {
  //   console.log(key[0] + ', ' + key[1]);
  // }
  let resp = "";
  await $.ajax({
    url: url,
    cache: false,
    dataType: 'text',
    contentType: false,
    processData: false,
    data: formData,
    type: 'POST',
    success: function (response) {
      console.log(response);
      resp = response;
    },
    error: function (error) {
      console.log(error);
      resp = "ERROR";
    }
  });
  return resp;
}

async function deleteQueryBatch(db, query, resolve) {
  const snapshot = await query.get();

  const batchSize = snapshot.size;
  if (batchSize === 0) {
    // When there are no documents left, we are done
    resolve();
    return;
  }

  // Delete documents in a batch
  const batch = db.batch();
  snapshot.docs.forEach((doc) => {
    batch.delete(doc.ref);
  });
  await batch.commit();

  // Recurse on the next process tick, to avoid
  // exploding the stack.

}

async function deleteCollection(db, collectionPath, batchSize) {
  const collectionRef = db.collection(collectionPath);
  const query = collectionRef.orderBy('__name__').limit(batchSize);

  return new Promise((resolve, reject) => {
    deleteQueryBatch(db, query, resolve).catch(reject);
  });
}



function AdminApp() {
  const [channel, setChannelValue] = useState('chat');
  const [auth, setAuth] = useState(false);

  const open = async () => {
    var x = document.getElementById("others");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  useEffect(() => {
    var main = document.getElementById("selection");
    main.innerHTML = channel === "chat" ? "Chat" : channel === "trivia" ? "Trivia" : channel === "holiday" ? "Holiday" : "Misc";
    main.scrollIntoView({ /*behavior: 'smooth'*/ })
  }, [channel])

  return (
    <div className="App">
      <div className="channelbar">
        <a href="javascript:void(0);" id="selection" onClick={open}>Chat</a>
        <div id="others">
          <a className={`${channel === "chat" ? "active" : null}`} onClick={() => { setChannelValue("chat"); open(); }}>Chat</a>
          <a className={`${channel === "trivia" ? "active" : null}`} onClick={() => { setChannelValue("trivia"); open(); }}>Trivia</a>
          <a className={`${channel === "holiday" ? "active" : null}`} onClick={() => { setChannelValue("holiday"); open(); }}>Holiday</a>
          <a className={`${channel === "misc" ? "active" : null}`} onClick={() => { setChannelValue("misc"); open(); }}>Misc</a>
        </div>
        <a href="javascript:void(0);" className="icon" onClick={open}>⬇️
        </a>
      </div>

      <section >
        {auth ? (channel === "chat" ? <ChatAdmin /> : channel === "trivia" ? <Trivia /> : channel === "holiday" ? <Holiday /> : <Ads />) : <SignIn onSignIn={() => setAuth(true)} />}
      </section>
    </div>
  );
}

function SignIn({
  onSignIn
}) {
  const [pass, setPass] = useState('');

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (pass === "TestGang420!") {
      onSignIn();
    }
    else {
      alert("Incorrect Password");
    }
    e.target.value = '';
    setPass('');
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <label className="signin">Sign In</label><br></br>
        <label className="signin">Password </label>
        <input
          type="text"
          required
          value={pass}
          onChange={(e) => setPass(e.target.value)}
        ></input>
        <button type="submit">Submit</button>
      </form>
    </>
  )
}

function ChatAdmin() {

  const clearChat = (e) => {
    e.preventDefault();
    deleteCollection(firestore, "messages", 500);

  }

  const clearCountChat = (e) => {
    e.preventDefault();
    deleteCollection(firestore, "countchat", 500);

  }

  const clearPromoChat = (e) => {
    e.preventDefault();
    deleteCollection(firestore, "promo", 500);

  }

  const clearShowcaseChat = (e) => {
    e.preventDefault();
    deleteCollection(firestore, "showcase", 500);

  }
  const clearLiveChat = (e) => {
    e.preventDefault();
    deleteCollection(firestore, "livechat", 500);


  }
  const clearVidChat = (e) => {
    e.preventDefault();
    deleteCollection(firestore, "giveawaychat", 500);

  }
  const clearBatChat = (e) => {
    e.preventDefault();
    deleteCollection(firestore, "battlechat", 500);

  }
  const clearGameChat = (e) => {
    e.preventDefault();
    deleteCollection(firestore, "gamechat", 500);

  }
  const enableChat = (e) => {
    e.preventDefault();
    sendQuery("update_battles.php", "UPDATE `version` SET `enabled`=true");
    alert("Chat Enabled");
  }
  const disableChat = (e) => {
    e.preventDefault();
    sendQuery("update_battles.php", "UPDATE `version` SET `enabled`=false");
    alert("Chat Disabled");
  }

  return (
    <>
      <form onSubmit={clearChat}>
        <label className="signin">Clear General Chat </label>
        <button type="submit">Submit</button>
      </form>
      <form onSubmit={clearCountChat}>
        <label className="signin">Clear Counting Chat </label>
        <button type="submit">Submit</button>
      </form>
      <form onSubmit={clearChat}>
        <label className="signin">Clear General Chat </label>
        <button type="submit">Submit</button>
      </form>
      <form onSubmit={clearLiveChat}>
        <label className="signin">Clear Live Chat </label>
        <button type="submit">Submit</button>
      </form>
      <form onSubmit={clearVidChat}>
        <label className="signin">Clear Giveaway Chat </label>
        <button type="submit">Submit</button>
      </form>
      <form onSubmit={clearBatChat}>
        <label className="signin">Clear Battles Chat </label>
        <button type="submit">Submit</button>
      </form>
      <form onSubmit={clearGameChat}>
        <label className="signin">Clear Game Chat </label>
        <button type="submit">Submit</button>
      </form>
      <form onSubmit={clearPromoChat}>
        <label className="signin">Clear Promo Chat </label>
        <button type="submit">Submit</button>
      </form>
      <form onSubmit={clearShowcaseChat}>
        <label className="signin">Clear Showcase Chat </label>
        <button type="submit">Submit</button>
      </form>
      <br></br>
      <form onSubmit={disableChat}>
        <label className="signin">Disable Chat </label>
        <button type="submit">Submit</button>
      </form>
      <br></br>
      <form onSubmit={enableChat}>
        <label className="signin">Enable Chat </label>
        <button type="submit">Submit</button>
      </form>
    </>
  )
}

function Trivia() {
  const [q, setQ] = useState('');
  const [a1, setA1] = useState('');
  const [a2, setA2] = useState('');
  const [a3, setA3] = useState('');
  const [a4, setA4] = useState('');
  const [correct, setCorrect] = useState('');
  const [pokemojiC, setPokemojiC] = useState('');
  const [pokemojiQ, setPokemojiQ] = useState('');



  const onSubmit = (e) => {
    e.preventDefault();
    console.log(q)
    console.log(a1)
    console.log(a2)
    console.log(a3)
    console.log(a4)
    console.log(correct);
    sendQuery("update_battles.php", "INSERT INTO `trivia`(`Question`, `A1`, `A2`, `A3`, `A4`, `Correct`) VALUES ('" + q + "','"
      + a1 + "','" + a2 + "','" + a3 + "','" + a4 + "','" + correct + "')");
    setQ('');
    setA1('');
    setA2('');
    setA3('');
    setA4('');

  };

  const onSubmit2 = (e) => {
    e.preventDefault();
    sendQuery("update_battles.php", "INSERT INTO `pokemoji`(`Emoji`, `Answer`) VALUES ('" + pokemojiQ + "','"
      + pokemojiC + "')");
    setPokemojiC('');
    setPokemojiQ('');
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <label className="signin">Trivia Question</label><br></br>
        <label className="signin">Question </label>
        <input
          type="text"
          required
          value={q}
          onChange={(e) => setQ(e.target.value)}
        ></input><br></br>
        <label className="signin">1. </label>
        <input
          type="text"
          required
          value={a1}
          onChange={(e) => setA1(e.target.value)}
        ></input><br></br>
        <label className="signin">2. </label>
        <input
          type="text"
          required
          value={a2}
          onChange={(e) => setA2(e.target.value)}
        ></input><br></br>
        <label className="signin">3. </label>
        <input
          type="text"
          required
          value={a3}
          onChange={(e) => setA3(e.target.value)}
        ></input><br></br>
        <label className="signin">4. </label>
        <input
          type="text"
          required
          value={a4}
          onChange={(e) => setA4(e.target.value)}
        ></input><br></br>
        <label className="signin">Correct answer: </label>
        <div onChange={(e) => setCorrect(e.target.value)} value={correct}>
          <input type="radio" id="1" name="correct" value="1"></input>
          <label className="signin">1 </label>
          <input type="radio" id="2" name="correct" value="2"></input>
          <label className="signin">2 </label>
          <input type="radio" id="3" name="correct" value="3"></input>
          <label className="signin">3 </label>
          <input type="radio" id="4" name="correct" value="4"></input>
          <label className="signin">4 </label>
        </div>
        <button type="submit">Submit</button>
      </form>
      <br></br>
      <form onSubmit={onSubmit2}>
        <label className="signin">Pokemoji Question</label><br></br>
        <label className="signin">Question (Emojis) </label>
        <input
          type="text"
          required
          value={pokemojiQ}
          onChange={(e) => setPokemojiQ(e.target.value)}
        ></input><br></br>
        <label className="signin">Correct answer: </label>
        <input
          type="text"
          required
          value={pokemojiC}
          onChange={(e) => setPokemojiC(e.target.value)}
        ></input><br></br>
        <button type="submit">Submit</button>
      </form>
    </>
  )
}


function Ads() {
  const [link, setLink] = useState('');
  const [img, setImg] = useState('');
  const [code, setCode] = useState('');



  const onSubmit = (e) => {
    e.preventDefault();
    sendQuery("update_battles.php", "INSERT INTO `ads`(`image`, `link`) VALUES ('" + img + "','"
      + link + "')");
    setLink('');
    setImg('');

  };

  const onSubmit2 = (e) => {
    e.preventDefault();
    sendQuery("update_battles.php", "INSERT INTO `codes`(`code`) VALUES ('" + code + "')");
    setCode('');
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <label className="signin">New Ad</label><br></br>
        <label className="signin">Amazon Affiliate Link </label>
        <input
          type="text"
          required
          value={link}
          onChange={(e) => setLink(e.target.value)}
        ></input><br></br>
        <label className="signin">Ad Image URL </label>
        <input
          type="text"
          required
          value={img}
          onChange={(e) => setImg(e.target.value)}
        ></input><br></br>
        <button type="submit">Submit</button>
      </form>
      <br></br><br></br>

      <form onSubmit={onSubmit2}>
        <label className="signin">New Code Card</label><br></br>
        <label className="signin">Code Card Text </label>
        <input
          type="text"
          required
          value={code}
          onChange={(e) => setCode(e.target.value)}
        ></input><br></br>
        <button type="submit">Submit</button>
      </form>
    </>

  )
}

function Holiday() {
  const [f, setF] = useState('');
  const [s, setS] = useState('');
  const [p1, setP1] = useState('');
  const [p2, setP2] = useState('');
  const [p3, setP3] = useState('');
  const [p4, setP4] = useState('');
  const [p5, setP5] = useState('');
  const [p6, setP6] = useState('');
  const [p7, setP7] = useState('');
  const [p8, setP8] = useState('');
  const [p9, setP9] = useState('');
  const [p10, setP10] = useState('');
  const [p11, setP11] = useState('');
  const [p12, setP12] = useState('');
  const [cost, setCost] = useState('');




  const changeFreq = (e) => {
    e.preventDefault();
    sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `period`=" + f);
    setF('');

  };

  const enablePuzzles = (e) => {
    e.preventDefault();
    sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `puzzle_enabled`=true");

  };

  const disablePuzzles = (e) => {
    e.preventDefault();
    sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `puzzle_enabled`=false");

  };
  const changePuzzleCost = (e) => {
    e.preventDefault();
    sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `puzzle_cost`=" + cost);
    setCost('');

  };
  const clearDiamonds = (e) => {
    e.preventDefault();
    let result = window.confirm("Are you sure you want to clear all diamonds from the leaderboard?")
    if (result) {
      sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `gold`=0");
    }

  };

  const clearPuzzles = (e) => {
    e.preventDefault();
    let result = window.confirm("Are you sure you want to clear all puzzle pieces from the leaderboard?")
    if (result) {
      sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `puzzle`=0");
    }

  };
  const changeSpawn = (e) => {
    e.preventDefault();
    sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `picurl`='" + s + "'");
    setS('');

  };
  const changePuzzle = (e) => {
    e.preventDefault();
    sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `piece1`='" + p1 + "',`piece2`='" + p2 +
      "',`piece3`='" + p3 + "',`piece4`='" + p4 + "',`piece5`='" + p5 + "',`piece6`='" + p6 + "',`piece7`='" + p7 + "',`piece8`='" + p8 +
      "',`piece9`='" + p9 + "',`piece10`='" + p10 + "',`piece11`='" + p11 + "',`piece12`='" + p12 + "'");
    setP1('');
    setP2('');
    setP3('');
    setP4('');
    setP5('');
    setP6('');
    setP7('');
    setP8('');
    setP9('');
    setP10('');
    setP11('');
    setP12('');

  };

  return (
    <>
      <form onSubmit={changeFreq}>
        <label className="signin">Change Spawn Frequency</label><br></br>
        <label className="signin">Frequency in minutes </label>
        <input
          type="number"
          required
          value={f}
          onChange={(e) => setF(e.target.value)}
        ></input><br></br>
        <button type="submit">Submit</button>
      </form>

      <form onSubmit={clearDiamonds}>
        <label className="signin">Clear Leaderboard Diamonds </label>
        <button type="submit">Submit</button>
      </form>

      <form onSubmit={clearPuzzles}>
        <label className="signin">Clear Leaderboard Puzzle Pieces </label>
        <button type="submit">Submit</button>
      </form>

      <form onSubmit={changeSpawn}>
        <label className="signin">Change Spawn Picture (enter pic url)</label><br></br>
        <label className="signin">Spawn Picture </label>
        <input
          type="text"
          required
          value={s}
          onChange={(e) => setS(e.target.value)}
        ></input><br></br>
        <button type="submit">Submit</button>
      </form>

      <form onSubmit={changePuzzle}>
        <label className="signin">Change Puzzle (enter url for each picture)</label><br></br>
        <label className="signin">1 Piece Picture </label>
        <input
          type="text"
          required
          value={p1}
          onChange={(e) => setP1(e.target.value)}
        ></input><br></br>
        <label className="signin">2 Piece Picture </label>
        <input
          type="text"
          required
          value={p2}
          onChange={(e) => setP2(e.target.value)}
        ></input><br></br>
        <label className="signin">3 Piece Picture </label>
        <input
          type="text"
          required
          value={p3}
          onChange={(e) => setP3(e.target.value)}
        ></input><br></br>
        <label className="signin">4 Piece Picture </label>
        <input
          type="text"
          required
          value={p4}
          onChange={(e) => setP4(e.target.value)}
        ></input><br></br>
        <label className="signin">5 Piece Picture </label>
        <input
          type="text"
          required
          value={p5}
          onChange={(e) => setP5(e.target.value)}
        ></input><br></br>
        <label className="signin">6 Piece Picture </label>
        <input
          type="text"
          required
          value={p6}
          onChange={(e) => setP6(e.target.value)}
        ></input><br></br>
        <label className="signin">7 Piece Picture </label>
        <input
          type="text"
          required
          value={p7}
          onChange={(e) => setP7(e.target.value)}
        ></input><br></br>
        <label className="signin">8 Piece Picture </label>
        <input
          type="text"
          required
          value={p8}
          onChange={(e) => setP8(e.target.value)}
        ></input><br></br>
        <label className="signin">9 Piece Picture </label>
        <input
          type="text"
          required
          value={p9}
          onChange={(e) => setP9(e.target.value)}
        ></input><br></br>
        <label className="signin">10 Piece Picture </label>
        <input
          type="text"
          required
          value={p10}
          onChange={(e) => setP10(e.target.value)}
        ></input><br></br>
        <label className="signin">11 Piece Picture </label>
        <input
          type="text"
          required
          value={p11}
          onChange={(e) => setP11(e.target.value)}
        ></input><br></br>
        <label className="signin">12 Piece Picture </label>
        <input
          type="text"
          required
          value={p12}
          onChange={(e) => setP12(e.target.value)}
        ></input><br></br>
        <button type="submit">Submit</button>
      </form>
      <form onSubmit={disablePuzzles}>
        <label className="signin">Disable Puzzle</label>
        <button type="submit">Submit</button>
      </form>
      <form onSubmit={enablePuzzles}>
        <label className="signin">Enable Puzzle</label>
        <button type="submit">Submit</button>
      </form>
      <form onSubmit={changePuzzleCost}>
        <label className="signin">Change Puzzle Cost</label><br></br>
        <label className="signin">Cost </label>
        <input
          type="number"
          required
          value={cost}
          onChange={(e) => setCost(e.target.value)}
        ></input><br></br>
        <button type="submit">Submit</button>
      </form>
    </>
  )
}

export default AdminApp;
