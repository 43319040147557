import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article4() {

  return (
    <>
      <NavBar />

      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <header><img
            src="https://cdn.discordapp.com/attachments/867970381426880562/1178806419634524292/BarginB.png?ex=65777c07&is=65650707&hm=bf4222f3ad059275a89bbfca8b58f6d6ae2a6ac0f306ea42a9081f55070e6f43&" />
          </header>
          <br></br>
          <h2><span style={{ fontWeight: "bold" }}>ARTICLE:</span> Bargain Beauties #1
          </h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-24">November 24, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
          <p>&#160;</p>
          <p>&#160;</p>

          <p>Card collecting is expensive and with the rising costs of packs and boxes and everything else in the world it
            may seem like trying to add to your binder is beyond your budget. But fear not! There are plenty of amazing
            cards out there all under $10 that will add plenty of shine to your collection. With Paldean Fates whipping
            everyone into a shiny frenzy we will start by looking at six examples of affordably awesome shiny Pokémon cards.
          </p>
          <br></br><br></br>
          <p><b>1. Lapras Vmax (Shining Fates SV111/122) Price: $3.84</b>
          </p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1177542167661649940/IMG_2760.webp?ex=6572e29a&is=65606d9a&hm=e5b7778ad9695e6330ae06f2dc17e5f26117c9e1ca684fd8c555ba3f415c70f4&"
            alt="" className="images" />
          <br></br>
          <p>
            Starting off with with a set most collectors are familiar with, Shining Fates still remains a surprisingly easy
            to come by set with the Pikachu V boxes still an easy find at Wal-Marts. And while it is a set filled with a
            treasure trove of shiny Pokémon, Lapras is a low-cost full art and sure to impress in its gigantamax form.

          </p>
          <br></br><br></br>
          <p><b>
            2. Radiant Eevee (Sword and Shield Promos SWSH230) Price: $3.93
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1177542167959437363/IMG_2761.webp?ex=6572e29a&is=65606d9a&hm=f94c394a9131673b5c6ee1a40f6075c68e76497630b2b1157449f43f08639eba&"
            alt="" className="images" />
          <br></br>
          <p>Shiny Pokémon aren't just found in in 'fates' sets. This adorable eevee was part of a Pokémon GO collection box
            which had this pretty promo, a cool playmat, and a whole stack of packs. But unless you just dying to chase that
            alt-art Conkeldurr V then buying this eevee as a single remains a budget friendly buy.
          </p>
          <br></br><br></br>
          <p><b>3. Froslass (Evolving Skies 226/203) Price: $4.70
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1177542168253050940/IMG_2766.webp?ex=6572e29a&is=65606d9a&hm=08905bf63fdb722c543630de3c33ba491b149c8a801ad65214f05760c8e7f1f9&"
            alt="" className="images" />
          <br></br>
          <p>Shiny Pokémon even come in gold! Quite a few gold full arts in the sword and shield era used the shiny variants
            coming in at under $5 you can have this blinged out beauty for far less than the cost of overly inflated
            Evolving Skies pack.

          </p>
          <br></br><br></br>
          <p><b>4. Lucario (Hidden Fates SV22/SV94) Price: $7.31
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1177542168571813918/IMG_2763.webp?ex=6572e29a&is=65606d9a&hm=9035e05dbc9a176168fcb72f0567c73f6de37208ea384c7d3c7aa4ac26aedce4&"
            alt="" className="images" />
          <br></br>
          <p>Towards the higher end of the Bargain Beauties price range and representing what most think of when you say
            “shiny Pokémon card” we have one of the major mascots of the franchise Lucario. Hidden Fates prices will likely
            rise as it remains a very popular set with a lot of high value cards so don't wait to get this card before more
            people take notice.

          </p>
          <br></br><br></br>
          <p><b>5. M Gardevoir EX (Steam Siege 112/114) Price: $9.50
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1177542168890572800/IMG_2762.webp?ex=6572e29a&is=65606d9a&hm=e78cf9cd9f1bea3dbffe92bfb696aaae3ba41c912d23d7e3933523964e30853f&"
            alt="" className="images" />
          <br></br>
          <p>Sporting a black dress instead of Gardevoir's usual white and blue hair (that is hair right?) This shiny
            Pokémon comes from the infamous and often disliked Steam Siege set of the XY era. She's Mega-Evolved and looks
            Mega-Angry, but don't let that intimidate you from picking her up. (And there's a shiny non-mega ex in Steam
            Siege too!)

          </p>
          <br></br><br></br>
          <p><b>6. Bisharp (Steam Siege cosmos holo variant 64/114) $0.34
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1177542169305821184/IMG_2767.webp?ex=6572e29a&is=65606d9a&hm=505c1ee2d60ff2c7d62dd6093323d510a54d38c6d201ae9fa3231315cb7dc435&"
            alt="" className="images" />
          <br></br>
          <p>
            I couldn't help adding this card because it's such a weird and often overlooked shiny. Coming from knockout
            collection boxes, this blue Bisharp costs less than 50 cents and being a cosmos holo can have swirls and is sure
            to stand out in any shiny collection.

          </p>
          <br></br><br></br>
        </section>
      </main>
    </>
  )
}

export default Article4;
