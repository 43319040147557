import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article7() {

  return (
    <>
      <NavBar />

      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <header><img
            src="https://cdn.discordapp.com/attachments/867970381426880562/1178806419634524292/BarginB.png?ex=65777c07&is=65650707&hm=bf4222f3ad059275a89bbfca8b58f6d6ae2a6ac0f306ea42a9081f55070e6f43&" />
          </header>
          <br></br>
          <h2><span style={{ fontWeight: "bold" }}>ARTICLE:</span> Bargain Beauties #2
          </h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-24">December 26, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
          <p>&#160;</p>
          <p>&#160;</p>

          <p>Special Illustration Rares, Scarlet and Violet's version of the wildly popular Alt-Arts seen in Sword and Shield. And in this edition of Bargain Beauties I am going to show you five of my favorites that make your binder look a little more special.

          </p>
          <br></br><br></br>
          <p><b>1. Revavroom EX (Obsidian Flames, 224/197): $1.90
          </b>
          </p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1184795388721512458/IMG_2875.webp?ex=65968031&is=65840b31&hm=f7010610bb4859c6c824be3ab2ba85edddfff8bfb2b86b466a5a92d923d1dd62&"
            alt="" className="images" />
          <br></br>
          <p>
            One of the cooler things seen in the Scarlet and Violet games is the mobile battle stages of Team Star powered by Revavroom and for less than $2 you can own the one used by Mela!

          </p>
          <br></br><br></br>
          <p><b>
            2. Eiscue EX (Obsidian Flames, 222/197): $2.00
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1184795389359030353/IMG_2876.webp?ex=65968031&is=65840b31&hm=faaafa56fccba54cec144854f6d8b1786c3893be69005424cf4f0bb105d725f3&"
            alt="" className="images" />
          <br></br>
          <p>I've seen a lot of things in my 20+ years of Pokémon but a penguin with an ice cube head jetting over the snow via a candelabra on its head is a new one and well worth its $2 price tag.

          </p>
          <br></br><br></br>
          <p><b>3. Quaquaval ex (Paldea Evolved, 260/193): $4.73
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1184795389707178139/IMG_2877.webp?ex=65968031&is=65840b31&hm=9daebd9c20906d28180c0fff4bb1cf64deb48ca765651a3e568882c01e24f19c&"
            alt="" className="images" />
          <br></br>
          <p>The dance king of the Paldean starters looks good in his SIR, but his under $5 price tag looks even better.

          </p>
          <br></br><br></br>
          <p><b>4. Saguaro (Paldea Evolved, 270/193): $4.93
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1184795390021730324/IMG_2878.webp?ex=65968031&is=65840b31&hm=a341d1fae5f476543667604ccb2b208df3e7e9c30a9507f6e6ab2c59349eefe1&"
            alt="" className="images" />
          <br></br>
          <p>A big man with a big heart but a surprisingly small cost. I honestly like this card than most of the waifu ones in Scarlet and Violet.

          </p>
          <br></br><br></br>
          <p><b>5. Pidgeot ex (Obsidian Flames, 225/197): $7.68
          </b></p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1184795390365683722/IMG_2880.webp?ex=65968031&is=65840b31&hm=4354728af93207aedd2808cf861a3472bf5e546cc5eea645db97016b8b72cbf7&"
            alt="" className="images" />
          <br></br>
          <p>There's just something about the simple flat colors that make this card really pop, it almost looks like it was hand drawn directly onto the card. And while it pushes towards the limit of what I call a bargain, there's no denying it's worth every cent.

          </p>
          <br></br>
          <br></br>
        </section>
      </main>
    </>
  )
}

export default Article7;
