import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article4_com() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <h2><span style={{ fontWeight: "bold" }}>ARTICLE: </span> Pokémon Game Mechanics Ranked
          </h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-12-08">December 8, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> Dayne Hultman</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <p>In this article I just wanted to go over the recent mechanics that Pokemon has done over the years in their games and rank them just on how they are used and how unique they are in the overall franchise itself.
          </p>
          <br>
          </br>
          <h4>1. Mega Evolution - Kalos
          </h4>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/866543421895344128/1182919862113292428/IMG_2836.png?ex=658672f8&is=6573fdf8&hm=0290110e3ba2f8e593dd80e4041e9a9ddc262173faa250c21a8007ad7a068276&"
            alt="" className="images" />
          <br></br>
          <p>Mega Evolution is a special and temporary evolutionary phenomenon introduced in the Pokémon series.
            It allows certain Pokémon to achieve a more powerful and visually altered form during battle.
            Triggered by the use of Mega Stones, unique to each species of Pokémon, and a compatible
            Mega Ring or Mega Bracelet held by their Trainer, Mega Evolution typically occurs mid-battle,
            bringing a strategic element to Pokémon battles. The transformed Pokémon, referred to as Mega Pokémon,
            not only undergo significant changes in appearance but also gain enhanced stats, altered abilities,
            and sometimes new typings. The transformation is temporary, lasting only for the duration of the battle,
            and the Pokémon reverts to its original form after the battle concludes.
            Mega Evolution was introduced in Pokémon X and Y and has since become a notable
            and strategic aspect of competitive Pokémon battling, allowing trainers to
            unleash the full potential of certain Pokémon during critical moments in battles.
          </p>
          <br></br>
          <p>As for being one of the most beloved mechanics in the Pokemon games,
            fans reacted very positively to this mechanic. It was loved so much that
            it returned in the Sun/Moon and Ruby and Sapphire reboots. I would consider
            this to be one of THE best mechanics in Pokemon games and I really hope
            that they return one day. So what is your favorite mega evolution?
          </p>
          <br></br>
          <h4>2. Dynamax/Gigantamax - Galar
          </h4>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/866543421895344128/1182917422731243550/IMG_2834.jpg?ex=658670b3&is=6573fbb3&hm=71fabbff85d2290243ed9046b2639859de438bbbeff803b066af2724a6f3513c&"
            alt="" className="images" />
          <br></br>
          <p>Dynamax and Gigantamax are special features introduced in the Pokémon series,
            specifically in Pokémon Sword and Shield. Dynamaxing is a phenomenon where
            Pokémon temporarily transform into massive versions of themselves during battles.
            This transformation not only alters their size but also enhances their stats
            and provides access to powerful Max Moves. Unlike Mega Evolution,
            Dynamaxing is not exclusive to specific Pokémon species; any Pokémon
            can Dynamax. Gigantamaxing, on the other hand, is a rarer and more
            specialized form of Dynamaxing. Certain Pokémon have the ability to
            Gigantamax, which not only changes their size and stats but also alters
            their appearance. Gigantamax Pokémon have unique G-Max Moves, which are
            powerful and visually striking moves specific to each Gigantamax form. Both
            Dynamaxing and Gigantamaxing are limited to specific locations in the Galar
            region, adding a strategic and visually dynamic element to battles in Pokémon Sword and Shield.

          </p>
          <br></br>
          <p>Similar to mega evolution, the Dynamax phenomenon only lasts 3 turns of
            battle, limiting the amount of time that the form exists on the battlefield.
            And while the transition from normal to Dynamax is not as dramatic as
            mega evolution, it is still unique in how the Pokemon changes to being
            completely gigantic. And since this mechanic applies to all Pokemon with
            only a few special forms, it is still a great mechanic which is why it
            is number 2 on this list. So what is your favorite Dynamax/Gigantamax form?

          </p>
          <br></br>
          <h4>3. Terastallization - Paldea
          </h4>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/866543421895344128/1182918134697558106/IMG_2835.webp?ex=6586715c&is=6573fc5c&hm=460bb8e7f082207ae137b213b3b5b0dc6c09c344f2af9e02019e0017b02fecf6&"
            alt="" className="images" />
          <br></br>
          <p>Terastallization is a mechanic introduced in Pokemon Scarlet and Violet
            in the Paldea region. Trainers who obtain a Tera Orb are able to use it
            to Terastallize their Pokémon, which makes a Tera Jewel appear above the
            Pokémon's head and causes the Pokémon's body to glisten like polished,
            faceted gemstones. This changes the Pokémon's defensive type(s) to its
            Tera Type, removing all other types. Terastallized Pokémon are identified
            by having "Tera" in front of their name. At this point in time there is
            still a lot we don't know about the Terastallization phenomenon but as
            it stands once the Indigo Disk releases on December 14th, we should have
            a full understanding of it and how it is used. Terapagos has some
            connection to this phenomenon and until the 14th, we should learn more.
          </p>
          <br></br>
          <p>As for how it is currently used, in online battles this is just as
            competitive as Pokemon types can be changed which in turn can change
            the tide of battle in an instant. Your opponent using ghost type moves?
            Change your type to Normal and enjoy the effects. And while the stats
            for the Pokemon aren't directly changed, the attack moves of that Tera
            type get a slight boost. Very well thought out than Z-Moves, but slightly
            underwhelming than Mega Evolution and Dynamaxing, this mechanic is
            something that I can't wait to see more unearthed.
          </p>
          <br></br>
          <h4>4. Z-Moves - Alola
          </h4>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/866543421895344128/1182917422466990080/IMG_2833.jpg?ex=658670b3&is=6573fbb3&hm=f8c2d9c9c1d6782881f503f2028367f8c1cafd203bb987caad7e927f2ffbf1fe&"
            alt="" className="images" />
          <br></br>
          <p>Z-Moves are a powerful and cinematic battling mechanic introduced in
            Pokémon Sun and Moon. These moves, powered by Z-Crystals, allow Pokémon
            to unleash extraordinary, one-time attacks during battle. Each Z-Crystal
            is type-specific and corresponds to a particular move, so a Water-type
            Pokémon equipped with a Waterium Z-Crystal, for example, can perform the
            powerful Hydro Vortex Z-Move. Trainers and their Pokémon must be in sync,
            as only one Z-Move can be used per battle. The spectacle of a Z-Move involves
            a dynamic animation sequence, emphasizing the unique bond between Trainer and Pokémon.
            Z-Moves provide a strategic advantage, capable of turning the tide in battles,
            and their introduction brought a new layer of depth and excitement to the Pokémon series.

          </p>
          <br></br>
          <p>
            However, while the animations are really good and bring out some insane moves like Snorlax's
            Pulverizing Pancake, it is just too similar to the Dynamax attacks in terms
            of the power of the move. Not only that but you have only one shot of activating
            the Z-Move, and then you just play it out like a normal Pokemon battle.
            I really think that there could have been a counter of some sort after a
            few moves you can use this Z-Move again, but that kind of change never came.
            That is why this is my least favorite of the Pokemon game mechanics.
            But tell me what is your favorite Z-Move?
          </p>
          <br></br>
        </section>
      </main>

    </>
  )
}

export default Article4_com;
