import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article1() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <h2><span style={{ fontWeight: "bold" }}>ARTICLE:</span> Paradox Rip-Off? Are there no two-hit packs in Pokémon's
            latest TCG set?</h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-04">November 4, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <img
            src="https://cdn.discordapp.com/attachments/867970381426880562/1170560855067349154/Untitled13_20231104201027.png?ex=65597cbf&is=654707bf&hm=1315107be5536ed6e86153e47f6a779224783da5f4ddd1f1fa0a41eed59bdfaf&"
            alt="" className="images" />
          <p>Paradox Rift has finally arrived bringing with it numerous things including the much awaited Past and Future
            Paradox Pokémon but it seems to have forgotten something. Following 151 which brought mini-god packs to English
            for the first time many were eager to see if they would continue with the idea in Paradox Rift. Sadly it seems
            The Pokémon Company went the opposite direction much to the dismay of many. People have reported never once
            seeing a pack with more than one hit regardless of what product the packs came from, some who run Rip&Ship
            channels and card stores who have opened thousands of packs have also claimed to have never seen a pack with two
            hits. With such high volumes of packs being opened it seems very unlikely this is an error and is clearly a
            deliberate change to how pull rates work.
            <br></br><br></br>
            For those who don't know, Two-Hit Packs also commonly known as "double bangers" can be found in just about every
            set that has a special subset of cards such as Shining Fates and Brilliant Stars. The base idea is the card in
            the reverse holo slot of the pack is replaced with a subset card (such as an illustration rare) and the
            rare/holo slot is an ultra rare (like an EX) and for the past 9 sets straight (from Brilliant Stars to 151) they
            have always been a possible and much welcomed surprised.
            <br></br><br></br>
            So the question becomes why, why suddenly take something away that Pokémon fans know and love? Well one theory
            is an attempt to spread hits into more packs without printing more hits and diluting the already struggling
            values of Scarlet and Violet era cards. We've all seen booster boxes that were filled with hits and some boxes
            that were almost pure bulk. Such sights have caused fans to question the possibility of "batching" where a
            certain batch of product has a ton of hits crammed into it with none saved or printed for the rest of the
            product being packed and shipped. Limiting a hit of any kind to one per pack would certainly help a bit with
            such an issue and allow more people a chance at getting good cards.
            <br></br><br></br>
            Another possible option is making opening a booster box more exciting overall both for people who open them and
            for fans of Pokémon card opening channels. A lot of Pokémon's money and free advertising comes from the myriad
            of people who watch online content creators opening different packs and products. The excitement of seeing a box
            where every pack seems to have a hit makes the viewer think "I could hit it big too, I should buy a box!"" And
            so it's in their best interest to make their booster boxes and premium collections seem more exciting and filled
            with goodies, even if it's really no more profitable than boxes with several Two-hit packs and more dud packs.
            Just like the the old "glass half full or half empty" debate, they want you to see it on the positive side of
            things.
            <br></br><br></br>
            Then there is the Rip&Ship channels, people who allow customers to buy packs and products which are then opened
            during a livestream and whatever hits are pulled being shipped to the customer. They commonly order entire cases
            of booster boxes allowing people to either buy into a "box break" where the box is opened then and there and the
            packs distributed to customers in the order they purchased, or selling individual packs from the boxes that are
            mixed up and again sold to customers in the order they bought. An older set with more Two-Hit Packs and more dud
            packs may leave the seller with unsold product as people stop buying packs from the set due to either appearing
            cold and unrewarding from the numerous packs with no hits or they may feel all the hits have been pulled and
            there's no point. On the other hand a set that always seems to have a hit will almost always sell well since
            people will tell themselves that at least they didn't leave empty handed, and the praise of other people in
            livestream for getting any kind of worthwhile pull will make them feel good and inclined to try again.
            <br></br><br></br>
            Whatever reasons The Pokémon Company may have for this, people are not happy and they will either need to
            address the issue, change it, or let their new set cause a rift in a fan base already struggling to justify the
            cost of opening a booster pack instead of just buying singles from a third party seller.

          </p>
        </section>
      </main>

    </>
  )
}

export default Article1;
