import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article1_com() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <h2><span style={{ fontWeight: "bold" }}>ARTICLE:</span> Top 10 Most Overshadowed Pokémon in Gen 1</h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-25">November 25, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> Pokeviolins</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <p>"I'm proud to introduce our first community submission done by my own brother, he's really excited to
            contribute
            and hopefully this will inspire others to submit their works too!"</p>
          <br></br>
          <p>- SuperViolinsADS</p>
          <br></br><br></br>

          <h4>Number 10: Seaking</h4>
          <br></br>
          <p>People just say that Seaking is just a different pattern of colors of a Goldeen. Seaking is partly just there,
            and is unliked in games. You will rarely ever find one, and its attacks are pretty limited to the same as
            Goldeen's. Goldeen isn't a liked Pokémon either, but Seaking is just there. A fish with a horn.
          </p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/1089607481342300202/1177819020951552070/IMG_0981.jpg?ex=6573e471&is=65616f71&hm=6896e3303e870d063af95a114be7db14113a89800cbc4d16cc606761d4396f24&"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 9: Electrode and Voltorb</h4>
          <br></br>
          <p>Both of these Pokémon are equally overshadowed by each other. Most would think, “that's the Pokémon that looks
            like a poke ball.” Nobody really notices the difference between Electrode and Voltorb. It's not that one is more
            favored than the other, it's just that nobody sees those two as different Pokémon. They aren't given much love
            in games or anime. When they are seen, which is rare, they have one reputation. It's the Pokémon that looks like
            a poke ball. That is most certainly not a good thing.</p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/1089607481342300202/1178130669952585728/image.png?ex=657506b0&is=656291b0&hm=c42de4a980387177a13dd0917051fa833644f809358ffa06ec1ec2258002de02&"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 8: Hitmonlee</h4>
          <br></br>
          <p>
            Why choose a slow kicker when you can have an epic boxer that can crush you with one hit? The hitmonchan vs
            hitmonlee thing started out equal, but it is proven that way more people would choose the boxer over the kicker.
            It could generally have something to do with the design. It could also be that hitmonchan has better stats. The
            conclusion is that hitmonchan is way more liked.
          </p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/1089607481342300202/1177819021165465680/IMG_0980.jpg?ex=6573e471&is=65616f71&hm=a1814f60dd97881e65cc1fb4a88f73a649095ce911289ba48dfbe293f74ea064&"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 7: Raticate</h4>
          <br></br>
          <p>
            Rattata is one of the Pokémon that you'll always see at the beginning of your journey. Rattata is never
            particularly liked to begin with and is often transferred away in games by trainers. If Rattata is already gone,
            then even less people know about Raticate. Raticate looks ugly to begin with to most, and is a boring normal
            type that sucks in battle. It helped when they added Alolan forms, but not enough to push them off this list.
            Raticate is a Pokémon that is recognized to either not exist, or be unliked. Raticate also has an evil addition
            to it in the anime.
          </p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/1089607481342300202/1177819022880931950/IMG_0971.jpg?ex=6573e471&is=65616f71&hm=3699a2b6bd4919d23f932d7381e02ae4653d2b186530f6154ab8272af4a5c25d&"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 6: Venusaur</h4>
          <br></br>
          <p>
            Everyone loved the fire dragon beast, Charizard. Ones who don't however, immediately jump to Blastoise if they
            don't wanna be separated from the crowd. Where is Venasaur in all of this? This probably also has to do with the
            fact that most people love their Bulbasaur and wanna keep it the way it is, just like Ash did in the anime.
            Venusaur is not only the rookie of the starters battle, but is also disliked compared to its former self.
          </p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/1089607481342300202/1177819021366804480/IMG_0979.jpg?ex=6573e471&is=65616f71&hm=d0e58f60bc5bfdc64cce79f64a6f6736d717bfbaccef95070a18381b822e8a42&"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 5: Persian</h4>
          <br></br>
          <p>
            Meowth is the immediate one to think of, when you think of evil. The only thing Persian ever gets is to be
            Giovanni's partner. Even so, that is barely realized in the anime or games due to the fact that he rarely shows
            up. Everyone obviously knows the cat with a charm, but what about the bigger white cat that is also there. Maybe
            not in a bad way, but Persian is definitely highly overshadowed by Meowth.
          </p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/1089607481342300202/1177819033941315624/IMG_0973.png?ex=6573e474&is=65616f74&hm=8bdf9ae52a606c5577e6aae7c4c8388d5c5c12a85224ab92772bd256f98aa1ef&"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 4: Doduo</h4>
          <br></br>
          <p>
            When you think of a gen 1 bird, your mind will immediately think of Pidgey. Next you'll think, “oh yes.
            Spearow.” After a few seconds the next bird comes to mind. Farfetch'd. However, that is it. Who else is there?
            Not only would it take you a long time to think of, but most will probably forget this Pokémon! Oh my gosh! I
            forgot about Doduo. Someone would think of Farfetch'd as an underdog, but they would completely forget about
            Doduo. Much like Raticate, Duduo is a Pokémon that is kind of, just there.
          </p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/1089607481342300202/1177819021584891924/IMG_0978.jpg?ex=6573e471&is=65616f71&hm=cf79769688144ae195e50ea18d3401b29e5e4606deb3218c4ee6eee03a4a4e22&"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 3: Porygon</h4>
          <br></br>
          <p>
            Porygon isn't particularly overshadowed by any other Pokémon, but to most people, if they were to name the first
            151 Pokémon, Porygon would be in at least the last 10. Neither the games nor the anime give a good reason to
            love Porygon. Porygon is the type of Pokémon who is just there.
          </p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/1089607481342300202/1177819022297923584/IMG_0972.jpg?ex=6573e471&is=65616f71&hm=44d8dc4ba47bf6e1fc5bae13b2bdcddcf68bae9e8b287d8d143dd9cf20248f79&"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 2: Raichu</h4>
          <br></br>
          <p>
            You're definitely confused on why Raichu is not first. Raichu is down here for all the obvious reasons. Yes,
            Raichu gets its Alolan form, but that is not nearly enough to make it not be down here. Even in Pokémon Unite,
            they have a Pikachu character instead of Raichu. Pikachu is the star of everything, and always will be, leaving
            Raichu to be left in the dust.
          </p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/1089607481342300202/1177819021823971329/IMG_0977.jpg?ex=6573e471&is=65616f71&hm=f1a5e405ee5c214c1486c3ad7ea34f694d778c17393aebeafb2510273488a9e6&"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 1: Golduck</h4>
          <br></br>
          <p>
            I bet you haven't even thought of the Pokémon being on the list due to the fact that everyone forgets about it.
            The blue duck is extremely lowly featured in the anime, and is not often brought up in the games. Golduck also
            has a reputation of being uninteresting. Psyduck has got its annoying headaches, and powerful unusual bursts,
            while Golduck is just a hippie who loves everything.
          </p>
          <br></br>
          <img
            src="https://cdn.discordapp.com/attachments/1089607481342300202/1177819022063059074/IMG_0976.jpg?ex=6573e471&is=65616f71&hm=247f12aa62a3f2d3c1f88acfe4dd41912bbb3648766b34de4b8db12c33ef384f&"
            alt="" className="images" />



        </section>
      </main>

    </>
  )
}

export default Article1_com;
