import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article6() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <h2><span style={{ fontWeight: "bold" }}>ARTICLE:</span> I Choose You, Charizard: Why does Charizard remain so popular?</h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-12-08">December 8, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1182923504329437264/IMG_2838.jpg?ex=6586765d&is=6574015d&hm=87d1495193efec2f88d60c7062e435f0687a406b4fccaad91f3c7f8a7d59fdb3&"
            alt="" className="images" />
          <p>
            Whether you are a longtime Pokémon fan or a newcomer, you've almost certainly
            have heard of Charizard. This big orange dragon-esque fire-type is
            everywhere but arguably his popularity is at its highest in the TCG.
            With Scarlet and Violet having 7 charizard cards alone, being the top
            chase cards of two different sets and having a rather nice EX collection box too.
            And going through past sets you will see Charizard commands top dollar
            in almost every set it's in with the one from Skyridge having a market
            value of several thousand dollars. But why is he so popular, why does
            he continue to be the unofficial face of the TCG? Well, I have some thoughts…
            <br></br><br></br>
            We can start all the way at the beginning with Pokémon Red, one of the
            two versions of the original generation. It was also the only version
            both Japan and the US shared initially with Pokémon Green being Japan
            exclusive and Pokémon Blue being what the states received. Each version
            featured one of the final evolutions of the first gen starters on the
            cartridge art and of course Charizard was plastered on Red. It is interesting
            that they pushed the chari-line to both countries since in the first
            generation Charmander was hardest starter to use in the beginning with
            the first two gyms being strong against fire types and usually Japan
            sends easier versions of its games to the west, But one can simply chalk
            this up to the fact that from a visual standpoint a big fiery dragon was
            cool as opposed to Venusaur which looked like a frog or toad with a plant on its back.
            <br></br><br></br>
            Charizard got even more of a push in the anime as Ash's surly and disobedient
            companion throughout the indigo league. Evolving from an abused and abandoned
            charmander that Ash rescued, the second he became charmeleon he began to act
            defiant towards Ash. It only became worse when he evolved into charizard who
            was more inclined to attack Ash than another Pokémon. Despite this Ash never gave
            up on him which sadly would lead to Ash losing the Indigo League after his
            Charizard decided to take a nap in the middle of fighting. You would think
            painting Charizard as an unruly uncontrollable and lazy Pokémon would cause
            his popularity to drop…
            <br></br><br></br>
            However soon came the Pokémon TCG and the base set which flew off store
            shelves when it debuted. At that time if you asked any kid what the rarest
            or most powerful card was many would have said Charizard. Boasting 120hp
            which was the highest base hp of any card for the time and an attack that
            hit for 100 damage which would decimate almost every current Pokémon card
            in one hit it was a power that couldn't be matched. Couple that with its
            rarity and it was a very sought after card for collecting and playing.
            This would seemingly set the trend for all future charizard cards that
            follow especially with the end of 2019.
            <br></br><br></br>
            In the wake of the covid-19 global pandemic many people had to stay home for
            their safety and the safety of others, which lead to a rise in hobbies
            and online shopping and a return to Pokémon card collecting for many adults
            who hoped for a bit of nostalgia to ease the cabin fever.  These same adults
            were the kids who saw charizard on their game cartridges, the anime, and who
            remembered the base set charizard now had money to spend as they please.
            The boom in the sale of Pokémon cards was something no one was prepared for.
            Stores were being emptied of every single product, distributors were being
            stalked and attacked which even garnered national news attention, scalping w
            as running rampant across all online markets, and the price of older singles skyrocketing.
            Including a graded charizard that sold for well over $400,000 USD and
            would help cause notable grading services such as PSA to become overwhelmed
            with orders that would create a waitlist 6 to 12 months long.
            <br></br><br></br>
            But it wasn't just adults reviving the Pokémon craze, many of these former
            children now had kids of their own and they were more than happy to share their
            passing with their offspring. They would teach their kids about about the cards
            and undoubtedly told them about how rare and powerful Charizard was and so these
            kids would want charizard cards just like mom and dad had. And on sites like
            YouTube you would see content creators such as Leonhart or Maxmoefoe go on long
            and dedicated hunts for charizards in sets like Burning Shadows which would
            just add fuel to the fire-lizard. During the sword and shield era we would see
            5 sets which all had charizard as the highest value card, Darkness Ablaze,
            Champion's Path, Shining Fates, Celebrations, and Brilliant Stars as well the
            Charizard Ultra Premium Collection.
            <br></br><br></br>
            However with Scarlet and Violet has posed a challenge to Charizard's ability
            to command top dollar. Whereas previous charizard cards usually averaged a
            market value of a couple hundred dollars when they released, the SIR charizards
            from Obsidian Flames and 151 barely stand above $100. This is due partly to
            Scarlet and Violet struggling to find a strong market presence, as well as
            the easier to pull natures of SIRs and people continuing to favor opening
            SwSh sets like Lost Origins for alternate arts. Despite it all though Charizard
            refused to budge for Ash in the Indigo League, and it doesn't look he'll
            budge from his position as the King of the TCG anytime soon,
            <br></br><br></br>
            But as always these are just one collectors theories and opinions, please share your thoughts with us at CollectingCommunity@gmail.com

          </p>
        </section>
      </main>

    </>
  )
}

export default Article6;
