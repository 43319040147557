import React from 'react'
import './rules.css';
import NavBar from './NavBar'


function Commands() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Comands Reference</p>
      </header>
      <main className="rulesMain">
        <section className="scroll-box">
          <h2>General Commands</h2>
          <br></br>
          <h4>!roll [number1]-[number2]</h4>
          <p>Randomly selects a number between number1 and number2 and returns that number.</p>
          <p><i>Example Usage: !roll 1-100</i></p>
          <br></br>
          <h4>!8ball [any text]</h4>
          <p>Works like any magic 8ball would. Chopi will tell you one of the magic 8ball's responses.</p>
          <p><i>Example Usage: !8ball is the site fun?</i></p>
          <br></br>
          <h4>!hug [any text]</h4>
          <p><i>Example Usage: !hug CC</i></p>
          <br></br>
          <h4>!chopi [any text]</h4>
          <p>Ask chopi a question. You will get a yes/no/maybe response.</p>
          <p><i>Example Usage: !chopi is the site fun?</i></p>
          <br></br>
          <h4>!discord</h4>
          <p>Get the link to the official Collecting Community discord.</p>
          <br></br>
          <h4>!claim</h4>
          <p>Get the information about how to claim your giveaway wins.</p>
          <br></br>
          <h4>!raffle</h4>
          <p>When a raffle giveaway is active in the Live chat, you can use this command to enter the giveaway.</p>
          <br></br>
          <h4>!help</h4>
          <p>Get a link to the command reference page to learn about commands as well as the Game instructions.</p>
          <br></br>
          <h4>!rules</h4>
          <p>Get a link to the rules page.</p>
          <br></br>
          <h2>Battles Commands</h2>
          <br></br>
          <p>These commands work with the Battle game in the Battles channel. You battle against anyone in a rock-paper-scissors game using pokemon
            types: fire, grass, and water. First you must challenge an opponent using the !battle command. The opponent must accept the challenge using !accept.
            Then both of you can choose your battle type using !choose. All command details below.
          </p>
          <br></br>
          <h4>!battle [name]</h4>
          <p>Use this command to challenge someone to a battle. You can even challenge Chopi by using
            !battle Chopi. Note that the name is case-sensitive and must be written exactly the same as the name
            of whoever you want to challenge.
          </p>
          <p><i>Example Usage: !battle CC</i></p>
          <br></br>
          <h4>!accept</h4>
          <p>If you were challenged to a battle, use this command to accept the challenge and begin the battle.</p>
          <br></br>
          <h4>!cancel</h4>
          <p>You can use this command to cancel a battle, but only if you are an Admin or are a participant in the battle.</p>
          <br></br>
          <h4>!choose [type]</h4>
          <p>Use this command to choose what type you want to use in the battle. As with Pokemon types, fire beats grass, grass beats water, and
            water beats fire.
          </p>
          <p><i>Example Usage: !choose water</i></p>
          <br></br>
          <h2>Game Commands</h2>
          <br></br>
          <p>All commands in this section can only be used in the Games channel</p>
          <br></br>
          <h3>Trivia</h3>
          <br></br>
          <p>In the trivia game, you will get a trivia question and 4 answer choices. You will pick one of these 4 choices. You get as many tries as you want to guess the correct answer.</p>
          <br></br>
          <h4>!trivia</h4>
          <p>Requests a trivia question from Chopi. Chopi will respond with a random one of our hundreds of trivia questions as well as the 4 answer choices.</p>
          <br></br>
          <h4>!pick [number]</h4>
          <p>Use this command to pick which of the answer choices you think is the correct answer to the trivia question. </p>
          <p><i>Example Usage: !pick 1 - this will pick the first answer choice.</i></p>
          <br></br>
          <h4>!repeattrivia</h4>
          <p>Use this to repeat the trivia question. This is intended to repeat the question in case it gets lost in the chat.</p>
          <br></br>
          <h3>Pokemoji Game</h3>
          <br></br>
          <p>In this game, you will get a question with a few emojis. These emojis represent a Pokemon and your
            goal is to guess what Pokemon it represents.
          </p>
          <br></br>
          <h4>!pokemoji</h4>
          <p>Use this command to get a new Pokemoji question that you can answer.</p>
          <br></br>
          <h4>!guess [any Pokemon name]</h4>
          <p>Use this command to make a guess about a Pokemoji question. You should guess the name
            of the Pokemon you think the Pokemojis represent. Spelling counts.
          </p>
          <p><i>Example Usage: !guess Charizard</i></p>
          <br></br>
          <h4>!repeatpokemoji</h4>
          <p>Use this command to repeat the Pokemoji question. This is intended to repeat the question in case it gets lost in the chat.</p>
          <br></br>
          <h3>Holiday Game</h3>
          <br></br>
          <p>The holiday game is a sitewide competition that rewards site activity. As you chat on the site, you may see a Pokemon spawn in the Games
            channel. This happens every 15 minutes as long as someone has sent a message during that time. If you see a Pokemon spawn, you can use the !shake command.
            When you do so, you have a random chance of getting coal, 1-4 diamonds, or a puzzle piece. If you get coal, you are presented
            with an opportunity to view a 3-second ad. Once you complete the ad, you will be able to use the !shake command again. At the end of the week on the Thursday livestream, based on the announcement of the previous week,
            CC will choose winners based on the diamond leaderboard. Some weeks, the top 3 will win a card, some weeks a booster box break will be divided among the top 20. And other weeks, the top players will receive CC packs.
            Finally, the first person to collect all 6 puzzle pieces of the puzzle card will win that card. Once someone completes the puzzle, puzzle pieces will no longer
            be a possible reward from !shake until the puzzle pieces are reset. During this time, players will receive 2 diamonds in place of the puzzle piece they would have won (the chances for a 2 diamond win will slightly increase).
          </p>
          <br></br>
          <p><i>Chance breakdown</i></p>
          <ul>
            <li>Coal: 40%</li>
            <li>1 Diamond: 40%</li>
            <li>2 Diamonds: 6.7%</li>
            <li>3 Diamonds: 3.3%</li>
            <li>Puzzle Piece: 10%</li>
          </ul>
          <br></br>
          <h4>!shake</h4>
          <p>Use this command to get your reward from a Pokemon spawn in the holiday game. You can only use this ONCE per spawn.</p>
          <br></br>
          <h4>!holidaylbpuzzle</h4>
          <p>Shows the 5 players with the most puzzle pieces.</p>
          <br></br>
          <h4>!holidaycoal</h4>
          <p>Shows the 5 players with the most coal.</p>
          <br></br>
          <h4>!holidaylbfull</h4>
          <p>Shows the full leaderboard, including every player that has more than 0 diamonds.</p>
          <br></br>
          <h4>!holidaylb</h4>
          <p>Shows the 5 players with the most diamonds.</p>
          <br></br>
          <h4>!myholiday</h4>
          <p>Shows your own holiday game stats, including number of diamonds, coal, and puzzle pieces.</p>
          <br></br>
          <h4>!holidayhelp</h4>
          <p>Shows a list of all the holiday commands.</p>
          <br></br>
          <h4>!buypuzzle</h4>
          <p>Using this command, you can spend some of the diamonds you've earned to buy a piece of the puzzle card. The price may vary.</p>
          <br></br>
          <h4>!sellpuzzle</h4>
          <p>Using this command, you can spend a puzzle piece you've earned to buy some diamonds. The price may vary.</p>
          <br></br>
          <h4>!previewpuzzle</h4>
          <p>Using this command, you can see how many pieces of the puzzle that have been discovered and see the actual puzzle!</p>
          <br></br>
          <h3>Mining Game</h3>
          <br></br>
          <p>In the mining game every 10 minutes a random material will spawn in the Games chat. There is also a chance for a Furnace to spawn.
            In the game everyone owns a pickaxe and the goal of the game is to upgrade this pickaxe. Your pickaxe upgrade determines what materials you are able to mine
            For example, the first pickaxe can only mine coal, but as you upgrade your pickaxe, you will be able to mine better materials as well. As you mine and play the game,
            you will also gain xp points. These contribute to your mining level. In order to upgrade to the next pickaxe, you need to meet some requirements. Every pickaxe will have 2 types of requirements: A level requirement
            and a material requirement. When a furnace spawns in the chat, you will be able to upgrade your pickaxe if you meet both of these requirements. All materials as well as the Furnace
            have varying chances of spawning and as you mine you have a chance of succeeding and getting that material or failing and getting no materials. If you succeed, you are guaranteed to receive xp points.
            If mining fails, you can still get xp, but you may not always get xp. There's a 70% chance to succeed at mining and if mining fails, there is a 40% chance to get xp points. You also have a 10% chance of getting CC Coins if mining succeeds.
            You will be able to use CC coins in the shop to buy various powerups.
            Note that the diamonds in this game are separate from the diamonds in the holiday game.
          </p>
          <br></br>
          <p><i>Spawn Chances</i></p>
          <ul>
            <li>Coal: 47.5%</li>
            <li>Tin: 12.5%</li>
            <li>Aluminum: 10%</li>
            <li>Copper: 7.5%</li>
            <li>Iron: 5%</li>
            <li>Silver: 2.75%</li>
            <li>Gold: 2%</li>
            <li>Titanium: 1.25%</li>
            <li>Cobalt: 0.75%</li>
            <li>Platinum: 0.5%</li>
            <li>Diamond: 0.25%</li>
            <li>Furnace: 10%</li>
          </ul>
          <br></br>
          <h4>!lvl</h4>
          <p>Use this command to view your mining level as well as how much xp you need to reach the next level.</p>
          <br></br>
          <h4>!upgradereq</h4>
          <p>Use this command to view your requirements to upgrade to the next pickaxe.</p>
          <br></br>
          <h4>!mine</h4>
          <p>Use this command when a material spawns in order to attempt to mine that material. You cannot use this command when a furnace spawns.</p>
          <br></br>
          <h4>!upgrade</h4>
          <p>Use this command to upgrade your pickaxe. This will show you the requirements you do not meet if you attempt to upgrade without meeting all upgrade requirements. This command cannot be used after a material has spawned. It can only be used when the current spawn is a furnace.</p>
          <br></br>
          <h4>!craft [material]</h4>
          <p>Use this command to use some of your materials to craft other materials. Specify the material after !craft. You can only use this command for a furnace spawn.</p>
          <p><i>Example Usage: !craft tin will attempt to craft tin.</i></p>
          <br></br>
          <p><i>Crafting requirements</i></p>
          <ul>
            <li>Coal: Cannot be crafted</li>
            <li>Tin: 8 Coal</li>
            <li>Aluminum: 2 Tin</li>
            <li>Copper: 2 Aluminum</li>
            <li>Iron: 2 Copper</li>
            <li>Silver: 2 Iron</li>
            <li>Gold: 2 Silver</li>
            <li>Titanium: 2 Gold</li>
            <li>Cobalt: 2 Titanium</li>
            <li>Platinum: 2 Cobalt</li>
            <li>Diamond: 2 Platinum</li>
          </ul>
          <br></br>
          <h4>!bal</h4>
          <p>Use this command to see how many CC Coins you have in the bank.</p>
          <br></br>
          <h4>!shop</h4>
          <p>Displays all items you can buy in the Mining Shop using CC Coins.</p>
          <br></br>
          <h4>!buy [number]</h4>
          <p>Use this command to buy one of the items listed in the shop. [number] should be the number listed next to the item in the shop list.</p>
          <p><i>Example Usage: !buy 1 will buy an Instant Furnace from the shop.</i></p>
          <br></br>
          <h4>!stoprush (ADMIN ONLY)</h4>
          <p>Use this to stop the ore rush. </p>
          <br></br>
          <h2>Admin Commands</h2>
          <br></br>
          <p>These commands can only be used by site admins. They will not work if you are not a site admin.</p>
          <br></br>
          <h4>!ongoing</h4>
          <p>Displays the following image in the chat</p>
          <img className="commandImg" src="https://cdn.discordapp.com/attachments/867970381426880562/1172026858641035386/InProgress.png?ex=655ed212&is=654c5d12&hm=fc04f03d205d571198c2158b3882f8d99de67929ec0a0412ebe93f4ccd1e71fc&"></img>
          <br></br>
          <h4>!numguess</h4>
          <p>Displays the following image in the chat</p>
          <img className="commandImg" src="https://cdn.discordapp.com/attachments/867970381426880562/1172026888114425876/Num1-10.png?ex=655ed219&is=654c5d19&hm=ecadfc4c72cc3885a401a8deb1995592212552251bfc790030770880afed6e4f&"></img>
          <br></br>
          <h4>!closed</h4>
          <p>Displays the following image in the chat</p>
          <img className="commandImg" src="https://cdn.discordapp.com/attachments/867970381426880562/1172026910889496656/closeRound.png?ex=655ed21e&is=654c5d1e&hm=4b687272af989d6aa49d0691a302489106540bfd0eab245454dc59d449d1cd6e&"></img>
          <br></br>
          <h4>!soon</h4>
          <p>Displays the following image in the chat</p>
          <img className="commandImg" src="https://cdn.discordapp.com/attachments/867970381426880562/1172026945798680667/ClosingSoon.png?ex=655ed227&is=654c5d27&hm=e7b60c4f0f58ef70932707adb202466808ed0f917be11311de75ef3327a2599d&"></img>
          <br></br>
          <h4>!highest</h4>
          <p>Displays the following image in the chat</p>
          <img className="commandImg" src="https://cdn.discordapp.com/attachments/867970381426880562/1172026976513572944/HighestRoll.png?ex=655ed22e&is=654c5d2e&hm=0bb7f6d891443749af8af8d74c4bd07c3e16257f79bed1292ca90c242d4998d7&"></img>
          <br></br>
          <h4>!complete</h4>
          <p>Displays the following image in the chat</p>
          <img className="commandImg" src="https://cdn.discordapp.com/attachments/867970381426880562/1172027014392320090/ChatGiveawayEnd.png?ex=655ed237&is=654c5d37&hm=9237f295208c39bd7675e2cfdf4dcea436b7ca6897b694b4fed36254fad6f837&"></img>
          <br></br>
          <h4>!energy</h4>
          <p>Displays the following image in the chat</p>
          <img className="commandImg" src="https://cdn.discordapp.com/attachments/867970381426880562/1172035454367977542/GuessEnergy.png?ex=655eda13&is=654c6513&hm=2327f7a90450b0b09c5edd157be986ddc288bd68688dcceb01c91f1b98f3bed3&"></img>
          <br></br>
          <h4>!startholiday</h4>
          <p>Starts the holiday game and spawns the first holiday game spawn.</p>
          <br></br>
          <h4>!stopholiday</h4>
          <p>Stops the holiday game, but does not clear the leaderboards.</p>
          <br></br>
          <h4>!startgiveaway</h4>
          <p>Starts a !raffle giveaway in the Live Chat, enabling the !raffle command.</p>
          <br></br>
          <h4>!stopgiveaway</h4>
          <p>Disables !raffle, so people will not be able to enter the giveaway. Does not clear the entries.</p>
          <br></br>
          <h4>!drawwinner</h4>
          <p>Picks a winner from the raffle giveaway entries and removes them from the entries so they cannot be picked again.</p>
          <br></br>
          <h4>!cleargiveaway</h4>
          <p>Clears the entries for the raffle giveaway.</p>
          <br></br>
          <h4>!startmining</h4>
          <p>Starts the mining game and triggers the first mining spawn.</p>
          <br></br>
          <h4>!stopmining</h4>
          <p>Stops the mining game, but does not clear any player's mining stats.</p>
          <br></br>
          <h4>!announce</h4>
          <p>Sends an announcement in the announcement bar at the top of every channel. This will not include any images.</p>

        </section >
      </main>

    </>
  )
}

export default Commands;
