import React, { useRef, useState, useEffect, useCallback } from 'react';
import './App.css';
import axios from 'axios';
import $ from 'jquery';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import TextareaAutosize from 'react-textarea-autosize';
import { useForm } from 'react-hook-form';
import { Routes, Route, Link } from 'react-router-dom'
import NavBar from './NavBar'
import jsonp from 'jsonp';
// &nbsp;  - newline in markdown

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import { useAuthState, useUpdateProfile } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { doc, deleteDoc, getDocs } from "firebase/firestore";
import { async } from 'q';

const { Octokit } = require("@octokit/core");


const octokit = new Octokit({
  auth: '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'
})

firebase.initializeApp({
  apiKey: "AIzaSyA75UUNZNK8nWE8_Edi2JfuEGvaexMAOs8",
  authDomain: "collectorscorner-c55a8.firebaseapp.com",
  projectId: "collectorscorner-c55a8",
  storageBucket: "collectorscorner-c55a8.appspot.com",
  messagingSenderId: "110731187943",
  appId: "1:110731187943:web:f10968078d6366615bb442",
  measurementId: "G-HZF30FZXYS"
})

const auth = firebase.auth();
const firestore = firebase.firestore();
const version = '2.1.03';

let rewardType = "shake";


const sendQuery = async (URL, query) => {
  const url = URL;
  const formData = new FormData();
  formData.append('query', query);
  // for (var key of formData.entries()) {
  //   console.log(key[0] + ', ' + key[1]);
  // }
  let resp = "";
  await $.ajax({
    url: url,
    cache: false,
    dataType: 'text',
    contentType: false,
    processData: false,
    data: formData,
    type: 'POST',
    success: function (response) {
      console.log(response);
      resp = response;
    },
    error: function (error) {
      console.log(error);
      resp = "ERROR";
      alert("An error occurred accessing the server database");
    }
  });
  return resp;
}

const checkVersion = async (versionInfo) => {

  console.log(version, versionInfo['version']);
  return versionInfo['version'] === version;
}

const checkChatEnabled = async (versionInfo) => {
  console.log(version, versionInfo['enabled']);
  return versionInfo['enabled'] === '1';
}

const checkAdminChatEnabled = async (versionInfo, admin, channel) => {
  console.log(version, versionInfo['admin_enabled']);
  return versionInfo['admin_enabled'] === '1' || admin || channel === "live";
}

const handleBattle = async (challenger, challenged) => {
  const usersRef = await firestore.collection('users');
  const challengerSnap = await usersRef.where("displayName", "==", challenger).get();
  const challengedSnap = await usersRef.where("displayName", "==", challenged).get();
  let accepted = challenged === "Chopi";
  console.log(challengedSnap.empty)
  if (challengedSnap.empty && !accepted) {
    return 0;
  }
  const battles = await sendQuery("/battle.php", "SELECT * FROM `battles`");
  console.log(battles);
  if (battles !== "0") {
    return 1;
  }
  let challengerUid = challenger
  challengerSnap.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    challengerUid = doc.data().uid;
  });
  let challengedUid = challenged
  if (accepted) {
    challengedUid = "0"
  }
  else {
    challengedSnap.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      challengedUid = doc.data().uid;
    });
  }
  let challengedChoice = 0;
  if (accepted) {
    challengedChoice = Math.floor(Math.random() * 3) + 1
  }
  const update = await sendQuery("/update_battles.php", "INSERT INTO `battles`(`accepted`, `challenged`, `challengedChoice`, `challengedHealth`, `challengedUid`, `challenger`, `challengerChoice`, `challengerHealth`, `challengerUid`, `createdAt`, `idVal`) VALUES (" + accepted + ",'"
    + challenged + "','" + challengedChoice + "','8','" + challengedUid + "','" + challenger + "','0','8','" + challengerUid +
    "',CURRENT_TIME,'1')");
  console.log(update);
  if (accepted) {
    return 3;
  }
  return 2;
}

const handleAccept = async (uid) => {
  const battles1 = await sendQuery("/battle.php", "SELECT * FROM `battles` WHERE `challengedUid` = '" + uid + "'");
  console.log(battles1);
  if (battles1 === "0") {
    return 0;
  }
  const update = await sendQuery("/update_battles.php", "UPDATE `battles` SET `accepted`=true");
  return 1;
}

const handleReject = async (uid, admin) => {
  const battles1 = await sendQuery("/battle.php", "SELECT * FROM `battles` WHERE `challengedUid` = '" + uid + "'");
  console.log(battles1);
  const battles2 = await sendQuery("/battle.php", "SELECT * FROM `battles` WHERE `challengerUid` = '" + uid + "'");
  console.log(battles2);
  if (battles1 === "0" && battles2 === "0" && !admin) {
    return 0;
  }
  const update = await sendQuery("/update_battles.php", "DELETE FROM `battles`");
  console.log(update);
  return 1;
}

const computeWinner = (choice1, choice2) => {
  if (choice1 === choice2) {
    return 0;
  }
  if (choice1 === 1 && choice2 === 2) {
    return 2;
  }
  if (choice1 === 1 && choice2 === 3) {
    return 1;
  }
  if (choice1 === 2 && choice2 === 1) {
    return 1;
  }
  if (choice1 === 2 && choice2 === 3) {
    return 2;
  }
  if (choice1 === 3 && choice2 === 1) {
    return 2;
  }
  if (choice1 === 3 && choice2 === 2) {
    return 1;
  }

}

const handleChoice = async (displayName, uid, choice) => {
  let response = "";
  let imgURL = "";
  let choiceCode = 0;
  if (choice === "water") {
    choiceCode = 1;
  }
  else if (choice === "grass") {
    choiceCode = 2;
  }
  else if (choice === "fire") {
    choiceCode = 3;
  }
  else {
    response = "@" + displayName + ", invalid battle choice. The options are water, grass, and fire. Please try again.";
    imgURL = "";
    console.log(response);
    console.log(imgURL);
    return { response, imgURL };
  }
  const acceptedSnap = await sendQuery("/battle.php", "SELECT * FROM `battles` WHERE `accepted` = true");
  console.log(acceptedSnap);
  if (acceptedSnap === "0") {
    response = "@" + displayName + ", Battle has not yet been accepted.";
    imgURL = ""
    return { response, imgURL };
  }
  const battles1 = await sendQuery("/battle.php", "SELECT * FROM `battles` WHERE `challengedUid` = '" + uid + "'");
  console.log(battles1);
  const battles2 = await sendQuery("/battle.php", "SELECT * FROM `battles` WHERE `challengerUid` = '" + uid + "'");
  console.log(battles2);

  let otherChoice = 0;
  if (battles1 !== "0") {
    const battleInfo = JSON.parse(battles1);
    console.log(battleInfo);
    console.log(battleInfo['challengedChoice']);
    let isChosenAlready = battleInfo['challengedChoice'] !== '0';
    let challengedPoints = battleInfo['challengedHealth'];
    let challengerPoints = battleInfo['challengerHealth'];
    let challengedDisp = battleInfo['challenged'];
    let challengerDisp = battleInfo['challenger'];
    otherChoice = parseInt(battleInfo['challengerChoice']);
    if (isChosenAlready) {
      response = "@" + displayName + ", you have already chosen, please wait for your opponent to choose.";
      imgURL = "";
      return { response, imgURL };
    }
    if (otherChoice !== 0) {
      let winner = computeWinner(choiceCode, otherChoice);
      if (winner === 0) {
        const update = await sendQuery("/update_battles.php", "UPDATE `battles` SET `challengerChoice`='0'");
        response = "Both chose " + choice + ". Battle Round Tie. " + challengedDisp + " ( HP: " +
          challengedPoints + " ), " + challengerDisp + " ( HP : " + challengerPoints + " ). Next Round! Type '!choose water', '!choose grass', or '!choose fire' to choose what type to battle with.";
        switch (choiceCode) {
          case 1:
            imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961293484310578/waterVwater.png?ex=6570c59f&is=655e509f&hm=f20bc202956a99b26d7cc79de4c6c3e701d9bf777cae7b1a3dc7aca9bd26cde3&";
            break;
          case 2:
            imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961267651592272/grassVgrass.png?ex=6570c599&is=655e5099&hm=153c5cfb0c7b7cbf72c06ea4abac350192964d7248005d4ad55005629146be22&";
            break;
          case 3:
            imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961226752917544/fireVfire.png?ex=6570c58f&is=655e508f&hm=e13968b8ab1fd16eb17686fd8008a78229e30f102000f383bb0dd67cdd723f01&";
            break;
        }
        return { response, imgURL };
      }
      else if (winner === 1) {
        let points = Math.floor(Math.random() * 5) + 1
        challengerPoints = challengerPoints - points;
        let challengerChoice = "";
        switch (otherChoice) {
          case 1:
            challengerChoice = "water";
            switch (choiceCode) {
              case 2:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961365177532507/waterVgrass.png?ex=6570c5b0&is=655e50b0&hm=dcaa7f0afce92cc9e6035356abfa6b5f80c54df7e47919742c62a725e59e7260&";
                break;
              case 3:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961406881509406/fireVwater.png?ex=6570c5ba&is=655e50ba&hm=43276e229bd1275d5ae409d174633c102d32c8dfd5a4ec95afe2d0435f73e79b&";
                break;
            }
            break;
          case 2:
            challengerChoice = "grass";
            switch (choiceCode) {
              case 1:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961365177532507/waterVgrass.png?ex=6570c5b0&is=655e50b0&hm=dcaa7f0afce92cc9e6035356abfa6b5f80c54df7e47919742c62a725e59e7260&";
                break;
              case 3:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961329672757378/grassVfire.png?ex=6570c5a7&is=655e50a7&hm=df1df545faf7d6e18bb450d9629cd3c47f0298b1f2992b5719ca04c1b16a3235&";
                break;
            }
            break;
          case 3:
            challengerChoice = "fire";
            switch (choiceCode) {
              case 1:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961406881509406/fireVwater.png?ex=6570c5ba&is=655e50ba&hm=43276e229bd1275d5ae409d174633c102d32c8dfd5a4ec95afe2d0435f73e79b&";
                break;
              case 2:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961329672757378/grassVfire.png?ex=6570c5a7&is=655e50a7&hm=df1df545faf7d6e18bb450d9629cd3c47f0298b1f2992b5719ca04c1b16a3235&";
                break;
            }
            break;
        }
        if (challengerPoints <= 0) {
          const update = await sendQuery("/update_battles.php", "DELETE FROM `battles`");
          response = challengedDisp + " Wins the Battle! " + challengerDisp + " chose " + challengerChoice + ", " + challengedDisp + " chose " + choice +
            ". " + challengedDisp + " Wins this Round, dealing " + points + " damage to " + challengerDisp + ". " + challengedDisp + " ( HP: " +
            challengedPoints + " ) | " + challengerDisp + " ( HP : " + challengerPoints + " ). ";
          return { response, imgURL };
        }
        const update = await sendQuery("/update_battles.php", "UPDATE `battles` SET `challengerChoice`='0', `challengerHealth`='" + challengerPoints + "'");
        response = challengerDisp + " chose " + challengerChoice + ", " + challengedDisp + " chose " + choice +
          ". " + challengedDisp + " Wins this Round, dealing " + points + " damage to " + challengerDisp + ". " + challengedDisp + " ( HP: " +
          challengedPoints + " ) | " + challengerDisp + " ( HP : " + challengerPoints + " ). Type '!choose water', '!choose grass', or '!choose fire' to choose what type to battle with.";
        return { response, imgURL };
      }
      else {
        let points = Math.floor(Math.random() * 5) + 1
        challengedPoints = challengedPoints - points;
        let challengerChoice = "";
        switch (otherChoice) {
          case 1:
            challengerChoice = "water";
            switch (choiceCode) {
              case 2:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961365177532507/waterVgrass.png?ex=6570c5b0&is=655e50b0&hm=dcaa7f0afce92cc9e6035356abfa6b5f80c54df7e47919742c62a725e59e7260&";
                break;
              case 3:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961406881509406/fireVwater.png?ex=6570c5ba&is=655e50ba&hm=43276e229bd1275d5ae409d174633c102d32c8dfd5a4ec95afe2d0435f73e79b&";
                break;
            }
            break;
          case 2:
            challengerChoice = "grass";
            switch (choiceCode) {
              case 1:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961365177532507/waterVgrass.png?ex=6570c5b0&is=655e50b0&hm=dcaa7f0afce92cc9e6035356abfa6b5f80c54df7e47919742c62a725e59e7260&";
                break;
              case 3:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961329672757378/grassVfire.png?ex=6570c5a7&is=655e50a7&hm=df1df545faf7d6e18bb450d9629cd3c47f0298b1f2992b5719ca04c1b16a3235&";
                break;
            }
            break;
          case 3:
            challengerChoice = "fire";
            switch (choiceCode) {
              case 1:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961406881509406/fireVwater.png?ex=6570c5ba&is=655e50ba&hm=43276e229bd1275d5ae409d174633c102d32c8dfd5a4ec95afe2d0435f73e79b&";
                break;
              case 2:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961329672757378/grassVfire.png?ex=6570c5a7&is=655e50a7&hm=df1df545faf7d6e18bb450d9629cd3c47f0298b1f2992b5719ca04c1b16a3235&";
                break;
            }
            break;
        }
        if (challengedPoints <= 0) {
          const update = await sendQuery("/update_battles.php", "DELETE FROM `battles`");
          response = challengerDisp + " Wins the Battle! " + challengerDisp + " chose " + challengerChoice + ", " + challengedDisp + " chose " + choice +
            ". " + challengerDisp + " Wins this Round, dealing " + points + " damage to " + challengedDisp + ". " + challengedDisp + " ( HP: " +
            challengedPoints + " ) | " + challengerDisp + " ( HP : " + challengerPoints + " ). ";
          return { response, imgURL };
        }
        const update = await sendQuery("/update_battles.php", "UPDATE `battles` SET `challengerChoice`='0', `challengedHealth`='" + challengedPoints + "'");
        response = challengerDisp + " chose " + challengerChoice + ", " + challengedDisp + " chose " + choice +
          ". " + challengerDisp + " Wins this Round, dealing " + points + " damage to " + challengedDisp + ". " + challengedDisp + " ( HP: " +
          challengedPoints + " ) | " + challengerDisp + " ( HP : " + challengerPoints + " ). Type '!choose water', '!choose grass', or '!choose fire' to choose what type to battle with.";
        return { response, imgURL };
      }
    }
    else {
      const update = await sendQuery("/update_battles.php", "UPDATE `battles` SET `challengedChoice`='" + choiceCode + "'");
      response = challengedDisp + " has made their choice!"
      imgURL = "";
      return { response, imgURL };
    }
  }
  else if (battles2 !== "0") {
    const battleInfo = JSON.parse(battles2);
    console.log(battleInfo);
    console.log(battleInfo['challengerChoice']);
    otherChoice = parseInt(battleInfo['challengedChoice']);
    let isChosenAlready = battleInfo['challengerChoice'] !== '0';
    let challengedPoints = battleInfo['challengedHealth'];
    let challengerPoints = battleInfo['challengerHealth'];
    let challengedDisp = battleInfo['challenged'];
    let challengerDisp = battleInfo['challenger'];
    let isChopi = challengedDisp === "Chopi";
    if (isChosenAlready) {
      response = "@" + displayName + ", you have already chosen, please wait for your opponent to choose.";
      imgURL = "";
      return { response, imgURL };
    }
    if (otherChoice !== 0) {
      let winner = computeWinner(otherChoice, choiceCode);
      if (winner === 0) {
        let newChoice = 0;
        if (isChopi) {
          newChoice = Math.floor(Math.random() * 3) + 1
        }
        const update = await sendQuery("/update_battles.php", "UPDATE `battles` SET `challengedChoice`='" + newChoice + "'");
        response = "Both chose " + choice + ". Battle Round Tie. " + challengedDisp + " ( HP: " +
          challengedPoints + " ) | " + challengerDisp + " ( HP : " + challengerPoints + " ). Next Round! Type '!choose water', '!choose grass', or '!choose fire' to choose what type to battle with.";
        if (isChopi) {
          response = response + " Chopi has chosen.";
        }
        switch (choiceCode) {
          case 1:
            imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961293484310578/waterVwater.png?ex=6570c59f&is=655e509f&hm=f20bc202956a99b26d7cc79de4c6c3e701d9bf777cae7b1a3dc7aca9bd26cde3&";
            break;
          case 2:
            imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961267651592272/grassVgrass.png?ex=6570c599&is=655e5099&hm=153c5cfb0c7b7cbf72c06ea4abac350192964d7248005d4ad55005629146be22&";
            break;
          case 3:
            imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961226752917544/fireVfire.png?ex=6570c58f&is=655e508f&hm=e13968b8ab1fd16eb17686fd8008a78229e30f102000f383bb0dd67cdd723f01&";
            break;
        }
        return { response, imgURL };
      }
      else if (winner === 1) {
        let points = Math.floor(Math.random() * 5) + 1
        challengerPoints = challengerPoints - points;
        let challengedChoice = "";
        switch (otherChoice) {
          case 1:
            challengedChoice = "water";
            switch (choiceCode) {
              case 2:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961365177532507/waterVgrass.png?ex=6570c5b0&is=655e50b0&hm=dcaa7f0afce92cc9e6035356abfa6b5f80c54df7e47919742c62a725e59e7260&";
                break;
              case 3:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961406881509406/fireVwater.png?ex=6570c5ba&is=655e50ba&hm=43276e229bd1275d5ae409d174633c102d32c8dfd5a4ec95afe2d0435f73e79b&";
                break;
            }
            break;
          case 2:
            challengedChoice = "grass";
            switch (choiceCode) {
              case 1:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961365177532507/waterVgrass.png?ex=6570c5b0&is=655e50b0&hm=dcaa7f0afce92cc9e6035356abfa6b5f80c54df7e47919742c62a725e59e7260&";
                break;
              case 3:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961329672757378/grassVfire.png?ex=6570c5a7&is=655e50a7&hm=df1df545faf7d6e18bb450d9629cd3c47f0298b1f2992b5719ca04c1b16a3235&";
                break;
            }
            break;
          case 3:
            challengedChoice = "fire";
            switch (choiceCode) {
              case 1:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961406881509406/fireVwater.png?ex=6570c5ba&is=655e50ba&hm=43276e229bd1275d5ae409d174633c102d32c8dfd5a4ec95afe2d0435f73e79b&";
                break;
              case 2:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961329672757378/grassVfire.png?ex=6570c5a7&is=655e50a7&hm=df1df545faf7d6e18bb450d9629cd3c47f0298b1f2992b5719ca04c1b16a3235&";
                break;
            }
            break;
        }
        if (challengerPoints <= 0) {
          const update = await sendQuery("/update_battles.php", "DELETE FROM `battles`");
          response = challengedDisp + " Wins the Battle! " + challengedDisp + " chose " + challengedChoice + ", " + challengerDisp + " chose " + choice +
            ". " + challengedDisp + " Wins this Round, dealing " + points + " damage to " + challengerDisp + ". " + challengedDisp + " ( HP: " +
            challengedPoints + " ) | " + challengerDisp + " ( HP : " + challengerPoints + " ). ";
          return { response, imgURL };
        }
        let newChoice = 0;
        if (isChopi) {
          newChoice = Math.floor(Math.random() * 3) + 1
        }
        const update = await sendQuery("/update_battles.php", "UPDATE `battles` SET `challengedChoice`='" + newChoice + "', `challengerHealth`='" + challengerPoints + "'");
        response = challengedDisp + " chose " + challengedChoice + ", " + challengerDisp + " chose " + choice +
          ". " + challengedDisp + " Wins this Round, dealing " + points + " damage to " + challengerDisp + ". " + challengedDisp + " ( HP: " +
          challengedPoints + " ) | " + challengerDisp + " ( HP : " + challengerPoints + " ). Type '!choose water', '!choose grass', or '!choose fire' to choose what type to battle with.";
        if (isChopi) {
          response = response + " Chopi has chosen.";
        }
        return { response, imgURL };
      }
      else {
        let points = Math.floor(Math.random() * 5) + 1
        challengedPoints = challengedPoints - points;
        let challengedChoice = "";
        switch (otherChoice) {
          case 1:
            challengedChoice = "water";
            switch (choiceCode) {
              case 2:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961365177532507/waterVgrass.png?ex=6570c5b0&is=655e50b0&hm=dcaa7f0afce92cc9e6035356abfa6b5f80c54df7e47919742c62a725e59e7260&";
                break;
              case 3:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961406881509406/fireVwater.png?ex=6570c5ba&is=655e50ba&hm=43276e229bd1275d5ae409d174633c102d32c8dfd5a4ec95afe2d0435f73e79b&";
                break;
            }
            break;
          case 2:
            challengedChoice = "grass";
            switch (choiceCode) {
              case 1:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961365177532507/waterVgrass.png?ex=6570c5b0&is=655e50b0&hm=dcaa7f0afce92cc9e6035356abfa6b5f80c54df7e47919742c62a725e59e7260&";
                break;
              case 3:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961329672757378/grassVfire.png?ex=6570c5a7&is=655e50a7&hm=df1df545faf7d6e18bb450d9629cd3c47f0298b1f2992b5719ca04c1b16a3235&";
                break;
            }
            break;
          case 3:
            challengedChoice = "fire";
            switch (choiceCode) {
              case 1:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961406881509406/fireVwater.png?ex=6570c5ba&is=655e50ba&hm=43276e229bd1275d5ae409d174633c102d32c8dfd5a4ec95afe2d0435f73e79b&";
                break;
              case 2:
                imgURL = "https://cdn.discordapp.com/attachments/868324517364269127/1176961329672757378/grassVfire.png?ex=6570c5a7&is=655e50a7&hm=df1df545faf7d6e18bb450d9629cd3c47f0298b1f2992b5719ca04c1b16a3235&";
                break;
            }
            break;
        }
        if (challengedPoints <= 0) {
          const update = await sendQuery("/update_battles.php", "DELETE FROM `battles`");
          response = challengerDisp + " Wins the Battle! " + challengedDisp + " chose " + challengedChoice + ", " + challengerDisp + " chose " + choice +
            ". " + challengerDisp + " Wins this Round, dealing " + points + " damage to " + challengedDisp + ". " + challengedDisp + " ( HP: " +
            challengedPoints + " ) | " + challengerDisp + " ( HP : " + challengerPoints + " )";
          return { response, imgURL };
        }
        let newChoice = 0;
        if (isChopi) {
          newChoice = Math.floor(Math.random() * 3) + 1
        }
        const update = await sendQuery("/update_battles.php", "UPDATE `battles` SET `challengedChoice`='" + newChoice + "', `challengedHealth`='" + challengedPoints + "'");
        response = challengedDisp + " chose " + challengedChoice + ", " + challengerDisp + " chose " + choice +
          ". " + challengerDisp + " Wins this Round, dealing " + points + " damage to " + challengedDisp + ". " + challengedDisp + " ( HP: " +
          challengedPoints + " ) | " + challengerDisp + " ( HP : " + challengerPoints + " ). Type '!choose water', '!choose grass', or '!choose fire' to choose what type to battle with.";
        if (isChopi) {
          response = response + " Chopi has chosen.";
        }
        return { response, imgURL };
      }
    }
    else {
      const update = await sendQuery("/update_battles.php", "UPDATE `battles` SET `challengerChoice`='" + choiceCode + "'");
      response = challengerDisp + " has made their choice!"
      imgURL = "";
      return { response, imgURL };
    }
  }
  else {
    response = "@" + displayName + ", you are not in a battle.";
    imgURL = "";
    return { response, imgURL };
  }

}

const handleTrivia = async (dispName) => {
  const active = await sendQuery("/battle.php", "SELECT * FROM `activetrivia`");
  console.log(active);
  if (active !== "0") {
    return "@" + dispName + ", There is already an active trivia question";
  }
  const trivia = await sendQuery("/trivia.php", "SELECT * FROM `trivia`");
  const triviaInfo = JSON.parse(trivia);
  console.log(triviaInfo);
  const randomVal = Math.floor(Math.random() * triviaInfo.length);
  const question = triviaInfo[randomVal];
  const q = question['Question'];
  const a1 = question['A1'];
  const a2 = question['A2'];
  const a3 = question['A3'];
  const a4 = question['A4'];
  const c = question['Correct'];
  let answerval = a1;
  if (c === '1') {
    answerval = a1;
  }
  else if (c === '2') {
    answerval = a2;
  }
  else if (c === '3') {
    answerval = a3;
  }
  else if (c === '4') {
    answerval = a4;
  }
  sendQuery("/update_battles.php", "INSERT INTO `activetrivia`(`Correct`,`Answerval`,`Question`, `A1`, `A2`, `A3`, `A4`) VALUES ('" + c + "','" + answerval + "','" + q + "','"
    + a1 + "','" + a2 + "','" + a3 + "','" + a4 + "')");
  return q + "  \n1. 1: " + a1 + "  \n2. 2: " + a2 + "  \n3. 3: " + a3 + "  \n4. 4: " + a4 + "  \nPick which answer you think is correct with !pick and then the corresponding number (ex: !pick 1). Anyone can answer the question."
}



const handlePokemoji = async (dispName) => {
  const active = await sendQuery("/battle.php", "SELECT * FROM `activepokemoji`");
  console.log(active);
  if (active !== "0") {
    return "@" + dispName + ", There is already an active Pokemoji question";
  }
  const trivia = await sendQuery("/trivia.php", "SELECT * FROM `pokemoji`");
  const triviaInfo = JSON.parse(trivia);
  console.log(triviaInfo);
  const randomVal = Math.floor(Math.random() * triviaInfo.length);
  const question = triviaInfo[randomVal];
  const q = question['Emoji'];
  const c = question['Answer'];
  sendQuery("/update_battles.php", "INSERT INTO `activepokemoji`(`Answerval`,`Emoji`) VALUES ('" + c + "','" + q + "')");
  return q + "  \nGuess the pokemon with !guess and then the name of pokemon you want to guess (Spelling counts). Anyone can answer the question."
}

const handleGuess = async (dispName, choice) => {
  const active = await sendQuery("/battle.php", "SELECT * FROM `activepokemoji`");
  console.log(active);
  if (active === "0") {
    return "@" + dispName + ", There is no active Pokemoji question. Please use !pokemoji to request a Pokemoji question.";
  }
  const activeInfo = JSON.parse(active);
  const answerval = activeInfo['Answerval']
  if (answerval.toLowerCase() === choice.toLowerCase()) {
    const update = await sendQuery("/update_battles.php", "DELETE FROM `activepokemoji`");
    return "@" + dispName + ", You got it right! The answer was: " + answerval;
  }
  else {
    return "@" + dispName + ", That is incorrect. Please try again";
  }
}

const handlePick = async (dispName, choice) => {
  const active = await sendQuery("/battle.php", "SELECT * FROM `activetrivia`");
  console.log(active);
  if (active === "0") {
    return "@" + dispName + ", There is no active trivia question. Please use !trivia to request a trivia question.";
  }
  const activeInfo = JSON.parse(active);
  const c = activeInfo['Correct'];
  const answerval = activeInfo['Answerval']
  if (c === choice) {
    const update = await sendQuery("/update_battles.php", "DELETE FROM `activetrivia`");
    return "@" + dispName + ", You got it right! The answer was: " + answerval;
  }
  else {
    return "@" + dispName + ", That is incorrect. Please try again";
  }
}

const setNextSpawn = async () => {
  const curTime = await sendQuery("/battle.php", "SELECT CURTIME() as `time`");
  console.log(curTime);
  const timeVal = JSON.parse(curTime)['time'];
  console.log(timeVal);
  const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
  const holidayInfo = JSON.parse(holidayActive);
  let period = parseInt(holidayInfo['period']);
  let [h, m, s] = timeVal.split(":");
  let min = Math.ceil(parseInt(m) / period) * period;
  let hr = parseInt(h) % 12;
  if (min === 60) {
    min = 0;
    hr = (hr + 1) % 12;
  }
  const timeStr = ("00" + hr).slice(-2) + ":" + ("00" + min).slice(-2) + ":" + s;
  console.log(timeStr);
  const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `Active`=true,`Time`='" + timeStr + "'");
}

const setNextOreSpawn = async () => {
  const curTime = await sendQuery("/battle.php", "SELECT CURTIME() as `time`");
  console.log(curTime);
  const timeVal = JSON.parse(curTime)['time'];
  console.log(timeVal);
  const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
  const holidayInfo = JSON.parse(holidayActive);
  let period = parseInt(holidayInfo['MiningPeriod']);
  let [h, m, s] = timeVal.split(":");
  let min = Math.ceil(parseInt(m) / period) * period;
  let hr = parseInt(h) % 12;
  if (min === 60) {
    min = 0;
    hr = (hr + 1) % 12;
  }
  const timeStr = ("00" + hr).slice(-2) + ":" + ("00" + min).slice(-2) + ":" + s;
  console.log(timeStr);
  const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningTime`='" + timeStr + "'");
}

const clearHolidayStatus = async () => {
  const update = await sendQuery("/update_battles.php", "DELETE FROM `holidayshaken`");

}

const clearMined = async () => {
  const update = await sendQuery("/update_battles.php", "DELETE FROM `mined`");

}

const checkNextSpawn = async () => {
  const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
  const holidayInfo = JSON.parse(holidayActive);
  if (holidayInfo['Active'] !== '0') {
    const curTime = await sendQuery("/battle.php", "SELECT CURTIME() as `time`");
    console.log(curTime);
    const timeVal = JSON.parse(curTime)['time'];
    console.log(timeVal);
    let [h, m, s] = timeVal.split(":");
    const spawnTimeVal = holidayInfo['Time'];
    let [sh, sm, ss] = spawnTimeVal.split(":");
    let hr = parseInt(h) % 12;
    let sHr = parseInt(sh) % 12;
    let min = parseInt(m);
    let sMin = parseInt(sm);
    if (hr === sHr - 1) {
      return false;
    }
    if (hr === 11 && sHr === 0) {
      return false;
    }
    if (hr !== sHr) {
      return true;
    }
    if (min > sMin) {
      return true;
    }
  }
  return false;
}


const checkNextOreSpawn = async () => {
  const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
  const holidayInfo = JSON.parse(holidayActive);
  if (holidayInfo['MiningActive'] !== '0') {
    const curTime = await sendQuery("/battle.php", "SELECT CURTIME() as `time`");
    console.log(curTime);
    const timeVal = JSON.parse(curTime)['time'];
    console.log(timeVal);
    let [h, m, s] = timeVal.split(":");
    const spawnTimeVal = holidayInfo['MiningTime'];
    let [sh, sm, ss] = spawnTimeVal.split(":");
    let hr = parseInt(h) % 12;
    let sHr = parseInt(sh) % 12;
    let min = parseInt(m);
    let sMin = parseInt(sm);
    if (hr === sHr - 1) {
      return false;
    }
    if (hr === 11 && sHr === 0) {
      return false;
    }
    if (hr !== sHr) {
      return true;
    }
    if (min > sMin) {
      return true;
    }
  }
  return false;
}

const selectOre = async (miningType) => {
  if (miningType === -1) {
    const randomVal = Math.floor(Math.random() * 400);
    if (randomVal < 190) {
      const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningType`=0");
      return "https://collectingcommunity.com/image/mining/Coal.png";
    }
    if (randomVal < 240) {
      const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningType`=1");
      return "https://collectingcommunity.com/image/mining/Tin.png";
    }
    if (randomVal < 280) {
      const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningType`=2");
      return "https://collectingcommunity.com/image/mining/Aluminum.png";
    }
    if (randomVal < 310) {
      const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningType`=3");
      return "https://collectingcommunity.com/image/mining/Copper.png";
    }
    if (randomVal < 330) {
      const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningType`=4");
      return "https://collectingcommunity.com/image/mining/Iron.png";
    }
    if (randomVal < 341) {
      const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningType`=5");
      return "https://collectingcommunity.com/image/mining/Silver.png";
    }
    if (randomVal < 349) {
      const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningType`=6");
      return "https://collectingcommunity.com/image/mining/Gold.png";
    }
    if (randomVal < 354) {
      const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningType`=7");
      return "https://collectingcommunity.com/image/mining/Titanium.png";
    }
    if (randomVal < 357) {
      const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningType`=8");
      return "https://collectingcommunity.com/image/mining/Cobalt.png";
    }
    if (randomVal < 359) {
      const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningType`=9");
      return "https://collectingcommunity.com/image/mining/Platinum.png";
    }
    if (randomVal < 360) {
      const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningType`=10");
      return "https://collectingcommunity.com/image/mining/Diamond.png";
    }
    const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningType`=11");
    return "https://collectingcommunity.com/image/mining/Furnace.png";
  }
  if (miningType === 11) {
    const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningType`=11");
    return "https://collectingcommunity.com/image/mining/Furnace.png";
  }
}

const spawn = async (messagesRef, uid) => {
  let checkSpawn = await checkNextSpawn();
  if (checkSpawn) {
    const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
    const holidayInfo = JSON.parse(holidayActive);
    await clearHolidayStatus();
    await setNextSpawn();
    let imgURL = holidayInfo['picurl'];
    const res = await messagesRef.add({
      text: "",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid: "chopi",
      photoURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw-FaIPhk69fDUfuCzboUTzkks8gpzdKkcBg&usqp=CAU",
      displayName: "Chopi (BOT)",
      image: imgURL,
      color: "#34cceb",
      badge: "",
      famTier: false,
      friendTier: false,
      comTier: false,
      replyUser: "",
      replyImg: "",
      replyText: "",
    });
    res.set({
      text: "",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid: "chopi",
      photoURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw-FaIPhk69fDUfuCzboUTzkks8gpzdKkcBg&usqp=CAU",
      displayName: "Chopi (BOT)",
      image: imgURL,
      color: "#34cceb",
      badge: "",
      famTier: false,
      friendTier: false,
      comTier: false,
      replyUser: "",
      replyImg: "",
      replyText: "",
      idVal: res.id
    })
  }
  let checkOreSpawn = await checkNextOreSpawn();
  if (checkOreSpawn) {
    await clearMined();
    await setNextOreSpawn();
    let imgURL = await selectOre(-1);
    const res = await messagesRef.add({
      text: "",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid: "chopi",
      photoURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw-FaIPhk69fDUfuCzboUTzkks8gpzdKkcBg&usqp=CAU",
      displayName: "Chopi (BOT)",
      image: imgURL,
      color: "#34cceb",
      badge: "",
      famTier: false,
      friendTier: false,
      comTier: false,
      replyUser: "",
      replyImg: "",
      replyText: "",
    });
    res.set({
      text: "",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid: "chopi",
      photoURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw-FaIPhk69fDUfuCzboUTzkks8gpzdKkcBg&usqp=CAU",
      displayName: "Chopi (BOT)",
      image: imgURL,
      color: "#34cceb",
      badge: "",
      famTier: false,
      friendTier: false,
      comTier: false,
      replyUser: "",
      replyImg: "",
      replyText: "",
      idVal: res.id
    })
  }
}

const instantSpawn = async (messagesRef, uid, miningType, numLeft) => {

  await clearMined();
  await setNextOreSpawn();
  let imgURL = await selectOre(miningType);
  const res = await messagesRef.add({
    text: miningType === -1 ? "There are " + numLeft + " spawns left in the ore rush." : "",
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    uid: "chopi",
    photoURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw-FaIPhk69fDUfuCzboUTzkks8gpzdKkcBg&usqp=CAU",
    displayName: "Chopi (BOT)",
    image: imgURL,
    color: "#34cceb",
    badge: "",
    famTier: false,
    friendTier: false,
    comTier: false,
    replyUser: "",
    replyImg: "",
    replyText: "",
  });
  res.set({
    text: miningType === -1 ? "There are " + numLeft + " spawns left in the ore rush." : "",
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    uid: "chopi",
    photoURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw-FaIPhk69fDUfuCzboUTzkks8gpzdKkcBg&usqp=CAU",
    displayName: "Chopi (BOT)",
    image: imgURL,
    color: "#34cceb",
    badge: "",
    famTier: false,
    friendTier: false,
    comTier: false,
    replyUser: "",
    replyImg: "",
    replyText: "",
    idVal: res.id
  })
}

const displayAd = () => {
  // e.preventDefault();
  var main = document.getElementById("modalMessage");
  if (rewardType === "shake") {
    main.innerHTML = "Would you like to view an ad to earn a free second shake on this spawn? (Extra shake will expire when next spawn happens)";
  }
  else {
    main.innerHTML = "Would you like to view an ad to earn a free second mine on this spawn? (Extra mine will expire when next spawn happens)";
  }
  $(".modal").show();
  $(".rewardButtons").show();
  // $(".ad-img").show();
}

const showAd = async (e) => {
  e.preventDefault();

  // $(".modal").hide();
  if (rewardType === "shake") {
    const update = await sendQuery("/update_battles.php", "DELETE FROM `holidayshaken` WHERE UID='" + auth.currentUser.uid + "'");
  }
  else {
    const update = await sendQuery("/update_battles.php", "DELETE FROM `mined` WHERE UID='" + auth.currentUser.uid + "'");

  }

  try {
    if (window.adsbygoogle) {
      window.adsbygoogle.push({});
    }
  } catch (err) {
    console.log("Error in adsense");
  }
  var main = document.getElementById("modalMessage");
  // const trivia = await sendQuery("/trivia.php", "SELECT * FROM `ads`");
  // const triviaInfo = JSON.parse(trivia);
  // console.log(triviaInfo);
  // const randomVal = Math.floor(Math.random() * triviaInfo.length);
  // const question = triviaInfo[randomVal];
  // const img = question['image'];
  // const link = question['link'];
  // $(".ad-link").attr('href', link);
  // $('.ad-img').attr('src', img);
  main.innerHTML = "";
  $(".rewardButtons").hide();
  $(".adsbygoogle").show();
  setTimeout(function () {
    $(".grantButtons").show();
  }, 5000);

}

const closeAd = (e) => {
  e.preventDefault();
  $(".modal").hide();
  $(".rewardButtons").hide();
  $(".grantButtons").hide();
  $(".adsbygoogle").hide();
}

const giveReward = async (e) => {
  e.preventDefault();
  var main = document.getElementById("modalMessage");
  if (rewardType === "shake") {
    main.innerHTML = "Congratulations you have earned 1 extra shake on this spawn!"
  }
  else {
    main.innerHTML = "Congratulations you have earned 1 extra mine on this spawn!"

  }
  // $(".modal").hide();
  $(".rewardButtons").hide();
  $(".grantButtons").hide();
  $(".adsbygoogle").hide();
  $(".completeButtons").show();
}

const completeReward = (e) => {
  e.preventDefault();
  $(".modal").hide();
  $(".rewardButtons").hide();
  $(".grantButtons").hide();
  $(".adsbygoogle").hide();
  $(".completeButtons").hide();
}

const handleCommand = async (messagesRef, uid, formValue, dispName, admin, channel) => {
  let response = "";
  let imgURL = "";
  let showCmd = true;
  let showChopi = true;
  let command = ((formValue.trim()).split(" ")[0]).toLowerCase();

  if (command.startsWith("!roll")) {
    let command = formValue.split(" ");
    if (command.length < 2) {
      response = "@" + dispName + ", incorrect format for roll command";
    }
    else {
      let nums = command[1].split("-");
      let firstNum = parseInt(nums[0]);
      let secondNum = parseInt(nums[1]);
      if (isNaN(firstNum) || isNaN(secondNum) || firstNum > secondNum) {
        response = "@" + dispName + ", incorrect format for roll command";
      }
      else {
        let roll = Math.floor(Math.random() * (secondNum + 1 - firstNum)) + firstNum;
        response = "@" + dispName + " rolled " + command[1] + ". The roll is " + roll;
      }
    }
  }
  else if (command.startsWith("!rules")) {
    response = "@" + dispName + " Here are all the site rules: https://collectingcommunity.com/rules"
  }
  else if (command.startsWith("!8ball")) {
    let responses = ["It is certain", "It is decidedly so", "Without a doubt", "Yes definitely",
      "You may rely on it", "As I see it, yes", "Most likely", "Outlook good", "Yes", "Signs point to yes",
      "Reply hazy, try again", "Ask again later", "Better not tell you now", "Cannot predict now", "Concentrate and ask again",
      "Don't count on it", "My reply is no", "My sources say no", "Outlook not so good", "Very doubtful"];
    response = responses[Math.floor(Math.random() * 20)] + ", @" + dispName;
  }
  else if (command.startsWith("!hug")) {
    let [command, ...rest] = formValue.split(" ");
    response = "@" + dispName + " hugs " + rest.join(" ");
  }
  else if (command.startsWith("!announce")) {
    showChopi = false;
    showCmd = false;
    if (admin) {
      let [command, ...rest] = formValue.split(" ");
      showChopi = false;
      showCmd = false;
      const announcementRef = firestore.collection('cur_announce');
      const res = await announcementRef.add({
        text: rest.join(" "),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      });
      alert("Announcement has been made");
    }
    else {
      alert("You don't have permission to use that command");
    }
  }
  else if (command.startsWith("!chopi")) {
    let responses = ["yes", "maybe", "no"];
    response = responses[Math.floor(Math.random() * 3)] + ", @" + dispName;
  }
  else if (command.startsWith("!discord")) {
    response = 'Check out our discord! [https://discord.gg/7CyfWc44fZ](https://discord.gg/7CyfWc44fZ)'
  }
  else if (command.startsWith("!ongoing")) {
    if (admin) {
      showCmd = false;
      imgURL = "https://cdn.discordapp.com/attachments/867970381426880562/1172026858641035386/InProgress.png?ex=655ed212&is=654c5d12&hm=fc04f03d205d571198c2158b3882f8d99de67929ec0a0412ebe93f4ccd1e71fc&"
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!numguess")) {
    if (admin) {
      showCmd = false;
      imgURL = "https://cdn.discordapp.com/attachments/867970381426880562/1172026888114425876/Num1-10.png?ex=655ed219&is=654c5d19&hm=ecadfc4c72cc3885a401a8deb1995592212552251bfc790030770880afed6e4f&"
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!closed")) {
    if (admin) {
      showCmd = false;
      imgURL = "https://cdn.discordapp.com/attachments/867970381426880562/1172026910889496656/closeRound.png?ex=655ed21e&is=654c5d1e&hm=4b687272af989d6aa49d0691a302489106540bfd0eab245454dc59d449d1cd6e&"
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!soon")) {
    if (admin) {
      showCmd = false;
      imgURL = "https://cdn.discordapp.com/attachments/867970381426880562/1172026945798680667/ClosingSoon.png?ex=655ed227&is=654c5d27&hm=e7b60c4f0f58ef70932707adb202466808ed0f917be11311de75ef3327a2599d&"
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!highest")) {
    if (admin) {
      showCmd = false;
      imgURL = "https://cdn.discordapp.com/attachments/867970381426880562/1172026976513572944/HighestRoll.png?ex=655ed22e&is=654c5d2e&hm=0bb7f6d891443749af8af8d74c4bd07c3e16257f79bed1292ca90c242d4998d7&"
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!complete")) {
    if (admin) {
      showCmd = false;
      imgURL = "https://cdn.discordapp.com/attachments/867970381426880562/1172027014392320090/ChatGiveawayEnd.png?ex=655ed237&is=654c5d37&hm=9237f295208c39bd7675e2cfdf4dcea436b7ca6897b694b4fed36254fad6f837&"
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!energy")) {
    if (admin) {
      showCmd = false;
      imgURL = "https://cdn.discordapp.com/attachments/867970381426880562/1172035454367977542/GuessEnergy.png?ex=655eda13&is=654c6513&hm=2327f7a90450b0b09c5edd157be986ddc288bd68688dcceb01c91f1b98f3bed3&"
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!ttt")) {
    response = `Just a mockup:
    
    |X|O|X|
    |X|O|O|
    |O|X|X|
    `
  }
  else if (command.startsWith("!claim")) {
    response = "To claim your winnings email CollectingCommunity@gmail.com within 72 hours of winning with your name and address (and guardians permission if you are a minor), failure to do results in a forfeiture of the prize.";
  }
  else if (command.startsWith("!battle")) {
    if (channel !== "battle") {
      alert("You can only use this command in the Battles channel");
      showCmd = false;
      showChopi = false;
    } else {
      let [command, ...rest] = formValue.split(" ");
      if (rest.length < 1) {
        response = "@" + dispName + ", incorrect format for battle command";
      }
      else {
        let challenged = rest.join(" ");
        let res = await handleBattle(dispName, challenged.trim())
        if (res === 0) {
          response = "@" + dispName + ", there is nobody with that display name. Please try again.";
        }
        else if (res === 1) {
          response = "@" + dispName + ", there is already an active battle, please wait til the battle completes.";
        }
        else if (res === 3) {
          response = "@" + dispName + " challenges " + challenged + " to a battle. Chopi accepts the battle challenge. Type '!choose water', '!choose grass', or '!choose fire' to choose what type to battle with. Chopi has chosen!";

        }
        else {
          response = "@" + dispName + " challenges " + challenged + " to a battle";
        }

      }
    }
  }
  else if (command.startsWith("!accept")) {
    if (channel !== "battle") {
      alert("You can only use this command in the Battles channel");
      showCmd = false;
      showChopi = false;
    } else {
      let res = await handleAccept(auth.currentUser.uid);
      if (res === 0) {
        response = "@" + dispName + ", You were not challenged to a battle.";
      }
      else {
        response = "@" + dispName + " accepts the battle challenge. Type '!choose water', '!choose grass', or '!choose fire' to choose what type to battle with.";
      }
    }
  }
  else if (command.startsWith("!cancel")) {
    if (channel !== "battle") {
      alert("You can only use this command in the Battles channel");
      showCmd = false;
      showChopi = false;
    } else {
      let res = await handleReject(auth.currentUser.uid, admin);
      if (res === 0) {
        response = "@" + dispName + ", You do not have permission to cancel the battle.";
      }
      else {
        response = "@" + dispName + " cancels the battle challenge";
      }
    }
  }
  else if (command.startsWith("!choose")) {
    if (channel !== "battle") {
      alert("You can only use this command in the Battles channel");
      showCmd = false;
      showChopi = false;
    } else {
      let [command, ...rest] = formValue.split(" ");
      showCmd = false;
      if (rest.length < 1) {
        response = "@" + dispName + ", incorrect format for choose command";
      }
      else {
        let choice = rest.join(" ");
        let result = await handleChoice(dispName, auth.currentUser.uid, choice.trim());
        console.log(result);
        console.log(result.response);
        response = result.response;
        console.log(result.imgURL);
        imgURL = result.imgURL;
      }
    }
  }
  else if (command.startsWith("!trivia")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      response = await handleTrivia(dispName);
    }
  }
  else if (command.startsWith("!pick")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      let [command, ...rest] = formValue.split(" ");
      showCmd = false;
      if (rest.length < 1) {
        response = "@" + dispName + ", incorrect format for pick command. Use !pick 1 to choose the first option, etc.";
      }
      else {
        let choice = rest.join(" ");
        response = await handlePick(dispName, choice.trim());
      }
    }
  }
  else if (command.startsWith("!pokemoji")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      response = await handlePokemoji(dispName);
    }
  }
  else if (command.startsWith("!guess")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      let [command, ...rest] = formValue.split(" ");
      if (rest.length < 1) {
        response = "@" + dispName + ", incorrect format for guess command. Use !guess <pokemon_name> without the <> to guess a Pokemon";
      }
      else {
        let choice = rest.join(" ");
        response = await handleGuess(dispName, choice.trim());
      }
    }
  }
  else if (command.startsWith("!startholiday")) {
    if (admin) {
      if (channel !== "games") {
        alert("You can only use this command in the Games channel");
        showCmd = false;
        showChopi = false;
      } else {
        showCmd = false;
        const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `Active`=true,`Time`='00:00:00'");
        const gamesRef = firestore.collection('gamechat');
        await spawn(gamesRef, uid);
        response = "Holiday game has started!!";
      }
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!stopholiday")) {
    if (admin) {
      if (channel !== "games") {
        alert("You can only use this command in the Games channel");
        showCmd = false;
        showChopi = false;
      } else {
        showCmd = false;
        const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `Active`=false");
        response = "Holiday game has stopped!!";
      }
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!shake")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      showCmd = false;
      const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
      const holidayInfo = JSON.parse(holidayActive);
      if (holidayInfo['Active'] === '0') {
        response = "@" + dispName + ", The holiday game is not active.";
      }
      else {
        const shaken = await sendQuery("/battle.php", "SELECT * FROM `holidayshaken` WHERE UID='" + auth.currentUser.uid + "'");
        if (shaken === "0") {
          const update = await sendQuery("/update_battles.php", "INSERT INTO `holidayshaken`(`UID`) VALUES ('" + auth.currentUser.uid + "')");
          const shakeR = Math.floor(Math.random() * 30);
          if (shakeR > 26) {
            const shakeR2 = Math.floor(Math.random() * 1);
            if (shakeR2 === 0) {
              const completePuzzle = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE puzzle=12");

              if (holidayInfo['puzzle_enabled'] === "1" && completePuzzle === "0") {
                response = "Congratulations @" + dispName + "! You got a puzzle piece!";
                const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
                const holidayInfo = JSON.parse(holidayActive);

                const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
                if (leader === "0") {
                  imgURL = holidayInfo['piece1'];
                  const update = await sendQuery("/update_battles.php", "INSERT INTO `holidayleader`(`gold`, `puzzle`, `dispName`, `uid`,`coal`) VALUES (0,1,'" + dispName + "','" + auth.currentUser.uid + "',0)");
                }
                else {
                  const leaderInfo = JSON.parse(leader);
                  const newPuzzle = parseInt(leaderInfo['puzzle']) + 1;
                  if (newPuzzle > 12) {
                    newPuzzle = 12;
                  }
                  else {
                    const update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `puzzle`='" + newPuzzle + "' WHERE `uid`='" + auth.currentUser.uid + "'");
                  }
                  imgURL = holidayInfo['piece' + newPuzzle.toString()];

                }
              }
              else {
                response = "Congratulations @" + dispName + "! You got 2 diamonds! 💎💎";
                const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
                if (leader === "0") {
                  const update = await sendQuery("/update_battles.php", "INSERT INTO `holidayleader`(`gold`, `puzzle`, `dispName`, `uid`, `coal`) VALUES (2,0,'" + dispName + "','" + auth.currentUser.uid + "',0)");
                }
                else {
                  const leaderInfo = JSON.parse(leader);
                  const newGold = parseInt(leaderInfo['gold']) + 2;
                  const update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `gold`='" + newGold + "' WHERE `uid`='" + auth.currentUser.uid + "'");
                }
              }
            } else {
              response = "Sorry @" + dispName + ". You got coal. 🪨";
              const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
              if (leader === "0") {
                const update = await sendQuery("/update_battles.php", "INSERT INTO `holidayleader`(`gold`, `puzzle`, `dispName`, `uid`, `coal`) VALUES (0,0,'" + dispName + "','" + auth.currentUser.uid + "',1)");
              }
              else {
                const leaderInfo = JSON.parse(leader);
                const newCoal = parseInt(leaderInfo['coal']) + 1;
                const update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coal`='" + newCoal + "' WHERE `uid`='" + auth.currentUser.uid + "'");
              }
              rewardType = "shake";
              displayAd();
            }
          }
          else if (shakeR === 26) {
            response = "Congratulations @" + dispName + "! You got 3 diamonds! 💎💎💎";
            const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
            if (leader === "0") {
              const update = await sendQuery("/update_battles.php", "INSERT INTO `holidayleader`(`gold`, `puzzle`, `dispName`, `uid`, `coal`) VALUES (3,0,'" + dispName + "','" + auth.currentUser.uid + "',0)");
            }
            else {
              const leaderInfo = JSON.parse(leader);
              const newGold = parseInt(leaderInfo['gold']) + 3;
              const update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `gold`='" + newGold + "' WHERE `uid`='" + auth.currentUser.uid + "'");
            }
          }
          else if (shakeR > 23) {
            response = "Congratulations @" + dispName + "! You got 2 diamonds! 💎💎";
            const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
            if (leader === "0") {
              const update = await sendQuery("/update_battles.php", "INSERT INTO `holidayleader`(`gold`, `puzzle`, `dispName`, `uid`, `coal`) VALUES (2,0,'" + dispName + "','" + auth.currentUser.uid + "',0)");
            }
            else {
              const leaderInfo = JSON.parse(leader);
              const newGold = parseInt(leaderInfo['gold']) + 2;
              const update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `gold`='" + newGold + "' WHERE `uid`='" + auth.currentUser.uid + "'");
            }
          }
          else if (shakeR > 11) {
            response = "Sorry @" + dispName + ". You got coal. 🪨";
            const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
            if (leader === "0") {
              const update = await sendQuery("/update_battles.php", "INSERT INTO `holidayleader`(`gold`, `puzzle`, `dispName`, `uid`, `coal`) VALUES (0,0,'" + dispName + "','" + auth.currentUser.uid + "',1)");
            }
            else {
              const leaderInfo = JSON.parse(leader);
              const newCoal = parseInt(leaderInfo['coal']) + 1;
              const update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coal`='" + newCoal + "' WHERE `uid`='" + auth.currentUser.uid + "'");
            }
            rewardType = "shake";
            displayAd();
          }
          else {
            response = "Congratulations @" + dispName + "! You got 1 diamond! 💎";
            const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
            if (leader === "0") {
              const update = await sendQuery("/update_battles.php", "INSERT INTO `holidayleader`(`gold`, `puzzle`, `dispName`, `uid`, `coal`) VALUES (1,0,'" + dispName + "','" + auth.currentUser.uid + "',0)");
            }
            else {
              const leaderInfo = JSON.parse(leader);
              const newGold = parseInt(leaderInfo['gold']) + 1;
              const update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `gold`='" + newGold + "' WHERE `uid`='" + auth.currentUser.uid + "'");
            }
          }

        }
        else {
          response = "@" + dispName + ", You've already shaken. Please wait til the next spawn.";
        }
      }
    }
  }
  else if (command.startsWith("!holidaylbpuzzle")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      const leaderboard = await sendQuery("/trivia.php", "SELECT * FROM `holidayleader`");
      let lbInfo = JSON.parse(leaderboard);
      if (lbInfo.length !== 0) {
        lbInfo.sort((a, b) => b['puzzle'] - a['puzzle']);
        response = "Top 5 Players (Puzzle)  \n|Place|Name|Puzzle Pieces|  \n|-----|-----|-----|";
        for (let i = 0; i < lbInfo.length && i < 5; i++) {
          let place = i + 1;
          response = response + "  \n|" + place + "|" + lbInfo[i]['dispName'] + "|" + lbInfo[i]['puzzle'] + "|";
        }
      }
    }
  }
  else if (command.startsWith("!holidaycoal")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      const leaderboard = await sendQuery("/trivia.php", "SELECT * FROM `holidayleader`");
      let lbInfo = JSON.parse(leaderboard);
      if (lbInfo.length !== 0) {
        lbInfo.sort((a, b) => b['coal'] - a['coal']);
        response = "Top 5 Players (Most Coal)  \n|Place|Name|Coal|  \n|-----|-----|-----|";
        for (let i = 0; i < lbInfo.length && i < 5; i++) {
          let place = i + 1;
          response = response + "  \n|" + place + "|" + lbInfo[i]['dispName'] + "|" + lbInfo[i]['coal'] + "|";
        }
      }
    }
  }
  else if (command.startsWith("!holidaylbfull")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      const leaderboard = await sendQuery("/trivia.php", "SELECT * FROM `holidayleader`");
      let lbInfo = JSON.parse(leaderboard);
      if (lbInfo.length !== 0) {
        lbInfo.sort((a, b) => b['gold'] - a['gold']);
        response = "Full Leaderboard  \n|Place|Name|Diamonds|  \n|-----|-----|-----|";
        for (let i = 0; i < lbInfo.length; i++) {
          if (lbInfo[i]['gold'] !== '0') {
            let place = i + 1;
            response = response + "  \n|" + place + "|" + lbInfo[i]['dispName'] + "|" + lbInfo[i]['gold'] + "|";
          }
        }
      }
    }

  }
  else if (command.startsWith("!holidaylb")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      const leaderboard = await sendQuery("/trivia.php", "SELECT * FROM `holidayleader`");
      let lbInfo = JSON.parse(leaderboard);
      if (lbInfo.length !== 0) {
        lbInfo.sort((a, b) => b['gold'] - a['gold']);
        response = "Top 5 players  \n|Place|Name|Diamonds|  \n|-----|-----|-----|";
        for (let i = 0; i < lbInfo.length && i < 5; i++) {
          let place = i + 1;
          response = response + "  \n|" + place + "|" + lbInfo[i]['dispName'] + "|" + lbInfo[i]['gold'] + "|";
        }
      }
    }

  }
  else if (command.startsWith("!myholiday")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
      if (leader === "0") {
        response = "@" + dispName + ", You haven't played the holiday game yet";
      }
      else {
        const leaderInfo = JSON.parse(leader);
        const puzzleAmt = parseInt(leaderInfo['puzzle']);
        const goldAmt = parseInt(leaderInfo['gold']);
        const coalAmt = parseInt(leaderInfo['coal']);
        const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
        const holidayInfo = JSON.parse(holidayActive);
        if (puzzleAmt > 0) {
          console.log('piece' + puzzleAmt.toString());
          imgURL = holidayInfo['piece' + puzzleAmt.toString()];
        }
        response = "@" + dispName + ", You have " + goldAmt + " diamond(s), " + puzzleAmt + " puzzle pieces, and " + coalAmt + " coal!";
      }
    }
  }
  else if (command.startsWith("!holidayhelp")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      response = "@" + dispName + ", here are the holiday commands:  \n!shake - shakes the spawn and has a random chance of giving you diamonds, coal or a puzzle piece. Can only be done once per spawn.  \n!holidaylb - shows the top 5 leaderboard in diamonds.  \n!holidaylbpuzzle - shows the top 5 leaderboard in puzzle pieces.  \n!holidaylbfull - shows the full leaderboard in diamonds.  \n!holidaycoal - shows the top 5 most coal collected.  \n!myholiday - shows how many diamonds and puzzle pieces you have."
    }
  }
  else if (command.startsWith("!bulkforbucks")) {
    const shaken = await sendQuery("/battle.php", "SELECT * FROM `newsletter` WHERE UID='" + auth.currentUser.uid + "'");
    showCmd = false;
    if (shaken === "0") {
      const update = await sendQuery("/update_battles.php", "INSERT INTO `newsletter`(`UID`) VALUES ('" + auth.currentUser.uid + "')");
      response = "Congratulations @" + dispName + "! You got 12 diamonds! 💎💎💎💎💎💎💎💎💎💎💎💎";
      const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
      if (leader === "0") {
        const update = await sendQuery("/update_battles.php", "INSERT INTO `holidayleader`(`gold`, `puzzle`, `dispName`, `uid`, `coal`) VALUES (12,0,'" + dispName + "','" + auth.currentUser.uid + "',0)");
      }
      else {
        const leaderInfo = JSON.parse(leader);
        const newGold = parseInt(leaderInfo['gold']) + 12;
        const update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `gold`='" + newGold + "' WHERE `uid`='" + auth.currentUser.uid + "'");
      }
    }
    else {
      response = "@" + dispName + ", You can't use that command again.";
    }
  }
  else if (command.startsWith("!repeattrivia")) {
    const active = await sendQuery("/battle.php", "SELECT * FROM `activetrivia`");
    console.log(active);
    if (active === "0") {
      response = "@" + dispName + ", There is no active trivia question.";
    }
    else {
      const trivia = await sendQuery("/trivia.php", "SELECT * FROM `activetrivia`");
      const triviaInfo = JSON.parse(trivia);
      console.log(triviaInfo);
      const randomVal = Math.floor(Math.random() * triviaInfo.length);
      const question = triviaInfo[randomVal];
      const q = question['Question'];
      const a1 = question['A1'];
      const a2 = question['A2'];
      const a3 = question['A3'];
      const a4 = question['A4'];
      response = q + "  \n1. 1: " + a1 + "  \n2. 2: " + a2 + "  \n3. 3: " + a3 + "  \n4. 4: " + a4 + "  \nPick which answer you think is correct with !pick and then the corresponding number (ex: !pick 1). Anyone can answer the question."
    }
  }
  else if (command.startsWith("!repeatpokemoji")) {
    const active = await sendQuery("/battle.php", "SELECT * FROM `activepokemoji`");
    console.log(active);
    if (active === "0") {
      response = "@" + dispName + ", There is no active Pokemoji question.";
    }
    else {
      const trivia = await sendQuery("/trivia.php", "SELECT * FROM `activepokemoji`");
      const triviaInfo = JSON.parse(trivia);
      console.log(triviaInfo);
      const randomVal = Math.floor(Math.random() * triviaInfo.length);
      const question = triviaInfo[randomVal];
      const q = question['Emoji'];
      response = q + "  \nGuess the pokemon with !guess and then the name of pokemon you want to guess (Spelling counts). Anyone can answer the question."
    }
  }
  else if (command.startsWith("!startgiveaway")) {
    showCmd = false;
    if (admin) {
      const update = await sendQuery("/update_battles.php", "UPDATE `giveawaystatus` SET `open`=" + true);
      response = "A giveaway has started. Type !raffle to enter!"
      imgURL = "https://cdn.discordapp.com/attachments/867970381426880562/1172026858641035386/InProgress.png?ex=655ed212&is=654c5d12&hm=fc04f03d205d571198c2158b3882f8d99de67929ec0a0412ebe93f4ccd1e71fc&"
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!stopgiveaway")) {
    showCmd = false;
    if (admin) {
      const update = await sendQuery("/update_battles.php", "UPDATE `giveawaystatus` SET `open`=" + false);
      showCmd = false;
      response = "The giveaway has been closed."
      imgURL = imgURL = "https://cdn.discordapp.com/attachments/867970381426880562/1172026910889496656/closeRound.png?ex=655ed21e&is=654c5d1e&hm=4b687272af989d6aa49d0691a302489106540bfd0eab245454dc59d449d1cd6e&"
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!drawwinner")) {
    showCmd = false;
    if (admin) {
      const trivia = await sendQuery("/trivia.php", "SELECT * FROM `entries`");
      const triviaInfo = JSON.parse(trivia);
      console.log(triviaInfo);
      const randomVal = Math.floor(Math.random() * triviaInfo.length);
      const winner = triviaInfo[randomVal];
      const winnerName = winner['dispName'];
      const winnerUID = winner['UID'];
      const update = await sendQuery("/update_battles.php", "DELETE FROM `entries` WHERE UID='" + winnerUID + "'");
      response = "Congratulations @" + winnerName + "! You won the giveaway!";
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!cleargiveaway")) {
    showCmd = false;
    if (admin) {
      const update = await sendQuery("/update_battles.php", "DELETE FROM `entries`");
      response = "Giveaway entries cleared";
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!raffle")) {
    const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `giveawaystatus`");
    const holidayInfo = JSON.parse(holidayActive);
    if (holidayInfo['open'] === '0') {
      response = "@" + dispName + ", There is no active giveaway";
    }
    else {
      const shaken = await sendQuery("/battle.php", "SELECT * FROM `entries` WHERE UID='" + auth.currentUser.uid + "'");
      if (shaken === "0") {
        const update = await sendQuery("/update_battles.php", "INSERT INTO `entries`(`dispName`,`UID`) VALUES ('" + dispName + "','" + auth.currentUser.uid + "')");
        response = "@" + dispName + ", you have entered the giveaway!";
      } else {
        response = "@" + dispName + ", you are already in the giveaway!";
      }
    }
  }
  else if (command.startsWith("!lvl")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
      if (leader === "0") {
        response = "@" + dispName + ", You haven't played the mining game yet";
      }
      else {
        const leaderInfo = JSON.parse(leader);
        const lvl = parseInt(leaderInfo['lvl']);
        const xp = parseInt(leaderInfo['xp']);
        var next_lvl = lvl + 1;
        const lvls = await sendQuery("/battle.php", "SELECT * FROM `levels` WHERE level=" + next_lvl + "");
        const lvlInfo = JSON.parse(lvls);
        const reqxp = parseInt(lvlInfo['xp']);
        const rounded = Math.round(100 * xp / reqxp);
        response = dispName + "  \nYOUR LEVEL: " + lvl + "  \nXP: ![50%](https://progress-bar.dev/" + rounded + "?title=" + xp + "/" + reqxp + ")";
      }
    }
  }
  else if (command.startsWith("!upgradereq")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
      if (leader === "0") {
        response = "@" + dispName + ", You haven't played the mining game yet";
      }
      else {
        const leaderInfo = JSON.parse(leader);
        const lvl = parseInt(leaderInfo['lvl']);
        const pickaxe = parseInt(leaderInfo['mining']);
        var nxt_pickaxe = pickaxe + 1;
        const lvls = await sendQuery("/battle.php", "SELECT * FROM `upgradereq` WHERE pickaxe=" + nxt_pickaxe + "");
        const lvlInfo = JSON.parse(lvls);
        const reqlvl = parseInt(lvlInfo['level']);
        let rounded = Math.round(100 * lvl / reqlvl);
        if (rounded >= 100) {
          rounded = 100;
        }
        response = dispName + "'s requirements for next pickaxe upgrade  \nLevel Requirement: ![50%](https://progress-bar.dev/" + rounded + "?title=" + lvl + "/" + reqlvl + ")";
        let req = parseInt(lvlInfo['coal']);
        let amt = parseInt(leaderInfo['coal']);
        if (req > 0) {
          rounded = Math.round(100 * amt / req);
          if (rounded >= 100) {
            rounded = 100;
          }
          response = response + "  \nCoal: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
        }

        req = parseInt(lvlInfo['tin']);
        amt = parseInt(leaderInfo['tin']);
        if (req > 0) {
          rounded = Math.round(100 * amt / req);
          if (rounded >= 100) {
            rounded = 100;
          }
          response = response + "  \nTin: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
        }

        req = parseInt(lvlInfo['aluminum']);
        amt = parseInt(leaderInfo['aluminum']);
        if (req > 0) {
          rounded = Math.round(100 * amt / req);
          if (rounded >= 100) {
            rounded = 100;
          }
          response = response + "  \nAluminum: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
        }

        req = parseInt(lvlInfo['copper']);
        amt = parseInt(leaderInfo['copper']);
        if (req > 0) {
          rounded = Math.round(100 * amt / req);
          if (rounded >= 100) {
            rounded = 100;
          }
          response = response + "  \nCopper: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
        }

        req = parseInt(lvlInfo['iron']);
        amt = parseInt(leaderInfo['iron']);
        if (req > 0) {
          rounded = Math.round(100 * amt / req);
          if (rounded >= 100) {
            rounded = 100;
          }
          response = response + "  \nIron: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
        }
        req = parseInt(lvlInfo['silver']);
        amt = parseInt(leaderInfo['silver']);
        if (req > 0) {
          rounded = Math.round(100 * amt / req);
          if (rounded >= 100) {
            rounded = 100;
          }
          response = response + "  \nSilver: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
        }

        req = parseInt(lvlInfo['gold']);
        amt = parseInt(leaderInfo['gold_mine']);
        if (req > 0) {
          rounded = Math.round(100 * amt / req);
          if (rounded >= 100) {
            rounded = 100;
          }
          response = response + "  \nGold: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
        }

        req = parseInt(lvlInfo['titanium']);
        amt = parseInt(leaderInfo['titanium']);
        if (req > 0) {
          rounded = Math.round(100 * amt / req);
          if (rounded >= 100) {
            rounded = 100;
          }
          response = response + "  \nTitanium: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
        }

        req = parseInt(lvlInfo['cobalt']);
        amt = parseInt(leaderInfo['cobalt']);
        if (req > 0) {
          rounded = Math.round(100 * amt / req);
          if (rounded >= 100) {
            rounded = 100;
          }
          response = response + "  \nCobalt: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
        }

        req = parseInt(lvlInfo['platinum']);
        amt = parseInt(leaderInfo['platinum']);
        if (req > 0) {
          rounded = Math.round(100 * amt / req);
          if (rounded >= 100) {
            rounded = 100;
          }
          response = response + "  \nPlatinum: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
        }

        req = parseInt(lvlInfo['diamond']);
        amt = parseInt(leaderInfo['diamond']);
        if (req > 0) {
          rounded = Math.round(100 * amt / req);
          if (rounded >= 100) {
            rounded = 100;
          }
          response = response + "  \nDiamond: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
        }
        let cur_pickaxe = await computeBadge(pickaxe);
        let next_pickaxe = await computeBadge(nxt_pickaxe);

        response = response + "  \n![pickaxe](" + cur_pickaxe + ") &rarr; ![pickaxe](" + next_pickaxe + ")";

      }
    }
  }
  else if (command.startsWith("!startmining")) {
    if (admin) {
      if (channel !== "games") {
        alert("You can only use this command in the Games channel");
        showCmd = false;
        showChopi = false;
      } else {
        showCmd = false;
        const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningActive`=true,`MiningTime`='00:00:00'");
        const gamesRef = firestore.collection('gamechat');
        await spawn(gamesRef, uid);
        response = "Mining game has started!!";
      }
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!stopmining")) {
    if (admin) {
      if (channel !== "games") {
        alert("You can only use this command in the Games channel");
        showCmd = false;
        showChopi = false;
      } else {
        showCmd = false;
        const update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `MiningActive`=false");
        response = "Mining game has stopped!!";
      }
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!mine")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      showCmd = false;
      const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
      const holidayInfo = JSON.parse(holidayActive);
      if (holidayInfo['MiningActive'] === '0') {
        response = "@" + dispName + ", The mining game is not active.";
      }
      else if (holidayInfo['MiningType'] === '11') {
        response = "@" + dispName + ", You can't mine a furnace.";
      }
      else {
        const shaken = await sendQuery("/battle.php", "SELECT * FROM `mined` WHERE UID='" + auth.currentUser.uid + "'");
        if (shaken === "0") {
          let update = await sendQuery("/update_battles.php", "INSERT INTO `mined`(`UID`) VALUES ('" + auth.currentUser.uid + "')");
          const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
          let lvl = 0;
          let xp = 0;
          let pickaxe = 0;
          let coins = 0;
          let xp_boost = 0;
          let item_boost = 0;
          if (leader !== "0") {
            const leaderInfo = JSON.parse(leader);
            lvl = parseInt(leaderInfo['lvl']);
            xp = parseInt(leaderInfo['xp']);
            pickaxe = parseInt(leaderInfo['mining']);
            coins = parseInt(leaderInfo['coins']);
            xp_boost = parseInt(leaderInfo['xp_boost']);
            item_boost = parseInt(leaderInfo['item_boost']);
          }
          else {
            let update = await sendQuery("/update_battles.php", "INSERT INTO `holidayleader`(`gold`, `puzzle`, `dispName`, `uid`,`coal`) VALUES (0,1,'" + dispName + "','" + auth.currentUser.uid + "',0)");
          }
          let miningType = parseInt(holidayInfo['MiningType']);
          if (miningType * 2 - 1 > pickaxe) {
            response = "@" + dispName + ", Your pickaxe isn't upgraded enough to mine this ore. When a furnace spawns, use !upgrade to upgrade your pickaxe and !upgradereq to see what materials are required to upgrade your pickaxe.";
          }
          else {
            var next_lvl = lvl + 1;
            const lvls = await sendQuery("/battle.php", "SELECT * FROM `levels` WHERE level=" + next_lvl + "");
            const lvlInfo = JSON.parse(lvls);
            const reqxp = parseInt(lvlInfo['xp']);
            let chance = Math.floor(Math.random() * 10);
            if (chance < 7) {
              let xp_gain = Math.floor(Math.random() * (3 + miningType * 3 + pickaxe * 2)) + 4 + 2 * miningType;
              if (xp_boost > 0) {
                xp_boost = xp_boost - 1;
                xp_gain = xp_gain * 2;
              }
              response = "Congratulations @" + dispName + "! You have earned " + xp_gain + " xp.";
              xp = xp + xp_gain;
              if (xp >= reqxp) {
                lvl = lvl + 1;
                xp = xp - reqxp;
                response = response + " You have leveled up to level " + lvl + "!";
              }
              let chance2 = Math.floor(Math.random() * 10);
              if (chance2 === 0) {
                response = response + " You have earned 1 CC Coin.";
                coins = coins + 1;
              }
              switch (miningType) {
                case 0:
                  if (item_boost > 0) {
                    item_boost = item_boost - 1;
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`item_boost`='" + item_boost + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`coal`=coal+2 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 2 coal!";
                  }
                  else {
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`coal`=coal+1 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 1 coal!";
                  }
                  break;
                case 1:
                  if (item_boost > 0) {
                    item_boost = item_boost - 1;
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`item_boost`='" + item_boost + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`tin`=tin+2 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 2 tin!";
                  }
                  else {
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`tin`=tin+1 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 1 tin!";
                  }
                  break;
                case 2:
                  if (item_boost > 0) {
                    item_boost = item_boost - 1;
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`item_boost`='" + item_boost + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`aluminum`=aluminum+2 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 2 aluminum!";
                  }
                  else {
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`aluminum`=aluminum+1 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 1 aluminum!";
                  }
                  break;
                case 3:
                  if (item_boost > 0) {
                    item_boost = item_boost - 1;
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`item_boost`='" + item_boost + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`copper`=copper+2 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 2 copper!";
                  }
                  else {
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`copper`=copper+1 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 1 copper!";
                  }
                  break;
                case 4:
                  if (item_boost > 0) {
                    item_boost = item_boost - 1;
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`item_boost`='" + item_boost + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`iron`=iron+2 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 2 iron!";
                  }
                  else {
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`iron`=iron+1 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 1 iron!";
                  }
                  break;
                case 5:
                  if (item_boost > 0) {
                    item_boost = item_boost - 1;
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`item_boost`='" + item_boost + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`silver`=silver+2 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 2 silver!";
                  }
                  else {
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`silver`=silver+1 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 1 silver!";
                  }
                  break;
                case 6:
                  if (item_boost > 0) {
                    item_boost = item_boost - 1;
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`item_boost`='" + item_boost + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`gold_mine`=gold_mine+2 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 2 gold!";
                  }
                  else {
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`gold_mine`=gold_mine+1 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 1 gold!";
                  }
                  break;
                case 7:
                  if (item_boost > 0) {
                    item_boost = item_boost - 1;
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`item_boost`='" + item_boost + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`titanium`=titanium+2 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 2 titanium!";
                  }
                  else {
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`titanium`=titanium+1 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 1 titanium!";
                  }
                  break;
                case 8:
                  if (item_boost > 0) {
                    item_boost = item_boost - 1;
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`item_boost`='" + item_boost + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`cobalt`=cobalt+2 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 2 cobalt!";
                  }
                  else {
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`cobalt`=cobalt+1 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 1 cobalt!";
                  }
                  break;
                case 9:
                  if (item_boost > 0) {
                    item_boost = item_boost - 1;
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`item_boost`='" + item_boost + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`platinum`=platinum+2 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 2 platinum!";
                  }
                  else {
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`platinum`=platinum+1 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 1 platinum!";
                  }
                  break;
                case 10:
                  if (item_boost > 0) {
                    item_boost = item_boost - 1;
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`item_boost`='" + item_boost + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`diamond`=diamond+2 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 2 diamond!";
                  }
                  else {
                    update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`xp_boost`='" + xp_boost + "',`xp`='" + xp + "',`lvl`='" + lvl + "',`diamond`=diamond+1 WHERE `uid`='" + auth.currentUser.uid + "'");
                    response = response + " You have mined 1 diamond!";
                  }
                  break;
              }
            }
            else {
              let chance2 = Math.floor(Math.random() * 10);
              if (chance2 < 4) {
                let xp_gain = Math.floor(Math.random() * (3 + miningType * 3)) + 1;
                if (xp_boost > 0) {
                  xp_boost = xp_boost - 1;
                  xp_gain = xp_gain * 2;
                }
                response = "Congratulations @" + dispName + "! You have earned " + xp_gain + " xp.";
                xp = xp + xp_gain;
                if (xp >= reqxp) {
                  lvl = lvl + 1;
                  xp = xp - reqxp;
                  response = response + " You have leveled up to level " + lvl + "!";
                }
                const update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `xp`='" + xp + "',`xp_boost`='" + xp_boost + "',`lvl`='" + lvl + "' WHERE `uid`='" + auth.currentUser.uid + "'");
                response = response + " You did not gain any materials.";
              }
              else {
                response = "Sorry @" + dispName + ", you did not gain any xp or materials."
                rewardType = "mine";
                displayAd();
              }
            }
          }
        }
        else {
          response = "@" + dispName + ", You've already mined. Please wait til the next ore.";
        }
      }
    }
  }
  else if (command.startsWith("!bal")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    }
    else {
      const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
      if (leader === "0") {
        response = "@" + dispName + ", You haven't played the mining game yet";
      }
      else {
        const leaderInfo = JSON.parse(leader);
        const coins = parseInt(leaderInfo['coins']);
        response = "@" + dispName + ", You have " + coins + " CC Coins in the bank.";
      }
    }
  }
  else if (command.startsWith("!shop")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      response = "Mining Shop  \nUse !buy [ID] in order to buy the item with that ID (based on the IDs listed below)  \n|ID|Item|Description|Cost (CC Coins)|  \n|-----|-----|-----|-----|";
      response = response + "  \n|" + 1 + "|Instant Furnace|Spawns a furnace instantly|5|";
      response = response + "  \n|" + 2 + "|Micro XP Boost|Doubles xp gained next time you mine|3|";
      response = response + "  \n|" + 3 + "|Small XP Boost|Doubles xp gained next 5 times you mine|12|";
      response = response + "  \n|" + 4 + "|Large XP Boost|Doubles xp gained next 10 times you mine|20|";
      response = response + "  \n|" + 5 + "|Micro Item Boost|Doubles items gained next time you mine|3|";
      response = response + "  \n|" + 6 + "|Small Item Boost|Doubles items gained next 5 times you mine|12|";
      response = response + "  \n|" + 7 + "|Large Item Boost|Doubles items gained next 10 times you mine|20|";
      response = response + "  \n|" + 8 + "|Ore Rush|Spawns ores every minute for 30 minutes|50|";
    }
  }
  else if (command.startsWith("!upgrade")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      showCmd = false;
      const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
      const holidayInfo = JSON.parse(holidayActive);
      if (holidayInfo['MiningActive'] === '0') {
        response = "@" + dispName + ", The mining game is not active.";
      }
      else if (holidayInfo['MiningType'] !== '11') {
        response = "@" + dispName + ", You can't upgrade without a furnace.";
      }
      else {
        const shaken = await sendQuery("/battle.php", "SELECT * FROM `mined` WHERE UID='" + auth.currentUser.uid + "'");
        if (shaken === "0") {
          const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
          if (leader === "0") {
            response = "@" + dispName + ", You haven't played the mining game yet";
          }
          else {
            response = dispName + " you didn't meet the requirements to upgrade your pickaxe.";
            let upgradeable = true;
            const leaderInfo = JSON.parse(leader);
            const lvl = parseInt(leaderInfo['lvl']);
            const pickaxe = parseInt(leaderInfo['mining']);
            var nxt_pickaxe = pickaxe + 1;
            const lvls = await sendQuery("/battle.php", "SELECT * FROM `upgradereq` WHERE pickaxe=" + nxt_pickaxe + "");
            const lvlInfo = JSON.parse(lvls);
            const reqlvl = parseInt(lvlInfo['level']);
            let rounded = Math.round(100 * lvl / reqlvl);
            if (rounded >= 100) {
              rounded = 100;
            }

            if (lvl < reqlvl) {
              upgradeable = false;
              response = response + "  \nLevel Requirement: ![50%](https://progress-bar.dev/" + rounded + "?title=" + lvl + "/" + reqlvl + ")";
            }
            let req = parseInt(lvlInfo['coal']);
            let amt = parseInt(leaderInfo['coal']);
            if (req > 0) {
              rounded = Math.round(100 * amt / req);
              if (rounded >= 100) {
                rounded = 100;
              }
              if (amt < req) {
                upgradeable = false;
                response = response + "  \nCoal: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
              }
            }
            req = parseInt(lvlInfo['tin']);
            amt = parseInt(leaderInfo['tin']);
            if (req > 0) {
              rounded = Math.round(100 * amt / req);
              if (rounded >= 100) {
                rounded = 100;
              }
              if (amt < req) {
                upgradeable = false;
                response = response + "  \nTin: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
              }
            }

            req = parseInt(lvlInfo['aluminum']);
            amt = parseInt(leaderInfo['aluminum']);
            if (req > 0) {
              rounded = Math.round(100 * amt / req);
              if (rounded >= 100) {
                rounded = 100;
              }
              if (amt < req) {
                upgradeable = false;
                response = response + "  \nAluminum: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
              }
            }

            req = parseInt(lvlInfo['copper']);
            amt = parseInt(leaderInfo['copper']);
            if (req > 0) {
              rounded = Math.round(100 * amt / req);
              if (rounded >= 100) {
                rounded = 100;
              }
              if (amt < req) {
                upgradeable = false;
                response = response + "  \nCopper: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
              }
            }

            req = parseInt(lvlInfo['iron']);
            amt = parseInt(leaderInfo['iron']);
            if (req > 0) {
              rounded = Math.round(100 * amt / req);
              if (rounded >= 100) {
                rounded = 100;
              }
              if (amt < req) {
                upgradeable = false;
                response = response + "  \nIron: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
              }
            }
            req = parseInt(lvlInfo['silver']);
            amt = parseInt(leaderInfo['silver']);
            if (req > 0) {
              rounded = Math.round(100 * amt / req);
              if (rounded >= 100) {
                rounded = 100;
              }
              if (amt < req) {
                upgradeable = false;
                response = response + "  \nSilver: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
              }
            }

            req = parseInt(lvlInfo['gold']);
            amt = parseInt(leaderInfo['gold_mine']);
            if (req > 0) {
              rounded = Math.round(100 * amt / req);
              if (rounded >= 100) {
                rounded = 100;
              }
              if (amt < req) {
                upgradeable = false;
                response = response + "  \nGold: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
              }
            }

            req = parseInt(lvlInfo['titanium']);
            amt = parseInt(leaderInfo['titanium']);
            if (req > 0) {
              rounded = Math.round(100 * amt / req);
              if (rounded >= 100) {
                rounded = 100;
              }
              if (amt < req) {
                upgradeable = false;
                response = response + "  \nTitanium: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
              }
            }

            req = parseInt(lvlInfo['cobalt']);
            amt = parseInt(leaderInfo['cobalt']);
            if (req > 0) {
              rounded = Math.round(100 * amt / req);
              if (rounded >= 100) {
                rounded = 100;
              }
              if (amt < req) {
                upgradeable = false;
                response = response + "  \nCobalt: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
              }
            }

            req = parseInt(lvlInfo['platinum']);
            amt = parseInt(leaderInfo['platinum']);
            if (req > 0) {
              rounded = Math.round(100 * amt / req);
              if (rounded >= 100) {
                rounded = 100;
              }
              if (amt < req) {
                upgradeable = false;
                response = response + "  \nPlatinum: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
              }
            }

            req = parseInt(lvlInfo['diamond']);
            amt = parseInt(leaderInfo['diamond']);
            if (req > 0) {
              rounded = Math.round(100 * amt / req);
              if (rounded >= 100) {
                rounded = 100;
              }
              if (amt < req) {
                upgradeable = false;
                response = response + "  \nDiamond: ![50%](https://progress-bar.dev/" + rounded + "?title=" + amt + "/" + req + ")"
              }
            }
            if (upgradeable) {
              let update = await sendQuery("/update_battles.php", "INSERT INTO `mined`(`UID`) VALUES ('" + auth.currentUser.uid + "')");
              response = "Congratulations @" + dispName + "! You have upgraded your pickaxe!";
              let coalreq = parseInt(lvlInfo['coal']);
              let tinreq = parseInt(lvlInfo['tin']);
              let aluminumreq = parseInt(lvlInfo['aluminum']);
              let copperreq = parseInt(lvlInfo['copper']);
              let ironreq = parseInt(lvlInfo['iron']);
              let silverreq = parseInt(lvlInfo['silver']);
              let goldreq = parseInt(lvlInfo['gold']);
              let titaniumreq = parseInt(lvlInfo['titanium']);
              let cobaltreq = parseInt(lvlInfo['cobalt']);
              let platinumreq = parseInt(lvlInfo['platinum']);
              let diamondreq = parseInt(lvlInfo['diamond']);
              let coal = parseInt(leaderInfo['coal']) - coalreq;
              let tin = parseInt(leaderInfo['tin']) - tinreq;
              let aluminum = parseInt(leaderInfo['aluminum']) - aluminumreq;
              let copper = parseInt(leaderInfo['copper']) - copperreq;
              let iron = parseInt(leaderInfo['iron']) - ironreq;
              let silver = parseInt(leaderInfo['silver']) - silverreq;
              let gold = parseInt(leaderInfo['gold_mine']) - goldreq;
              let titanium = parseInt(leaderInfo['titanium']) - titaniumreq;
              let cobalt = parseInt(leaderInfo['cobalt']) - cobaltreq;
              let platinum = parseInt(leaderInfo['platinum']) - platinumreq;
              let diamond = parseInt(leaderInfo['diamond']) - diamondreq;
              console.log(coal, tin, aluminum, copper, iron, silver, gold, titanium, cobalt, platinum, diamond, nxt_pickaxe);
              update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coal`=" + coal + ",`tin`=" + tin +
                ",`aluminum`=" + aluminum + ",`copper`=" + copper + ",`iron`=" + iron + ",`silver`=" + silver +
                ",`gold_mine`=" + gold + ",`titanium`=" + titanium + ",`cobalt`=" + cobalt +
                ",`platinum`=" + platinum + ",`diamond`=" + diamond + ",`mining`=" + nxt_pickaxe + " WHERE `uid`='" + auth.currentUser.uid + "'");
              imgURL = await computeBadge(nxt_pickaxe);

            }
          }
        }
        else {
          response = "@" + dispName + ", You've already upgraded. Please wait til the next furnace.";
        }
      }
    }
  }
  else if (command.startsWith("!craft")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      showCmd = false;
      const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
      const holidayInfo = JSON.parse(holidayActive);
      if (holidayInfo['MiningActive'] === '0') {
        response = "@" + dispName + ", The mining game is not active.";
      }
      else if (holidayInfo['MiningType'] !== '11') {
        response = "@" + dispName + ", You can't craft without a furnace.";
      }
      else {
        const shaken = await sendQuery("/battle.php", "SELECT * FROM `mined` WHERE UID='" + auth.currentUser.uid + "'");
        if (shaken === "0") {
          const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
          if (leader === "0") {
            response = "@" + dispName + ", You haven't played the mining game yet";
          }
          else {
            const leaderInfo = JSON.parse(leader);
            const pickaxe = parseInt(leaderInfo['mining']);
            let [command, ...rest] = formValue.split(" ");
            let orename = rest.join(" ");
            orename = (orename.trim()).toLowerCase();
            let miningType = -1;
            if (orename === "tin") {
              miningType = 1;
            }
            else if (orename === "aluminum") {
              miningType = 2;
            }
            else if (orename === "copper") {
              miningType = 3;
            }
            else if (orename === "iron") {
              miningType = 4;
            }
            else if (orename === "silver") {
              miningType = 5;
            }
            else if (orename === "gold") {
              miningType = 6;
            }
            else if (orename === "titanium") {
              miningType = 7;
            }
            else if (orename === "cobalt") {
              miningType = 8;
            }
            else if (orename === "platinum") {
              miningType = 9;
            }
            else if (orename === "diamond") {
              miningType = 10;
            }
            else {
              miningType = -1;
            }
            if (miningType === -1) {
              response = "@" + dispName + ", You cannot craft " + orename + ". Please review the ore types in the command reference page.";
            }
            else if (miningType * 2 - 2 > pickaxe) {
              response = "@" + dispName + ", Your pickaxe isn't upgraded enough to craft this ore. When a furnace spawns, use !upgrade to upgrade your pickaxe and !upgradereq to see what materials are required to upgrade your pickaxe.";
            }
            else {
              let update = await sendQuery("/update_battles.php", "INSERT INTO `mined`(`UID`) VALUES ('" + auth.currentUser.uid + "')");
              let coal = parseInt(leaderInfo['coal']);
              let tin = parseInt(leaderInfo['tin']);
              let aluminum = parseInt(leaderInfo['aluminum']);
              let copper = parseInt(leaderInfo['copper']);
              let iron = parseInt(leaderInfo['iron']);
              let silver = parseInt(leaderInfo['silver']);
              let gold = parseInt(leaderInfo['gold_mine']);
              let titanium = parseInt(leaderInfo['titanium']);
              let cobalt = parseInt(leaderInfo['cobalt']);
              let platinum = parseInt(leaderInfo['platinum']);
              let diamond = parseInt(leaderInfo['diamond']);
              switch (miningType) {
                case 1:
                  if (coal < 8) {
                    response = "@" + dispName + ", You don't have enough coal to craft tin. You need 8 coal";
                  }
                  else {
                    coal = coal - 8;
                    tin = tin + 1;
                    response = "@" + dispName + ", You have crafted 1 tin with 8 coal!";
                  }
                  break;
                case 2:
                  if (tin < 2) {
                    response = "@" + dispName + ", You don't have enough tin to craft aluminum. You need 2 tin";
                  }
                  else {
                    aluminum = aluminum + 1;
                    tin = tin - 2;
                    response = "@" + dispName + ", You have crafted 1 aluminum with 2 tin!";
                  }
                  break;
                case 3:
                  if (aluminum < 2) {
                    response = "@" + dispName + ", You don't have enough aluminum to craft copper. You need 2 aluminum";
                  }
                  else {
                    aluminum = aluminum - 2;
                    copper = copper + 1;
                    response = "@" + dispName + ", You have crafted 1 copper with 2 aluminum!";
                  }
                  break;
                case 4:
                  if (copper < 2) {
                    response = "@" + dispName + ", You don't have enough copper to craft iron. You need 2 copper";
                  }
                  else {
                    iron = iron + 1;
                    copper = copper - 2;
                    response = "@" + dispName + ", You have crafted 1 iron with 2 copper!";
                  }
                  break;
                case 5:
                  if (iron < 2) {
                    response = "@" + dispName + ", You don't have enough iron to craft silver. You need 2 iron";
                  }
                  else {
                    iron = iron - 2;
                    silver = silver + 1;
                    response = "@" + dispName + ", You have crafted 1 silver with 2 iron!";
                  }
                  break;
                case 6:
                  if (silver < 2) {
                    response = "@" + dispName + ", You don't have enough silver to craft gold. You need 2 silver";
                  }
                  else {
                    gold = gold + 1;
                    silver = silver - 2;
                    response = "@" + dispName + ", You have crafted 1 gold with 2 silver!";
                  }
                  break;
                case 7:
                  if (gold < 2) {
                    response = "@" + dispName + ", You don't have enough gold to craft titanium. You need 2 gold";
                  }
                  else {
                    titanium = titanium + 1;
                    gold = gold - 2;
                    response = "@" + dispName + ", You have crafted 1 titanium with 2 gold!";
                  }
                  break;
                case 8:
                  if (titanium < 2) {
                    response = "@" + dispName + ", You don't have enough titanium to craft cobalt. You need 2 titanium";
                  }
                  else {
                    cobalt = cobalt + 1;
                    titanium = titanium - 2;
                    response = "@" + dispName + ", You have crafted 1 cobalt with 2 titanium!";
                  }
                  break;
                case 9:
                  if (cobalt < 2) {
                    response = "@" + dispName + ", You don't have enough cobalt to craft platinum. You need 2 cobalt";
                  }
                  else {
                    platinum = platinum + 1;
                    cobalt = cobalt - 2;
                    response = "@" + dispName + ", You have crafted 1 platinum with 2 cobalt!";
                  }
                  break;
                case 10:
                  if (platinum < 2) {
                    response = "@" + dispName + ", You don't have enough platinum to craft diamond. You need 2 platinum";
                  }
                  else {
                    diamond = diamond + 1;
                    platinum = platinum - 2;
                    response = "@" + dispName + ", You have crafted 1 diamond with 2 platinum!";
                  }
                  break;
                default:
                  alert("Some kind of weird error occurred with !craft. Please notify the site staff");


              }
              update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coal`=" + coal + ",`tin`=" + tin +
                ",`aluminum`=" + aluminum + ",`copper`=" + copper + ",`iron`=" + iron + ",`silver`=" + silver +
                ",`gold_mine`=" + gold + ",`titanium`=" + titanium + ",`cobalt`=" + cobalt +
                ",`platinum`=" + platinum + ",`diamond`=" + diamond + " WHERE `uid`='" + auth.currentUser.uid + "'");

            }
          }
        }
        else {
          response = "@" + dispName + ", You've already upgraded. Please wait til the next furnace.";
        }
      }
    }
  }
  else if (command.startsWith("!buypuzzle")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      showCmd = false;
      const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
      const holidayInfo = JSON.parse(holidayActive);
      if (holidayInfo['Active'] === '0') {
        response = "@" + dispName + ", The holiday game is not active.";
      }
      else {
        const completePuzzle = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE puzzle=12");
        if (holidayInfo['puzzle_enabled'] === "1" && completePuzzle === "0") {
          const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
          if (leader === "0") {
            response = "@" + dispName + ". You haven't played the game, so you don't have enough diamonds to buy a puzzle piece.";
          }
          else {
            const leaderInfo = JSON.parse(leader);
            let diamonds = parseInt(leaderInfo['gold']);
            let puzzleCost = parseInt(holidayInfo['puzzle_cost']);
            if (diamonds >= puzzleCost) {
              const newPuzzle = parseInt(leaderInfo['puzzle']) + 1;
              diamonds = diamonds - puzzleCost;
              const update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `puzzle`='" + newPuzzle + "',`gold`='" + diamonds + "' WHERE `uid`='" + auth.currentUser.uid + "'");

              imgURL = holidayInfo['piece' + newPuzzle.toString()];
              response = "Congratulations @" + dispName + "! You purchased a puzzle piece for " + puzzleCost + " diamonds!";
            }
            else {
              response = "@" + dispName + ". You don't have enough diamonds to buy a puzzle piece. It costs " + holidayInfo['puzzle_cost'] + " diamonds.";
            }
          }

        } else {
          response = "Puzzle pieces are not currently available for purchase.";
        }
      }
    }
  }
  else if (command.startsWith("!sellpuzzle")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      showCmd = false;
      const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
      const holidayInfo = JSON.parse(holidayActive);
      if (holidayInfo['Active'] === '0') {
        response = "@" + dispName + ", The holiday game is not active.";
      }
      else {
        const completePuzzle = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE puzzle=12");
        if (holidayInfo['puzzle_enabled'] === "1" && completePuzzle === "0") {
          const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
          if (leader === "0") {
            response = "@" + dispName + ". You haven't played the game, so you don't have puzzle pieces to sell.";
          }
          else {
            const leaderInfo = JSON.parse(leader);
            let diamonds = parseInt(leaderInfo['gold']);
            let puzzleCost = Math.floor(parseInt(holidayInfo['puzzle_cost']) / 2);
            const puzzle = parseInt(leaderInfo['puzzle']);
            if (puzzle > 0) {
              const newPuzzle = puzzle - 1;
              diamonds = diamonds + puzzleCost;
              const update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `puzzle`='" + newPuzzle + "',`gold`='" + diamonds + "' WHERE `uid`='" + auth.currentUser.uid + "'");
              response = "Congratulations @" + dispName + "! You sold a puzzle piece for " + puzzleCost + " diamonds!";
            }
            else {
              response = "@" + dispName + ". You don't have any puzzle pieces to convert to diamonds.";
            }
          }

        } else {
          response = "Currently, selling puzzle pieces is disabled.";
        }
      }
    }
  }
  else if (command.startsWith("!previewpuzzle")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    } else {
      showCmd = false;
      const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
      const holidayInfo = JSON.parse(holidayActive);
      if (holidayInfo['Active'] === '0') {
        response = "@" + dispName + ", The holiday game is not active.";
      }
      else {
        const completePuzzle = await sendQuery("/battle.php", "SELECT MAX(`puzzle`) as `max_puzzle` FROM `holidayleader`");
        const puzzleVal = JSON.parse(completePuzzle)['max_puzzle'];
        if (puzzleVal !== "0") {
          imgURL = holidayInfo['piece' + puzzleVal];
          response = "**Puzzle Preview**"
        }
        else {
          response = "@" + dispName + ", no puzzle pieces have been discovered.";
        }
      }
    }
  }
  else if (command.startsWith("!buy")) {
    if (channel !== "games") {
      alert("You can only use this command in the Games channel");
      showCmd = false;
      showChopi = false;
    }
    else {
      const holidayActive = await sendQuery("/battle.php", "SELECT * FROM `holidaystatus`");
      const holidayInfo = JSON.parse(holidayActive);
      if (holidayInfo['MiningActive'] === '0') {
        response = "@" + dispName + ", The mining game is not active.";
      }
      else {
        const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
        if (leader === "0") {
          response = "@" + dispName + ", You haven't played the mining game yet";
        }
        else {
          const leaderInfo = JSON.parse(leader);
          let coins = parseInt(leaderInfo['coins']);
          let xp_boost = parseInt(leaderInfo["xp_boost"]);
          let item_boost = parseInt(leaderInfo["item_boost"]);
          let [command, ...rest] = formValue.split(" ");
          let item = rest.join(" ");
          item = item.trim();
          if (item === "1") {
            if (coins < 5) {
              response = "@" + dispName + ", You don't have enough CC Coins to buy this item. Please use !shop to see the cost of the item and !bal to see how many CC Coins you have.";
            }
            else {
              coins = coins - 5;
              response = "@" + dispName + ", You have purchased an Instant Furnace!";
              const gamesRef = firestore.collection('gamechat');
              await instantSpawn(gamesRef, auth.currentUser.uid, 11, 0);
            }
          }
          else if (item === "2") {
            if (coins < 3) {
              response = "@" + dispName + ", You don't have enough CC Coins to buy this item. Please use !shop to see the cost of the item and !bal to see how many CC Coins you have.";
            }
            else {
              coins = coins - 3;
              response = "@" + dispName + ", You have purchased a Micro XP Boost!";
              xp_boost = xp_boost + 1;
            }
          }
          else if (item === "3") {
            if (coins < 12) {
              response = "@" + dispName + ", You don't have enough CC Coins to buy this item. Please use !shop to see the cost of the item and !bal to see how many CC Coins you have.";
            }
            else {
              coins = coins - 12;
              response = "@" + dispName + ", You have purchased a Small XP Boost!";
              xp_boost = xp_boost + 5;
            }
          }
          else if (item === "4") {
            if (coins < 20) {
              response = "@" + dispName + ", You don't have enough CC Coins to buy this item. Please use !shop to see the cost of the item and !bal to see how many CC Coins you have.";
            }
            else {
              coins = coins - 20;
              response = "@" + dispName + ", You have purchased a Large XP Boost!";
              xp_boost = xp_boost + 10;
            }
          }
          else if (item === "5") {
            if (coins < 3) {
              response = "@" + dispName + ", You don't have enough CC Coins to buy this item. Please use !shop to see the cost of the item and !bal to see how many CC Coins you have.";
            }
            else {
              coins = coins - 3;
              response = "@" + dispName + ", You have purchased a Micro Item Boost!";
              item_boost = item_boost + 1;
            }
          }
          else if (item === "6") {
            if (coins < 12) {
              response = "@" + dispName + ", You don't have enough CC Coins to buy this item. Please use !shop to see the cost of the item and !bal to see how many CC Coins you have.";
            }
            else {
              coins = coins - 12;
              response = "@" + dispName + ", You have purchased a Small Item Boost!";
              item_boost = item_boost + 5;
            }
          }
          else if (item === "7") {
            if (coins < 20) {
              response = "@" + dispName + ", You don't have enough CC Coins to buy this item. Please use !shop to see the cost of the item and !bal to see how many CC Coins you have.";
            }
            else {
              coins = coins - 20;
              response = "@" + dispName + ", You have purchased a Large Item Boost!";
              item_boost = item_boost + 10;
            }
          }
          else if (item === "8") {
            if (holidayInfo['ore_rush'] === '0') {
              if (coins < 50) {
                response = "@" + dispName + ", You don't have enough CC Coins to buy this item. Please use !shop to see the cost of the item and !bal to see how many CC Coins you have.";
              }
              else {
                const gamesRef = firestore.collection('gamechat');
                coins = coins - 50;
                let update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `ore_rush`=true");
                response = "@" + dispName + ", You have purchased an Ore Rush!";
                var timesRun = 0;
                var interval = setInterval(async function () {
                  await instantSpawn(gamesRef, auth.currentUser.uid, -1, 30 - timesRun - 1);
                  timesRun += 1;
                  if (timesRun === 30) {
                    update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `ore_rush`=false");
                    clearInterval(interval);

                  }
                }, 60000)
              }
            }
            else {
              response = "@" + dispName + ", There is already an active ore rush. Please wait for it to finish.";
            }
          }
          else {
            response = "@" + dispName + ", That is not a valid item you can buy. Use !shop to see what items are available";
          }
          let update = await sendQuery("/update_battles.php", "UPDATE `holidayleader` SET `coins`='" + coins + "',`item_boost`='" + item_boost + "',`xp_boost`='" + xp_boost + "' WHERE `uid`='" + auth.currentUser.uid + "'");
        }
      }
    }
  }
  else if (command.startsWith("!stoprush")) {
    if (admin) {
      if (channel !== "games") {
        alert("You can only use this command in the Games channel");
        showCmd = false;
        showChopi = false;
      }
      else {
        showCmd = false;
        let check = window.confirm("Are you sure you want to stop the Ore Rush?");
        if (check) {
          let update = await sendQuery("/update_battles.php", "UPDATE `holidaystatus` SET `ore_rush`=false");
          response = "Ore Rush stopped."
        }
        else {
          showChopi = false;
        }
      }
    }
    else {
      response = "@" + dispName + ", You don't have permission to use that command.";
    }
  }
  else if (command.startsWith("!help")) {
    response = "@" + dispName + " Here is the command reference link: https://collectingcommunity.com/commands"
  }
  else {
    response = "@" + dispName + ", that command doesn't exist";
  }
  return { response, showCmd, imgURL, showChopi };
}

const computeBadge = async (pickaxe) => {
  return "https://collectingcommunity.com/image/mining/pick" + pickaxe + ".png";
}

const computeColor = async (isAdmin, lvl, isMember) => {
  if (isAdmin) {
    return "#dd61ed";
  }
  if (isMember) {
    return "#393cf7";
  }
  if (lvl >= 100) {
    return "#02f79e";
  }
  if (lvl >= 90) {
    return "#5dfa02";
  }
  if (lvl >= 80) {
    return "#cfe622";
  }
  if (lvl >= 70) {
    return "#fff203";
  }
  if (lvl >= 60) {
    return "#fcba03";
  }
  if (lvl >= 50) {
    return "#f76a05";
  }
  if (lvl >= 40) {
    return "#ff522b";
  }
  if (lvl >= 30) {
    return "#f50227";
  }
  if (lvl >= 20) {
    return "#ff0084";
  }
  if (lvl >= 10) {
    return "#fc03e7";
  }
  return "white";
}

const messageSending = async (formValue, messagesRef, fileName, file, channel, replyUser, replyText, replyImg) => {
  $(".submit").prop("disabled", true);
  var main = document.getElementById("sent-disp-msg");
  main.innerHTML = "<Markdown remarkPlugins={[remarkGfm]}>" + formValue.trim() + "</Markdown>"
  $(".sent-disp").css("display", "flex");
  const versionSource = await sendQuery("/battle.php", "SELECT * FROM `version`");
  const versionInfo = JSON.parse(versionSource);
  let versionCheck = await checkVersion(versionInfo);
  if (versionCheck) {
    let enabledCheck = await checkChatEnabled(versionInfo);
    if (enabledCheck) {
      const usersRef = await firestore.collection('users');
      const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();
      const isAdmin = (!docSnap.empty);
      const docSnap2 = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "fam").get();
      const docSnap3 = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "friend").get();
      const docSnap4 = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "com").get();
      const famTier = (!docSnap2.empty);
      const friendTier = (!docSnap3.empty);
      const comTier = (!docSnap4.empty) || isAdmin;
      const isMember = (!docSnap2.empty) || (!docSnap3.empty) || (!docSnap4.empty);
      let adminEnabledCheck = await checkAdminChatEnabled(versionInfo, isAdmin, channel);
      if (adminEnabledCheck) {
        const leader = await sendQuery("/battle.php", "SELECT * FROM `holidayleader` WHERE uid='" + auth.currentUser.uid + "'");
        let color = "white";
        let badge = "";
        if (leader !== "0") {
          const leaderInfo = JSON.parse(leader);
          const lvl = parseInt(leaderInfo['lvl']);
          const pickaxe = parseInt(leaderInfo['mining']);
          color = await computeColor(isAdmin, lvl, isMember);
          badge = await computeBadge(pickaxe);
        }
        const { uid, photoURL, displayName } = auth.currentUser;
        const bannedRef = await firestore.collection('banned');
        // console.log(bannedRef)
        const notbanned = await bannedRef.where("uid", "==", auth.currentUser.uid).get();
        // console.log(notbanned)
        if (notbanned.empty) {
          if (file !== null) {
            fileName = Date.now() + '_' + file.name
            const url = '/server.php';
            const formData = new FormData();
            // file.name = Date.now() + '_' + file.name;
            // console.log(file.name);
            formData.append('file', file);
            formData.append('fileName', fileName);
            for (var key of formData.entries()) {
              console.log(key[0] + ', ' + key[1]);
            }

            await $.ajax({
              url: url,
              cache: false,
              dataType: 'text',
              contentType: false,
              processData: false,
              data: formData,
              type: 'POST',
              success: function (response) {
                console.log(response);
              },
              error: function (error) {
                console.log(error);
              }
            });
            var dispName = "Cool Cat";
            const query = (await usersRef.where("uid", "==", auth.currentUser.uid).get())
            query.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              dispName = doc.data().displayName;
            });
            const res = await messagesRef.add({
              text: formValue,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              uid,
              photoURL,
              displayName: dispName,
              image: "https://collectingcommunity.com/image/" + fileName,
              color,
              badge,
              famTier,
              friendTier,
              comTier,
              replyUser,
              replyImg,
              replyText,
            });
            res.set({
              text: formValue,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              uid,
              photoURL,
              displayName: dispName,
              image: "https://collectingcommunity.com/image/" + fileName,
              color,
              badge,
              famTier,
              friendTier,
              comTier,
              replyUser,
              replyImg,
              replyText,
              idVal: res.id
            });
            $(".sent-disp").css("display", "none");
          } else if (formValue.startsWith("!")) {
            var dispName = "Cool Cat";
            const query = (await usersRef.where("uid", "==", auth.currentUser.uid).get())
            query.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              dispName = doc.data().displayName;
            });
            const { response, showCmd, imgURL, showChopi } = await handleCommand(messagesRef, uid, formValue.trim(), dispName, isAdmin, channel);
            if (showCmd) {
              const res = await messagesRef.add({
                text: formValue,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid,
                photoURL,
                displayName: dispName,
                image: "",
                color,
                badge,
                famTier,
                friendTier,
                comTier,
                replyUser,
                replyImg,
                replyText,
              });
              res.set({
                text: formValue,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid,
                photoURL,
                displayName: dispName,
                image: "",
                color,
                badge,
                famTier,
                friendTier,
                comTier,
                replyUser,
                replyImg,
                replyText,
                idVal: res.id
              })
              $(".sent-disp").css("display", "none");
            }
            if (showChopi) {
              const res = await messagesRef.add({
                text: response,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid: "chopi",
                photoURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw-FaIPhk69fDUfuCzboUTzkks8gpzdKkcBg&usqp=CAU",
                displayName: "Chopi (BOT)",
                image: imgURL,
                color: "#34cceb",
                badge: "",
                famTier: false,
                friendTier: false,
                comTier: false,
                replyUser,
                replyImg,
                replyText,
              });
              res.set({
                text: response,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid: "chopi",
                photoURL: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw-FaIPhk69fDUfuCzboUTzkks8gpzdKkcBg&usqp=CAU",
                displayName: "Chopi (BOT)",
                image: imgURL,
                color: "#34cceb",
                badge: "",
                famTier: false,
                friendTier: false,
                comTier: false,
                replyUser,
                replyImg,
                replyText,
                idVal: res.id
              })
            }
          }
          else {
            var dispName = "Cool Cat";
            const query = (await usersRef.where("uid", "==", auth.currentUser.uid).get())
            query.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              dispName = doc.data().displayName;
            });
            if (file !== null) {
              const res = await messagesRef.add({
                text: formValue,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid,
                photoURL,
                displayName: dispName,
                image: "https://collectingcommunity.com/image/" + fileName,
                color,
                badge,
                famTier,
                friendTier,
                comTier,
                replyUser,
                replyImg,
                replyText,
              });
              res.set({
                text: formValue,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid,
                photoURL,
                displayName: dispName,
                image: "https://collectingcommunity.com/image/" + fileName,
                color,
                badge,
                famTier,
                friendTier,
                comTier,
                replyUser,
                replyImg,
                replyText,
                idVal: res.id
              })
              $(".sent-disp").css("display", "none");

            }
            else {
              const res = await messagesRef.add({
                text: formValue,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid,
                photoURL,
                displayName: dispName,
                image: "",
                color,
                badge,
                famTier,
                friendTier,
                comTier,
                replyUser,
                replyImg,
                replyText,
              });
              res.set({
                text: formValue,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                uid,
                photoURL,
                displayName: dispName,
                image: "",
                color,
                badge,
                famTier,
                friendTier,
                comTier,
                replyUser,
                replyImg,
                replyText,
                idVal: res.id
              })
              $(".sent-disp").css("display", "none");
            }
          }
          const gamesRef = firestore.collection('gamechat');
          await spawn(gamesRef, uid);
        } else {
          alert("You have been banned");
        }
      }
      else {
        alert('Chat has been disabled temporarily. Please stay tuned.');
      }
    }
    else {
      alert('Chat has been disabled temporarily. Please stay tuned.');
    }
  } else {
    alert('You must refresh the page to get the newest site update!');
  }
}

function App() {

  const [user] = useAuthState(auth);
  const [channel, setChannelValue] = useState('announcement');
  const announcementRef = firestore.collection('cur_announce');
  // const announcementRef = firestore.collection('announcement');
  const query = announcementRef.orderBy('createdAt').limitToLast(1);
  const [announcement] = useCollectionData(query);


  const open = async () => {
    var x = document.getElementById("others");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
    x = document.getElementById("scrollind");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }
  const hideAnnounce = async () => {
    var x = document.getElementById("latestAnnounce");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  useEffect(() => {
    var main = document.getElementById("selection");
    main.innerHTML = (channel === "announcement" ? "Announcements" : channel === "main" ? "General Chat" : channel === "livechat" ? "Live Chat" : channel == "mail" ? "CC Mail Room" : channel === "countchat" ? "Counting" : channel === "givechat" ? "Giveaways" : channel === "battlechat" ? "Battles" : channel === "gamechat" ? "Games" : channel === "promo" ? "Self Promo" : "Art and Pulls Showcase") + "<p id=\"genNotif\"> 💬</p><img id=\"notif\" src=\"https://assets.pokemon.com/assets/cms2/img/pokedex/full/175.png\"></img><p id=\"promoNotif\"> 📢</p>";
    main.scrollIntoView({ /*behavior: 'smooth'*/ })
    if (channel === "main") {
      var x = document.getElementById("genNotif");
      x.style.display = "none";
      var x2 = document.getElementById("genNotif2");
      x2.style.display = "none";
    }
    var x = document.getElementById("notif2");
    if (x.style.display === "inline") {
      var x2 = document.getElementById("notif");
      x2.style.display = "inline";
    }
    var x = document.getElementById("genNotif2");
    if (x.style.display === "inline") {
      var x2 = document.getElementById("genNotif");
      x2.style.display = "inline";
    }
    var x = document.getElementById("promoNotif2");
    if (x.style.display === "inline") {
      var x2 = document.getElementById("promoNotif");
      x2.style.display = "inline";
    }
  }, [channel])

  useEffect(() => {
    var x = document.getElementById("latestAnnounce");
    x.style.display = "block";
  }, [announcement]);

  return (
    <>
      <NavBar />
      <div className="App">
        <div className="channelbar">
          <div className="currentChannel">

            <a href="javascript:void(0);" id="selection" onClick={open}>
              General Chat<p id="genNotif"> 💬</p><img id="notif" src="https://assets.pokemon.com/assets/cms2/img/pokedex/full/175.png"></img><p id="promoNotif"> 📢</p></a>
          </div>
          <div id="others">
            <a className={`${channel === "announcement" ? "active" : null} announcement`} onClick={() => { setChannelValue("announcement"); open(); }}>Announcements</a>
            <a className={`${channel === "main" ? "active" : null} general`} onClick={() => { setChannelValue("main"); open(); }}>General Chat<p id="genNotif2"> 💬</p></a>
            <a className={`${channel === "gamechat" ? "active" : null} gamechat`} onClick={() => { setChannelValue("gamechat"); open(); }}>Games<img id="notif2" src="https://assets.pokemon.com/assets/cms2/img/pokedex/full/175.png"></img></a>
            <a className={`${channel === "livechat" ? "active" : null} livechat`} onClick={() => { setChannelValue("livechat"); open(); }}>Live Chat</a>
            <a className={`${channel === "mail" ? "active" : null} mailroom`} onClick={() => { setChannelValue("mail"); open(); }}>CC Mail Room</a>
            <a className={`${channel === "givechat" ? "active" : null} givechat`} onClick={() => { setChannelValue("givechat"); open(); }}>Giveaways</a>
            <a className={`${channel === "battlechat" ? "active" : null} battlechat`} onClick={() => { setChannelValue("battlechat"); open(); }}>Battles</a>
            <a className={`${channel === "countchat" ? "active" : null} countchat`} onClick={() => { setChannelValue("countchat"); open(); }}>Counting</a>
            <a className={`${channel === "promo" ? "active" : null} promo`} onClick={() => { setChannelValue("promo"); open(); }}>Self Promo<p id="promoNotif2"> 📢</p></a>
            <a className={`${channel === "showcase" ? "active" : null} showcase`} onClick={() => { setChannelValue("showcase"); open(); }}>Art and Pulls Showcase</a>

            {<SignOut />}
          </div>
          <div id="scrollind">
            <a>▼ ▼ ▼</a>
          </div>
          <a href="javascript:void(0);" className="icon" onClick={open}>⬇️
          </a>
        </div>



        <section >
          {<><div className="announceBar">
            <p id="latestAnnounce">{"Latest Announcement: "}{announcement && announcement[0] && <p style={{ display: "inline-block" }}>{announcement[0].text}</p>}</p>

          </div>
            <a href="javascript:void(0);" className="icon" onClick={hideAnnounce}>▼</a></>}
          {user ? (channel === "announcement" ? <AnnouncementChatRoom /> : channel === "main" ? <ChatRoom /> : channel === "livechat" ? <LiveChatRoom /> : channel === "mail" ? <MailChatRoom /> : channel === "countchat" ? <CountChatRoom /> : channel === "givechat" ? <GiveawayChatRoom /> : channel === "battlechat" ? <BattleChatRoom /> : channel === "gamechat" ? <GameChatRoom /> : channel === "promo" ? <SelfPromoChatRoom /> : <ShowcaseChatRoom />) : <SignIn />}
        </section>
      </div >
    </>
  );
}

function SignIn() {
  const [formValue, setFormValue] = useState('');
  const [updateProfile, updating, error] = useUpdateProfile(auth);

  const signInWithGoogle = async (e) => {
    e.preventDefault();
    if (formValue.trim() !== '') {
      const provider = new firebase.auth.GoogleAuthProvider();
      await auth.signInWithPopup(provider);
      const usersRef = await firestore.collection('users');
      await updateProfile(auth.currentUser, { displayName: formValue })
      console.log(auth.currentUser.displayName)
      const { uid, photoURL } = auth.currentUser;
      const docSnap = await usersRef.where("uid", "==", uid).get();
      console.log(docSnap);
      if (docSnap.empty) {
        const res = await usersRef.add({
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          role: "member",
          displayName: formValue.trim(),
          uid
        });
      }
    }

  }

  return (
    <>
      <br></br><br></br>
      <h1 className="chooseUser">Choose Your User Name:</h1>
      <br></br><br></br>

      <form autocomplete="off" onSubmit={signInWithGoogle} className="signin-form">
        <input type="text"
          required placeholder='Your Username' value={formValue} onChange={(e) => setFormValue(e.target.value)} className="contact-inputs" />
        <br></br><br></br>
        <button type="submit">Sign in with Google</button></form>
    </>

  )
}

function MailChimp() {
  const [email, setEmail] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    const url = 'https://pokegamble.us21.list-manage.com/subscribe/post-json?u=5b9ead3d4817c9f781a41fceb&amp;id=ee72d549be&amp;f_id=00a3eae6f0';
    jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
      console.log('data', data);
      const { msg } = data;

      alert(msg);
    });
  };

  return (
    <div style={{ color: "#f8f8f8", fontSize: "25px", textAlign: "center", backgroundColor: "#000", margin: "50px" }}>
      <form autocomplete="off" onSubmit={onSubmit}>
        <label className="newsletter">Subscribe to our Newsletter</label><br></br>
        <label className="newsletter">Email </label>
        <input
          type="email"
          name="EMAIL"
          required
          onChange={(e) => setEmail(e.target.value)}
        ></input>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

function SignOut() {
  return auth.currentUser && (
    <a className="signout" onClick={() => auth.signOut()}>Sign Out</a>
  )
}
// else if (channel === "live") {
//   const res = await liveRef.add({
//     text: formValue,
//     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
//     uid,
//     photoURL,
//     displayName: dispName,
//   });
//   res.set({
//     text: formValue,
//     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
//     uid,
//     photoURL,
//     displayName: dispName,
//     idVal: res.id
//   })
// }

const ScrollContainer = ({ children }) => {
  const outerDiv = useRef(null);
  const innerDiv = useRef(null);

  const prevInnerDivHeight = useRef(null);

  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const outerDivHeight = outerDiv.current.clientHeight;
    const innerDivHeight = innerDiv.current.clientHeight;
    const outerDivScrollTop = outerDiv.current.scrollTop;

    if (
      !prevInnerDivHeight.current ||
      outerDivScrollTop >= prevInnerDivHeight.current - outerDivHeight - 30
      && outerDivScrollTop <= prevInnerDivHeight.current - outerDivHeight + 30
    ) {
      outerDiv.current.scrollTo({
        top: innerDivHeight - outerDivHeight,
        left: 0,
        behavior: prevInnerDivHeight.current ? "smooth" : "auto"
      });
    } else {
      console.log("showing button?")
      setShowScrollButton(true);
    };

    prevInnerDivHeight.current = innerDivHeight;
  }, [children]);

  const handleScrollButtonClick = useCallback(() => {
    const outerDivHeight = outerDiv.current.clientHeight;
    const innerDivHeight = innerDiv.current.clientHeight;

    outerDiv.current.scrollTo({
      top: innerDivHeight - outerDivHeight,
      left: 0,
      behavior: "smooth"
    });

    setShowScrollButton(false);
  }, []);

  return (
    <>
      <div
        style={{
          position: "relative",
          height: "100%"
        }}
      >
        <div
          ref={outerDiv}
          className='scrollDiv'
          style={{
            position: "relative",
            height: "100%",
            overflowY: "scroll"
          }}
        >
          <div
            ref={innerDiv}
            style={{
              position: "relative"
            }}
          >
            {children}
          </div>
        </div>

      </div >
      <button
        style={{
          position: "absolute",
          backgroundColor: "red",
          color: "white",
          left: "50%",
          transform: "translateX(-50%)",
          opacity: showScrollButton ? 1 : 0,
          pointerEvents: showScrollButton ? "auto" : "none"
        }}
        className="newMessageButton"
        onClick={handleScrollButtonClick}
      >
        New message!
      </button>
    </>
  )
};

function ChatRoom() {

  const dummy = useRef()
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt').limitToLast(100);;
  const gamesRef = firestore.collection('gamechat');
  const gamesQuery = gamesRef.orderBy('createdAt').limitToLast(100);

  const [gamesMsg, loading] = useCollectionData(gamesQuery);
  const [loaded, setLoaded] = useState(false);

  const promoRef = firestore.collection('promo');
  const promoQuery = promoRef.orderBy('createdAt').limitToLast(100);

  const [promoMsg, promoLoading] = useCollectionData(promoQuery);
  const [promoLoaded, setPromoLoaded] = useState(false);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  const [file, setFile] = useState(null);
  var fileName = null;
  $(".submit").prop("disabled", false);
  const [replyUser, setReplyUser] = useState("");
  const [replyMsg, setReplyMsg] = useState("");
  const [replyImg, setReplyImg] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    if (formValue.trim() !== '' || file !== null) {
      let msgVal = formValue;
      setFormValue('');
      await messageSending(msgVal, messagesRef, fileName, file, "general", replyUser, replyMsg, replyImg);
      $(".sent-disp").css("display", "none");
      setFile(null);
      $(".submit").prop("disabled", false);
      setReplyUser("");
      setReplyMsg("");
      setReplyImg("");
    }
  }

  const replyToMessage = (userName, msg, img) => {
    setReplyUser(userName);
    setReplyMsg(msg);
    if (img !== "") {
      setReplyImg("Image 🖼️");
    }
  }

  const clearReply = () => {
    setReplyUser("");
    setReplyMsg("");
    setReplyImg("");
  }

  const { uid, photoURL, displayName } = auth.currentUser;


  // useEffect(() => {
  //   dummy.current.scrollIntoView({ /*behavior: 'smooth'*/ });
  // }, [messages])

  useEffect(() => {
    setTimeout(function () {
      setLoaded(true);
    }, 3000);
  }, [loading]);

  useEffect(() => {
    if (loaded) {
      var x = document.getElementById("notif");
      x.style.display = "inline";
      var x2 = document.getElementById("notif2");
      x2.style.display = "inline";
    }
  }, [gamesMsg]);

  useEffect(() => {
    if (promoLoaded) {
      var x = document.getElementById("promoNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("promoNotif2");
      x2.style.display = "inline";
    }
  }, [promoMsg]);

  useEffect(() => {
    setTimeout(function () {
      setPromoLoaded(true);
    }, 3000);
  }, [promoLoading]);



  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>

      <main className="chatMain">
        <ScrollContainer>
          {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} onReply={replyToMessage} />)}


          <div className='message sent-disp'><div id="container">
            <div id="menu-wrap">
              <input type="checkbox" class="toggler" />
              <div class="dots">
                <div></div>
              </div>
              <div class="menu">
              </div>
            </div>
          </div>
            <img className="pfp" src={photoURL} />
            <p className="dispName">{"Cool Cat"}</p>
            <div className="msg" id="sent-disp-msg"><Markdown remarkPlugins={[remarkGfm]}>{"."}</Markdown></div>

          </div>
          {/* <div ref={dummy}></div> */}
        </ScrollContainer>
      </main>

      <form autocomplete="off" className="send-form" id="myForm" value="" onSubmit={sendMessage}>
        {replyUser && replyUser !== "" && <label className="reply-block"><button className="clearReply" onClick={clearReply}>x</button>  Replying to <span>{replyUser}</span>: {replyMsg} {replyImg}</label>}
        <div className="send">
          <label className="custom-file-upload"><input id="filebutton" type="file" name="uploadfile" onChange={(e) => setFile(e.target.files[0])} />🖼️</label>
          <TextareaAutosize id="messageform" value={formValue} type="text" onChange={(e) => setFormValue(e.target.value)} cacheMeasurements onHeightChange={(height) => { document.getElementsByClassName("send").height = height }} onKeyDown={(e) => {
            if (e.keyCode === 13 && e.shiftKey === false) {
              e.preventDefault();

              $(".submit").click();
            }
          }} />        <button className="submit" type=" submit" name="upload">Send</button>
        </div>
      </form >

    </>
  )

}

function CountChatRoom() {

  const dummy = useRef()
  const messagesRef = firestore.collection('countchat');
  const query = messagesRef.orderBy('createdAt').limitToLast(100);
  const gamesRef = firestore.collection('gamechat');
  const gamesQuery = gamesRef.orderBy('createdAt').limitToLast(100);

  const [gamesMsg, loading] = useCollectionData(gamesQuery);
  const [loaded, setLoaded] = useState(false);

  const genRef = firestore.collection('messages');
  const genQuery = genRef.orderBy('createdAt').limitToLast(100);

  const [genMsg, genLoading] = useCollectionData(genQuery);
  const [genLoaded, setGenLoaded] = useState(false);


  const promoRef = firestore.collection('promo');
  const promoQuery = promoRef.orderBy('createdAt').limitToLast(100);

  const [promoMsg, promoLoading] = useCollectionData(promoQuery);
  const [promoLoaded, setPromoLoaded] = useState(false);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  const [file, setFile] = useState(null);
  var fileName = null;
  $(".submit").prop("disabled", false);
  const [replyUser, setReplyUser] = useState("");
  const [replyMsg, setReplyMsg] = useState("");
  const [replyImg, setReplyImg] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    if (formValue.trim() !== '' || file !== null) {
      let msgVal = formValue;
      setFormValue('');
      await messageSending(msgVal, messagesRef, fileName, file, "counting", replyUser, replyMsg, replyImg);
      $(".sent-disp").css("display", "none");
      setFile(null);
      $(".submit").prop("disabled", false);
      setReplyUser("");
      setReplyMsg("");
      setReplyImg("");
    }
  }

  const replyToMessage = (userName, msg, img) => {
    setReplyUser(userName);
    setReplyMsg(msg);
    if (img !== "") {
      setReplyImg("Image 🖼️");
    }
  }

  const clearReply = () => {
    setReplyUser("");
    setReplyMsg("");
    setReplyImg("");
  }

  const { uid, photoURL, displayName } = auth.currentUser;

  useEffect(() => {
    setTimeout(function () {
      setLoaded(true);
    }, 3000);
  }, [loading]);
  useEffect(() => {
    if (loaded) {
      var x = document.getElementById("notif");
      x.style.display = "inline";
      var x2 = document.getElementById("notif2");
      x2.style.display = "inline";
    }
  }, [gamesMsg]);

  useEffect(() => {
    if (genLoaded) {
      var x = document.getElementById("genNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("genNotif2");
      x2.style.display = "inline";
    }
  }, [genMsg]);

  useEffect(() => {
    setTimeout(function () {
      setGenLoaded(true);
    }, 3000);
  }, [genLoading]);

  useEffect(() => {
    if (promoLoaded) {
      var x = document.getElementById("promoNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("promoNotif2");
      x2.style.display = "inline";
    }
  }, [promoMsg]);

  useEffect(() => {
    setTimeout(function () {
      setPromoLoaded(true);
    }, 3000);
  }, [promoLoading]);


  // useEffect(() => {
  //   dummy.current.scrollIntoView({ /*behavior: 'smooth'*/ });
  // }, [messages])

  return (
    <>
      {/* UCcdVY1DZEhVmRPz2O9Q5BGg */}
      {/* <p align="center"><iframe id="ytplayer" width="320" height="180" src="https://www.youtube.com/embed/live_stream?channel=UCcdVY1DZEhVmRPz2O9Q5BGg&autoplay=1" align="center"></iframe></p > */}
      <main className="chatMain countchatmain">
        <ScrollContainer>
          {messages && messages.map(msg => <CountChatMessage key={msg.id} message={msg} onReply={replyToMessage} />)}


          <div className='message sent-disp'>
            <div id="container">
              <div id="menu-wrap">
                <input type="checkbox" class="toggler" />
                <div class="dots">
                  <div></div>
                </div>
                <div class="menu">
                </div>
              </div>
            </div>
            <img className="pfp" src={photoURL} />
            <p className="dispName">{"Cool Cat"}</p>
            <div className="msg" id="sent-disp-msg"><Markdown remarkPlugins={[remarkGfm]}>{"."}</Markdown></div>

          </div>
        </ScrollContainer>
        {/* <div ref={dummy}></div> */}
        <form autocomplete="off" className="send-form" id="myForm" value="" onSubmit={sendMessage}>
          {replyUser && replyUser !== "" && <label className="reply-block"><button className="clearReply" onClick={clearReply}>x</button>  Replying to <span>{replyUser}</span>: {replyMsg} {replyImg}</label>}
          <div className="send">
            <label className="custom-file-upload"><input id="filebutton" type="file" name="uploadfile" onChange={(e) => setFile(e.target.files[0])} />🖼️</label>
            <TextareaAutosize id="messageform" value={formValue} type="text" onChange={(e) => setFormValue(e.target.value)} cacheMeasurements onHeightChange={(height) => { document.getElementsByClassName("send").height = height }} onKeyDown={(e) => {
              if (e.keyCode === 13 && e.shiftKey === false) {
                e.preventDefault();

                $(".submit").click();
              }
            }} />        <button className="submit" type=" submit" name="upload">Send</button>
          </div>
        </form >
      </main>
    </>
  )

}


function LiveChatRoom() {
  const dummy = useRef()
  const messagesRef = firestore.collection('livechat');
  const query = messagesRef.orderBy('createdAt').limitToLast(100);
  const gamesRef = firestore.collection('gamechat');
  const gamesQuery = gamesRef.orderBy('createdAt').limitToLast(100);

  const [gamesMsg, loading] = useCollectionData(gamesQuery);
  const [loaded, setLoaded] = useState(false);

  const genRef = firestore.collection('messages');
  const genQuery = genRef.orderBy('createdAt').limitToLast(100);

  const [genMsg, genLoading] = useCollectionData(genQuery);
  const [genLoaded, setGenLoaded] = useState(false);

  const promoRef = firestore.collection('promo');
  const promoQuery = promoRef.orderBy('createdAt').limitToLast(100);

  const [promoMsg, promoLoading] = useCollectionData(promoQuery);
  const [promoLoaded, setPromoLoaded] = useState(false);


  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  const [file, setFile] = useState(null);
  var fileName = null;
  $(".submit").prop("disabled", false);
  const [replyUser, setReplyUser] = useState("");
  const [replyMsg, setReplyMsg] = useState("");
  const [replyImg, setReplyImg] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    if (formValue.trim() !== '' || file !== null) {
      let msgVal = formValue;
      setFormValue('');
      await messageSending(msgVal, messagesRef, fileName, file, "live", replyUser, replyMsg, replyImg);
      $(".sent-disp").css("display", "none");
      setFile(null);
      $(".submit").prop("disabled", false);
      setReplyUser("");
      setReplyMsg("");
      setReplyImg("");
    }
  }

  const replyToMessage = (userName, msg, img) => {
    setReplyUser(userName);
    setReplyMsg(msg);
    if (img !== "") {
      setReplyImg("Image 🖼️");
    }
  }

  const clearReply = () => {
    setReplyUser("");
    setReplyMsg("");
    setReplyImg("");
  }

  const { uid, photoURL, displayName } = auth.currentUser;

  useEffect(() => {
    setTimeout(function () {
      setLoaded(true);
    }, 3000);
  }, [loading]);
  useEffect(() => {
    if (loaded) {
      var x = document.getElementById("notif");
      x.style.display = "inline";
      var x2 = document.getElementById("notif2");
      x2.style.display = "inline";
    }
  }, [gamesMsg]);

  useEffect(() => {
    if (genLoaded) {
      var x = document.getElementById("genNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("genNotif2");
      x2.style.display = "inline";
    }
  }, [genMsg]);

  useEffect(() => {
    setTimeout(function () {
      setGenLoaded(true);
    }, 3000);
  }, [genLoading]);


  useEffect(() => {
    if (promoLoaded) {
      var x = document.getElementById("promoNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("promoNotif2");
      x2.style.display = "inline";
    }
  }, [promoMsg]);

  useEffect(() => {
    setTimeout(function () {
      setPromoLoaded(true);
    }, 3000);
  }, [promoLoading]);


  // useEffect(() => {
  //   dummy.current.scrollIntoView({ /*behavior: 'smooth'*/ });
  // }, [messages])

  return (
    <>
      {/* UCcdVY1DZEhVmRPz2O9Q5BGg */}
      <p align="center"><iframe id="ytplayer" width="320" height="180" src="https://www.youtube.com/embed/live_stream?channel=UCcdVY1DZEhVmRPz2O9Q5BGg&autoplay=1" align="center"></iframe></p >
      <main className="chatMain livechatmain">
        <ScrollContainer>
          {messages && messages.map(msg => <LiveChatMessage key={msg.id} message={msg} onReply={replyToMessage} />)}


          <div className='message sent-disp'>
            <div id="container">
              <div id="menu-wrap">
                <input type="checkbox" class="toggler" />
                <div class="dots">
                  <div></div>
                </div>
                <div class="menu">
                </div>
              </div>
            </div>
            <img className="pfp" src={photoURL} />
            <p className="dispName">{"Cool Cat"}</p>
            <div className="msg" id="sent-disp-msg"><Markdown remarkPlugins={[remarkGfm]}>{"."}</Markdown></div>

          </div>
        </ScrollContainer>
        {/* <div ref={dummy}></div> */}
        <form autocomplete="off" className="send-form" id="myForm" value="" onSubmit={sendMessage}>
          {replyUser && replyUser !== "" && <label className="reply-block"><button className="clearReply" onClick={clearReply}>x</button>  Replying to <span>{replyUser}</span>: {replyMsg} {replyImg}</label>}
          <div className="send">
            <label className="custom-file-upload"><input id="filebutton" type="file" name="uploadfile" onChange={(e) => setFile(e.target.files[0])} />🖼️</label>
            <TextareaAutosize id="messageform" value={formValue} type="text" onChange={(e) => setFormValue(e.target.value)} cacheMeasurements onHeightChange={(height) => { document.getElementsByClassName("send").height = height }} onKeyDown={(e) => {
              if (e.keyCode === 13 && e.shiftKey === false) {
                e.preventDefault();

                $(".submit").click();
              }
            }} />        <button className="submit" type=" submit" name="upload">Send</button>
          </div>
        </form >
      </main>
    </>
  )

}

function GiveawayChatRoom() {

  const dummy = useRef()
  const messagesRef = firestore.collection('giveawaychat');
  const query = messagesRef.orderBy('createdAt').limitToLast(100);
  const gamesRef = firestore.collection('gamechat');
  const gamesQuery = gamesRef.orderBy('createdAt').limitToLast(100);

  const [gamesMsg, loading] = useCollectionData(gamesQuery);
  const [loaded, setLoaded] = useState(false);

  const genRef = firestore.collection('messages');
  const genQuery = genRef.orderBy('createdAt').limitToLast(100);

  const [genMsg, genLoading] = useCollectionData(genQuery);
  const [genLoaded, setGenLoaded] = useState(false);

  const promoRef = firestore.collection('promo');
  const promoQuery = promoRef.orderBy('createdAt').limitToLast(100);

  const [promoMsg, promoLoading] = useCollectionData(promoQuery);
  const [promoLoaded, setPromoLoaded] = useState(false);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  const [file, setFile] = useState(null);
  var fileName = null;
  $(".submit").prop("disabled", false);
  const [replyUser, setReplyUser] = useState("");
  const [replyMsg, setReplyMsg] = useState("");
  const [replyImg, setReplyImg] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    if (formValue.trim() !== '' || file !== null) {
      let msgVal = formValue;
      setFormValue('');
      await messageSending(msgVal, messagesRef, fileName, file, "giveaway", replyUser, replyMsg, replyImg);
      $(".sent-disp").css("display", "none");
      setFile(null);
      $(".submit").prop("disabled", false);
      setReplyUser("");
      setReplyMsg("");
      setReplyImg("");
    }
  }

  const replyToMessage = (userName, msg, img) => {
    setReplyUser(userName);
    setReplyMsg(msg);
    if (img !== "") {
      setReplyImg("Image 🖼️");
    }
  }

  const clearReply = () => {
    setReplyUser("");
    setReplyMsg("");
    setReplyImg("");
  }

  const { uid, photoURL, displayName } = auth.currentUser;

  useEffect(() => {
    setTimeout(function () {
      setLoaded(true);
    }, 3000);
  }, [loading]);

  useEffect(() => {
    if (loaded) {
      var x = document.getElementById("notif");
      x.style.display = "inline";
      var x2 = document.getElementById("notif2");
      x2.style.display = "inline";
    }
  }, [gamesMsg]);

  useEffect(() => {
    if (genLoaded) {
      var x = document.getElementById("genNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("genNotif2");
      x2.style.display = "inline";
    }
  }, [genMsg]);

  useEffect(() => {
    setTimeout(function () {
      setGenLoaded(true);
    }, 3000);
  }, [genLoading]);

  useEffect(() => {
    if (promoLoaded) {
      var x = document.getElementById("promoNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("promoNotif2");
      x2.style.display = "inline";
    }
  }, [promoMsg]);

  useEffect(() => {
    setTimeout(function () {
      setPromoLoaded(true);
    }, 3000);
  }, [promoLoading]);


  return (
    <>

      <main className="chatMain">
        <ScrollContainer>
          {messages && messages.map(msg => <GiveawayChatMessage key={msg.id} message={msg} onReply={replyToMessage} />)}

          <div className='message sent-disp'>
            <div id="container">
              <div id="menu-wrap">
                <input type="checkbox" class="toggler" />
                <div class="dots">
                  <div></div>
                </div>
                <div class="menu">
                </div>
              </div>
            </div>
            <img className="pfp" src={photoURL} />
            <p className="dispName">{"Cool Cat"}</p>
            <div className="msg" id="sent-disp-msg"><Markdown remarkPlugins={[remarkGfm]}>{"."}</Markdown></div>

          </div>
        </ScrollContainer>
        {/* <div ref={dummy}></div> */}

      </main>

      <form autocomplete="off" className="send-form" id="myForm" value="" onSubmit={sendMessage}>
        {replyUser && replyUser !== "" && <label className="reply-block"><button className="clearReply" onClick={clearReply}>x</button>  Replying to <span>{replyUser}</span>: {replyMsg} {replyImg}</label>}
        <div className="send">
          <label className="custom-file-upload"><input id="filebutton" type="file" name="uploadfile" onChange={(e) => setFile(e.target.files[0])} />🖼️</label>
          <TextareaAutosize id="messageform" value={formValue} type="text" onChange={(e) => setFormValue(e.target.value)} cacheMeasurements onHeightChange={(height) => { document.getElementsByClassName("send").height = height }} onKeyDown={(e) => {
            if (e.keyCode === 13 && e.shiftKey === false) {
              e.preventDefault();

              $(".submit").click();
            }
          }} />        <button className="submit" type=" submit" name="upload">Send</button>
        </div>
      </form >

    </>
  )

}

function AnnouncementChatRoom() {

  const dummy = useRef()
  const messagesRef = firestore.collection('announcement');
  const query = messagesRef.orderBy('createdAt').limitToLast(100);
  const gamesRef = firestore.collection('gamechat');
  const gamesQuery = gamesRef.orderBy('createdAt').limitToLast(100);

  const [gamesMsg, loading] = useCollectionData(gamesQuery);
  const [loaded, setLoaded] = useState(false);

  const genRef = firestore.collection('messages');
  const genQuery = genRef.orderBy('createdAt').limitToLast(100);

  const [genMsg, genLoading] = useCollectionData(genQuery);
  const [genLoaded, setGenLoaded] = useState(false);

  const promoRef = firestore.collection('promo');
  const promoQuery = promoRef.orderBy('createdAt').limitToLast(100);

  const [promoMsg, promoLoading] = useCollectionData(promoQuery);
  const [promoLoaded, setPromoLoaded] = useState(false);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  const [file, setFile] = useState(null);
  var fileName = null;
  $(".submit").prop("disabled", false);
  const [replyUser, setReplyUser] = useState("");
  const [replyMsg, setReplyMsg] = useState("");
  const [replyImg, setReplyImg] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    if (formValue.trim() !== '' || file !== null) {
      const usersRef = await firestore.collection('users');
      const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();
      const isAdmin = (!docSnap.empty);
      if (isAdmin) {
        let msgVal = formValue;
        setFormValue('');
        await messageSending(msgVal, messagesRef, fileName, file, "announcement", replyUser, replyMsg, replyImg);
        $(".sent-disp").css("display", "none");
        setFile(null);
        $(".submit").prop("disabled", false);
        setReplyUser("");
        setReplyMsg("");
        setReplyImg("");
      }
      else {
        alert("You don't have permission to send messages here.");
      }
    }
  }

  const replyToMessage = (userName, msg, img) => {
    setReplyUser(userName);
    setReplyMsg(msg);
    if (img !== "") {
      setReplyImg("Image 🖼️");
    }
  }

  const clearReply = () => {
    setReplyUser("");
    setReplyMsg("");
    setReplyImg("");
  }

  const { uid, photoURL, displayName } = auth.currentUser;

  useEffect(() => {
    setTimeout(function () {
      setLoaded(true);
    }, 3000);
  }, [loading]);

  useEffect(() => {
    if (loaded) {
      var x = document.getElementById("notif");
      x.style.display = "inline";
      var x2 = document.getElementById("notif2");
      x2.style.display = "inline";
    }
  }, [gamesMsg]);

  useEffect(() => {
    if (genLoaded) {
      var x = document.getElementById("genNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("genNotif2");
      x2.style.display = "inline";
    }
  }, [genMsg]);

  useEffect(() => {
    setTimeout(function () {
      setGenLoaded(true);
    }, 3000);
  }, [genLoading]);

  useEffect(() => {
    if (promoLoaded) {
      var x = document.getElementById("promoNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("promoNotif2");
      x2.style.display = "inline";
    }
  }, [promoMsg]);

  useEffect(() => {
    setTimeout(function () {
      setPromoLoaded(true);
    }, 3000);
  }, [promoLoading]);


  return (
    <>

      <main className="chatMain">
        <ScrollContainer>
          {messages && messages.map(msg => <AnnouncementChatMessage key={msg.id} message={msg} onReply={replyToMessage} />)}

          <div className='message sent-disp'>
            <div id="container">
              <div id="menu-wrap">
                <input type="checkbox" class="toggler" />
                <div class="dots">
                  <div></div>
                </div>
                <div class="menu">
                </div>
              </div>
            </div>
            <img className="pfp" src={photoURL} />
            <p className="dispName">{"Cool Cat"}</p>
            <div className="msg" id="sent-disp-msg"><Markdown remarkPlugins={[remarkGfm]}>{"."}</Markdown></div>

          </div>
        </ScrollContainer>
        {/* <div ref={dummy}></div> */}

      </main>

      <form autocomplete="off" className="send-form" id="myForm" value="" onSubmit={sendMessage}>
        {replyUser && replyUser !== "" && <label className="reply-block"><button className="clearReply" onClick={clearReply}>x</button>  Replying to <span>{replyUser}</span>: {replyMsg} {replyImg}</label>}
        <div className="send">
          <label className="custom-file-upload"><input id="filebutton" type="file" name="uploadfile" onChange={(e) => setFile(e.target.files[0])} />🖼️</label>
          <TextareaAutosize id="messageform" value={formValue} type="text" onChange={(e) => setFormValue(e.target.value)} cacheMeasurements onHeightChange={(height) => { document.getElementsByClassName("send").height = height }} onKeyDown={(e) => {
            if (e.keyCode === 13 && e.shiftKey === false) {
              e.preventDefault();

              $(".submit").click();
            }
          }} />        <button className="submit" type=" submit" name="upload">Send</button>
        </div>
      </form >

    </>
  )

}

function MailChatRoom() {

  const dummy = useRef()
  const messagesRef = firestore.collection('mail');
  const query = messagesRef.orderBy('createdAt').limitToLast(100);
  const gamesRef = firestore.collection('gamechat');
  const gamesQuery = gamesRef.orderBy('createdAt').limitToLast(100);

  const [gamesMsg, loading] = useCollectionData(gamesQuery);
  const [loaded, setLoaded] = useState(false);

  const genRef = firestore.collection('messages');
  const genQuery = genRef.orderBy('createdAt').limitToLast(100);

  const [genMsg, genLoading] = useCollectionData(genQuery);
  const [genLoaded, setGenLoaded] = useState(false);

  const promoRef = firestore.collection('promo');
  const promoQuery = promoRef.orderBy('createdAt').limitToLast(100);

  const [promoMsg, promoLoading] = useCollectionData(promoQuery);
  const [promoLoaded, setPromoLoaded] = useState(false);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  const [file, setFile] = useState(null);
  var fileName = null;
  $(".submit").prop("disabled", false);
  const [replyUser, setReplyUser] = useState("");
  const [replyMsg, setReplyMsg] = useState("");
  const [replyImg, setReplyImg] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    if (formValue.trim() !== '' || file !== null) {
      if (auth.currentUser.uid === "qFCYD5KhCLU2BwbRKSJEjpBW7uA3") {
        let msgVal = formValue;
        setFormValue('');
        await messageSending(msgVal, messagesRef, fileName, file, "mail", replyUser, replyMsg, replyImg);
        $(".sent-disp").css("display", "none");
        setFile(null);
        $(".submit").prop("disabled", false);
        setReplyUser("");
        setReplyMsg("");
        setReplyImg("");
      }
      else {
        alert("You don't have permission to send messages here.");
      }
    }
  }

  const replyToMessage = (userName, msg, img) => {
    setReplyUser(userName);
    setReplyMsg(msg);
    if (img !== "") {
      setReplyImg("Image 🖼️");
    }
  }

  const clearReply = () => {
    setReplyUser("");
    setReplyMsg("");
    setReplyImg("");
  }

  const { uid, photoURL, displayName } = auth.currentUser;

  useEffect(() => {
    setTimeout(function () {
      setLoaded(true);
    }, 3000);
  }, [loading]);

  useEffect(() => {
    if (loaded) {
      var x = document.getElementById("notif");
      x.style.display = "inline";
      var x2 = document.getElementById("notif2");
      x2.style.display = "inline";
    }
  }, [gamesMsg]);

  useEffect(() => {
    if (genLoaded) {
      var x = document.getElementById("genNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("genNotif2");
      x2.style.display = "inline";
    }
  }, [genMsg]);

  useEffect(() => {
    setTimeout(function () {
      setGenLoaded(true);
    }, 3000);
  }, [genLoading]);

  useEffect(() => {
    if (promoLoaded) {
      var x = document.getElementById("promoNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("promoNotif2");
      x2.style.display = "inline";
    }
  }, [promoMsg]);

  useEffect(() => {
    setTimeout(function () {
      setPromoLoaded(true);
    }, 3000);
  }, [promoLoading]);


  return (
    <>

      <main className="chatMain">
        <ScrollContainer>
          {messages && messages.map(msg => <MailChatMessage key={msg.id} message={msg} onReply={replyToMessage} />)}

          <div className='message sent-disp'>
            <div id="container">
              <div id="menu-wrap">
                <input type="checkbox" class="toggler" />
                <div class="dots">
                  <div></div>
                </div>
                <div class="menu">
                </div>
              </div>
            </div>
            <img className="pfp" src={photoURL} />
            <p className="dispName">{"Cool Cat"}</p>
            <div className="msg" id="sent-disp-msg"><Markdown remarkPlugins={[remarkGfm]}>{"."}</Markdown></div>

          </div>
        </ScrollContainer>
        {/* <div ref={dummy}></div> */}

      </main>

      <form autocomplete="off" className="send-form" id="myForm" value="" onSubmit={sendMessage}>
        {replyUser && replyUser !== "" && <label className="reply-block"><button className="clearReply" onClick={clearReply}>x</button>  Replying to <span>{replyUser}</span>: {replyMsg} {replyImg}</label>}
        <div className="send">
          <label className="custom-file-upload"><input id="filebutton" type="file" name="uploadfile" onChange={(e) => setFile(e.target.files[0])} />🖼️</label>
          <TextareaAutosize id="messageform" value={formValue} type="text" onChange={(e) => setFormValue(e.target.value)} cacheMeasurements onHeightChange={(height) => { document.getElementsByClassName("send").height = height }} onKeyDown={(e) => {
            if (e.keyCode === 13 && e.shiftKey === false) {
              e.preventDefault();

              $(".submit").click();
            }
          }} />        <button className="submit" type=" submit" name="upload">Send</button>
        </div>
      </form >

    </>
  )

}

function BattleChatRoom() {

  const dummy = useRef()
  const messagesRef = firestore.collection('battlechat');
  const query = messagesRef.orderBy('createdAt').limitToLast(100);
  const gamesRef = firestore.collection('gamechat');
  const gamesQuery = gamesRef.orderBy('createdAt').limitToLast(100);

  const [gamesMsg, loading] = useCollectionData(gamesQuery);
  const [loaded, setLoaded] = useState(false);

  const genRef = firestore.collection('messages');
  const genQuery = genRef.orderBy('createdAt').limitToLast(100);

  const [genMsg, genLoading] = useCollectionData(genQuery);
  const [genLoaded, setGenLoaded] = useState(false);

  const promoRef = firestore.collection('promo');
  const promoQuery = promoRef.orderBy('createdAt').limitToLast(100);

  const [promoMsg, promoLoading] = useCollectionData(promoQuery);
  const [promoLoaded, setPromoLoaded] = useState(false);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  const [file, setFile] = useState(null);
  var fileName = null;
  $(".submit").prop("disabled", false);
  const [replyUser, setReplyUser] = useState("");
  const [replyMsg, setReplyMsg] = useState("");
  const [replyImg, setReplyImg] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    if (formValue.trim() !== '' || file !== null) {
      let msgVal = formValue;
      setFormValue('');
      await messageSending(msgVal, messagesRef, fileName, file, "battle", replyUser, replyMsg, replyImg);
      $(".sent-disp").css("display", "none");
      setFile(null);
      $(".submit").prop("disabled", false);
      setReplyUser("");
      setReplyMsg("");
      setReplyImg("");
    }
  }

  const replyToMessage = (userName, msg, img) => {
    setReplyUser(userName);
    setReplyMsg(msg);
    if (img !== "") {
      setReplyImg("Image 🖼️");
    }
  }

  const clearReply = () => {
    setReplyUser("");
    setReplyMsg("");
    setReplyImg("");
  }

  const { uid, photoURL, displayName } = auth.currentUser;

  useEffect(() => {
    setTimeout(function () {
      setLoaded(true);
    }, 3000);
  }, [loading]);

  useEffect(() => {
    if (loaded) {
      var x = document.getElementById("notif");
      x.style.display = "inline";
      var x2 = document.getElementById("notif2");
      x2.style.display = "inline";
    }
  }, [gamesMsg]);

  useEffect(() => {
    if (genLoaded) {
      var x = document.getElementById("genNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("genNotif2");
      x2.style.display = "inline";
    }
  }, [genMsg]);

  useEffect(() => {
    setTimeout(function () {
      setGenLoaded(true);
    }, 3000);
  }, [genLoading]);

  useEffect(() => {
    if (promoLoaded) {
      var x = document.getElementById("promoNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("promoNotif2");
      x2.style.display = "inline";
    }
  }, [promoMsg]);

  useEffect(() => {
    setTimeout(function () {
      setPromoLoaded(true);
    }, 3000);
  }, [promoLoading]);


  // useEffect(() => {
  //   dummy.current.scrollIntoView({ /*behavior: 'smooth'*/ });
  // }, [messages])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>

      <main className="chatMain">
        <ScrollContainer>
          {messages && messages.map(msg => <BattleChatMessage key={msg.id} message={msg} onReply={replyToMessage} />)}

          <div className='message sent-disp'>
            <div id="container">
              <div id="menu-wrap">
                <input type="checkbox" class="toggler" />
                <div class="dots">
                  <div></div>
                </div>
                <div class="menu">
                </div>
              </div>
            </div>
            <img className="pfp" src={photoURL} />
            <p className="dispName">{"Cool Cat"}</p>
            <div className="msg" id="sent-disp-msg"><Markdown remarkPlugins={[remarkGfm]}>{"."}</Markdown></div>

          </div>
        </ScrollContainer>

        {/* <div ref={dummy}></div> */}
      </main>

      <form autocomplete="off" className="send-form" id="myForm" value="" onSubmit={sendMessage}>
        {replyUser && replyUser !== "" && <label className="reply-block"><button className="clearReply" onClick={clearReply}>x</button>  Replying to <span>{replyUser}</span>: {replyMsg} {replyImg}</label>}
        <div className="send">
          <label className="custom-file-upload"><input id="filebutton" type="file" name="uploadfile" onChange={(e) => setFile(e.target.files[0])} />🖼️</label>
          <TextareaAutosize id="messageform" value={formValue} type="text" onChange={(e) => setFormValue(e.target.value)} cacheMeasurements onHeightChange={(height) => { document.getElementsByClassName("send").height = height }} onKeyDown={(e) => {
            if (e.keyCode === 13 && e.shiftKey === false) {
              e.preventDefault();

              $(".submit").click();
            }
          }} />        <button className="submit" type=" submit" name="upload">Send</button>
        </div>
      </form >

    </>
  )

}

function GameChatRoom() {
  var x = document.getElementById("notif");
  x.style.display = "none";
  var x2 = document.getElementById("notif2");
  x2.style.display = "none";

  const genRef = firestore.collection('messages');
  const genQuery = genRef.orderBy('createdAt').limitToLast(100);

  const [genMsg, genLoading] = useCollectionData(genQuery);
  const [genLoaded, setGenLoaded] = useState(false);

  const promoRef = firestore.collection('promo');
  const promoQuery = promoRef.orderBy('createdAt').limitToLast(100);

  const [promoMsg, promoLoading] = useCollectionData(promoQuery);
  const [promoLoaded, setPromoLoaded] = useState(false);

  const dummy = useRef()
  const messagesRef = firestore.collection('gamechat');
  const query = messagesRef.orderBy('createdAt').limitToLast(100);


  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  const [file, setFile] = useState(null);
  var fileName = null;
  $(".submit").prop("disabled", false);
  const [replyUser, setReplyUser] = useState("");
  const [replyMsg, setReplyMsg] = useState("");
  const [replyImg, setReplyImg] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    if (formValue.trim() !== '' || file !== null) {
      let msgVal = formValue;
      setFormValue('');
      await messageSending(msgVal, messagesRef, fileName, file, "games", replyUser, replyMsg, replyImg);
      $(".sent-disp").css("display", "none");
      setFile(null);
      $(".submit").prop("disabled", false);
      setReplyUser("");
      setReplyMsg("");
      setReplyImg("");
    }
  }

  const replyToMessage = (userName, msg, img) => {
    setReplyUser(userName);
    setReplyMsg(msg);
    if (img !== "") {
      setReplyImg("Image 🖼️");
    }
  }

  const clearReply = () => {
    setReplyUser("");
    setReplyMsg("");
    setReplyImg("");
  }

  const { uid, photoURL, displayName } = auth.currentUser;


  useEffect(() => {
    if (genLoaded) {
      var x = document.getElementById("genNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("genNotif2");
      x2.style.display = "inline";
    }
  }, [genMsg]);

  useEffect(() => {
    setTimeout(function () {
      setGenLoaded(true);
    }, 3000);
  }, [genLoading]);

  useEffect(() => {
    if (promoLoaded) {
      var x = document.getElementById("promoNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("promoNotif2");
      x2.style.display = "inline";
    }
  }, [promoMsg]);

  useEffect(() => {
    setTimeout(function () {
      setPromoLoaded(true);
    }, 3000);
  }, [promoLoading]);

  // useEffect(() => {
  //   dummy.current.scrollIntoView({ /*behavior: 'smooth'*/ });
  // }, [messages])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>

      <main className="chatMain">
        <ScrollContainer>
          {messages && messages.map(msg => <GameChatMessage key={msg.id} message={msg} onReply={replyToMessage} />)}

          <div className='message sent-disp'>
            <div id="container">
              <div id="menu-wrap">
                <input type="checkbox" class="toggler" />
                <div class="dots">
                  <div></div>
                </div>
                <div class="menu">
                </div>
              </div>
            </div>
            <img className="pfp" src={photoURL} />
            <p className="dispName">{"Cool Cat"}</p>
            <div className="msg" id="sent-disp-msg"><Markdown remarkPlugins={[remarkGfm]}>{"."}</Markdown></div>

          </div>
        </ScrollContainer>
        {/* <div ref={dummy}></div> */}
      </main>

      <form autocomplete="off" className="send-form" id="myForm" value="" onSubmit={sendMessage}>
        {replyUser && replyUser !== "" && <label className="reply-block"><button className="clearReply" onClick={clearReply}>x</button>  Replying to <span>{replyUser}</span>: {replyMsg} {replyImg}</label>}
        <div className="send">
          <label className="custom-file-upload"><input id="filebutton" type="file" name="uploadfile" onChange={(e) => setFile(e.target.files[0])} />🖼️</label>
          <TextareaAutosize id="messageform" value={formValue} type="text" onChange={(e) => setFormValue(e.target.value)} cacheMeasurements onHeightChange={(height) => { document.getElementsByClassName("send").height = height }} onKeyDown={(e) => {
            if (e.keyCode === 13 && e.shiftKey === false) {
              e.preventDefault();

              $(".submit").click();
            }
          }} />        <button className="submit" type=" submit" name="upload">Send</button>
        </div>
      </form >
      <div id="modal" className="modal">
        <div className="modalDialog">
          <p id="modalMessage">Would you like to view a 3 second ad to earn a free second shake on this spawn?</p>
          {/* <a className='ad-link' target="_blank" href='https://amzn.to/3TNnjjb'><img className="ad-img" alt='image' src="https://cdn.discordapp.com/attachments/1180271977739583638/1194177573547163668/VaultX.png?ex=65af6788&is=659cf288&hm=9c9527791e5fa2a73718ffe64d6ba08f72f6eb2e4a6edd771f068eb59dbbf82f&"></img></a> */}
          <ins class="adsbygoogle"
            style={{ display: "inline-block", width: "300px", height: "250px" }}
            data-ad-client="ca-pub-6995449797471895"
            data-ad-slot="4791581073"></ins>
          <br></br>
          <span className="grantButtons">
            <input id="closeButton" type="button" value="❌" onClick={giveReward} />
          </span>
          <span className="completeButtons">
            <input id="completeButton" type="button" value="Close" onClick={completeReward} />
          </span>
          <span className="rewardButtons">
            <input type="button" id="watchAdButton" value="Yes" onClick={showAd} />
            <input id="noRewardButton" type="button" value="No" onClick={closeAd} />
          </span>
        </div>
      </div>

    </>
  )

}

function SelfPromoChatRoom() {
  var x = document.getElementById("promoNotif");
  x.style.display = "none";
  var x2 = document.getElementById("promoNotif2");
  x2.style.display = "none";

  const dummy = useRef()
  const messagesRef = firestore.collection('promo');
  const query = messagesRef.orderBy('createdAt').limitToLast(100);
  const gamesRef = firestore.collection('gamechat');
  const gamesQuery = gamesRef.orderBy('createdAt').limitToLast(100);

  const [gamesMsg, loading] = useCollectionData(gamesQuery);
  const [loaded, setLoaded] = useState(false);

  const genRef = firestore.collection('messages');
  const genQuery = genRef.orderBy('createdAt').limitToLast(100);

  const [genMsg, genLoading] = useCollectionData(genQuery);
  const [genLoaded, setGenLoaded] = useState(false);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  const [file, setFile] = useState(null);
  var fileName = null;
  $(".submit").prop("disabled", false);
  const [replyUser, setReplyUser] = useState("");
  const [replyMsg, setReplyMsg] = useState("");
  const [replyImg, setReplyImg] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    if (formValue.trim() !== '' || file !== null) {
      let msgVal = formValue;
      setFormValue('');
      await messageSending(msgVal, messagesRef, fileName, file, "promo", replyUser, replyMsg, replyImg);
      $(".sent-disp").css("display", "none");
      setFile(null);
      $(".submit").prop("disabled", false);
      setReplyUser("");
      setReplyMsg("");
      setReplyImg("");
    }
  }

  const replyToMessage = (userName, msg, img) => {
    setReplyUser(userName);
    setReplyMsg(msg);
    if (img !== "") {
      setReplyImg("Image 🖼️");
    }
  }

  const clearReply = () => {
    setReplyUser("");
    setReplyMsg("");
    setReplyImg("");
  }

  const { uid, photoURL, displayName } = auth.currentUser;

  // useEffect(() => {
  //   dummy.current.scrollIntoView({ /*behavior: 'smooth'*/ });
  // }, [messages])

  useEffect(() => {
    setTimeout(function () {
      setLoaded(true);
    }, 3000);
  }, [loading]);

  useEffect(() => {
    if (loaded) {
      var x = document.getElementById("notif");
      x.style.display = "inline";
      var x2 = document.getElementById("notif2");
      x2.style.display = "inline";
    }
  }, [gamesMsg]);

  useEffect(() => {
    if (genLoaded) {
      var x = document.getElementById("genNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("genNotif2");
      x2.style.display = "inline";
    }
  }, [genMsg]);

  useEffect(() => {
    setTimeout(function () {
      setGenLoaded(true);
    }, 3000);
  }, [genLoading]);


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>

      <main className="chatMain">
        <ScrollContainer>
          {messages && messages.map(msg => <SelfPromoChatMessage key={msg.id} message={msg} onReply={replyToMessage} />)}

          <div className='message sent-disp'>
            <div id="container">
              <div id="menu-wrap">
                <input type="checkbox" class="toggler" />
                <div class="dots">
                  <div></div>
                </div>
                <div class="menu">
                </div>
              </div>
            </div>
            <img className="pfp" src={photoURL} />
            <p className="dispName">{"Cool Cat"}</p>
            <div className="msg" id="sent-disp-msg"><Markdown remarkPlugins={[remarkGfm]}>{"."}</Markdown></div>

          </div>

          {/* <div ref={dummy}></div> */}
        </ScrollContainer>
      </main>

      <form autocomplete="off" className="send-form" id="myForm" value="" onSubmit={sendMessage}>
        {replyUser && replyUser !== "" && <label className="reply-block"><button className="clearReply" onClick={clearReply}>x</button>  Replying to <span>{replyUser}</span>: {replyMsg} {replyImg}</label>}
        <div className="send">
          <label className="custom-file-upload"><input id="filebutton" type="file" name="uploadfile" onChange={(e) => setFile(e.target.files[0])} />🖼️</label>
          <TextareaAutosize id="messageform" value={formValue} type="text" onChange={(e) => setFormValue(e.target.value)} cacheMeasurements onHeightChange={(height) => { document.getElementsByClassName("send").height = height }} onKeyDown={(e) => {
            if (e.keyCode === 13 && e.shiftKey === false) {
              e.preventDefault();

              $(".submit").click();
            }
          }} />        <button className="submit" type=" submit" name="upload">Send</button>
        </div>
      </form >

    </>
  )

}



function ShowcaseChatRoom() {

  const dummy = useRef()
  const messagesRef = firestore.collection('showcase');
  const query = messagesRef.orderBy('createdAt').limitToLast(100);
  const gamesRef = firestore.collection('gamechat');
  const gamesQuery = gamesRef.orderBy('createdAt').limitToLast(100);

  const [gamesMsg, loading] = useCollectionData(gamesQuery);
  const [loaded, setLoaded] = useState(false);

  const genRef = firestore.collection('messages');
  const genQuery = genRef.orderBy('createdAt').limitToLast(100);

  const [genMsg, genLoading] = useCollectionData(genQuery);
  const [genLoaded, setGenLoaded] = useState(false);

  const promoRef = firestore.collection('promo');
  const promoQuery = promoRef.orderBy('createdAt').limitToLast(100);

  const [promoMsg, promoLoading] = useCollectionData(promoQuery);
  const [promoLoaded, setPromoLoaded] = useState(false);

  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  const [file, setFile] = useState(null);
  var fileName = null;
  $(".submit").prop("disabled", false);
  const [replyUser, setReplyUser] = useState("");
  const [replyMsg, setReplyMsg] = useState("");
  const [replyImg, setReplyImg] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    if (formValue.trim() !== '' || file !== null) {
      let msgVal = formValue;
      setFormValue('');
      await messageSending(msgVal, messagesRef, fileName, file, "showcase", replyUser, replyMsg, replyImg);
      $(".sent-disp").css("display", "none");
      setFile(null);
      $(".submit").prop("disabled", false);
      setReplyUser("");
      setReplyMsg("");
      setReplyImg("");
    }
  }

  const replyToMessage = (userName, msg, img) => {
    setReplyUser(userName);
    setReplyMsg(msg);
    if (img !== "") {
      setReplyImg("Image 🖼️");
    }
  }

  const clearReply = () => {
    setReplyUser("");
    setReplyMsg("");
    setReplyImg("");
  }

  const { uid, photoURL, displayName } = auth.currentUser;

  // useEffect(() => {
  //   dummy.current.scrollIntoView({ /*behavior: 'smooth'*/ });
  // }, [messages])

  useEffect(() => {
    setTimeout(function () {
      setLoaded(true);
    }, 3000);
  }, [loading]);

  useEffect(() => {
    if (loaded) {
      var x = document.getElementById("notif");
      x.style.display = "inline";
      var x2 = document.getElementById("notif2");
      x2.style.display = "inline";
    }
  }, [gamesMsg]);

  useEffect(() => {
    if (genLoaded) {
      var x = document.getElementById("genNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("genNotif2");
      x2.style.display = "inline";
    }
  }, [genMsg]);

  useEffect(() => {
    setTimeout(function () {
      setGenLoaded(true);
    }, 3000);
  }, [genLoading]);

  useEffect(() => {
    if (promoLoaded) {
      var x = document.getElementById("promoNotif");
      x.style.display = "inline";
      var x2 = document.getElementById("promoNotif2");
      x2.style.display = "inline";
    }
  }, [promoMsg]);

  useEffect(() => {
    setTimeout(function () {
      setPromoLoaded(true);
    }, 3000);
  }, [promoLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>

      <main className="chatMain">
        <ScrollContainer>
          {messages && messages.map(msg => <ShowcaseChatMessage key={msg.id} message={msg} onReply={replyToMessage} />)}

          <div className='message sent-disp'>
            <div id="container">
              <div id="menu-wrap">
                <input type="checkbox" class="toggler" />
                <div class="dots">
                  <div></div>
                </div>
                <div class="menu">
                </div>
              </div>
            </div>
            <img className="pfp" src={photoURL} />
            <p className="dispName">{"Cool Cat"}</p>
            <div className="msg" id="sent-disp-msg"><Markdown remarkPlugins={[remarkGfm]}>{"."}</Markdown></div>

          </div>

          {/* <div ref={dummy}></div> */}
        </ScrollContainer>
      </main>

      <form autocomplete="off" className="send-form" id="myForm" value="" onSubmit={sendMessage}>
        {replyUser && replyUser !== "" && <label className="reply-block"><button className="clearReply" onClick={clearReply}>x</button>  Replying to <span>{replyUser}</span>: {replyMsg} {replyImg}</label>}
        <div className="send">
          <label className="custom-file-upload"><input id="filebutton" type="file" name="uploadfile" onChange={(e) => setFile(e.target.files[0])} />🖼️</label>
          <TextareaAutosize id="messageform" value={formValue} type="text" onChange={(e) => setFormValue(e.target.value)} cacheMeasurements onHeightChange={(height) => { document.getElementsByClassName("send").height = height }} onKeyDown={(e) => {
            if (e.keyCode === 13 && e.shiftKey === false) {
              e.preventDefault();

              $(".submit").click();
            }
          }} />        <button className="submit" type=" submit" name="upload">Send</button>
        </div>
      </form >

    </>
  )

}

function ChatMessage(props) {

  const { idVal, text, uid, photoURL, displayName, image, color, famTier, friendTier, comTier, replyUser, replyImg, replyText } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  // console.log(auth.currentUser.uid)
  // docSnap = usersRef.where("uid", "==", cur_uid).where("role", "==", "admin").get();
  const deleteMessage = async (e) => {
    // setVisible(false)
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();
    // usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get()
    // console.log(docSnap);
    // console.log(docSnap.empty);
    if (!docSnap.empty) {
      deleteDoc(doc(firestore, "messages", props.message.idVal))
    }
    else if (auth.currentUser.uid === props.message.uid) {
      deleteDoc(doc(firestore, "messages", props.message.idVal))
    }
    $(".toggler").prop("checked", false);
  }

  const banUser = async (e) => {
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();    // console.log(docSnap);
    const messageDocSnap = await usersRef.where("uid", "==", props.message.uid).where("role", "==", "admin").get();
    if (!docSnap.empty && messageDocSnap.empty) {
      let banCheck = window.confirm("Are you sure you want to ban " + displayName + "? (Note, if you selected chopi, this will ban whoever sent the command that triggered Chopi's response)");
      if (banCheck) {
        const bannedRef = await firestore.collection('banned');
        const res = await bannedRef.add({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid
        });

        res.set({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid,
          idVal: res.id
        })
        console.log("Banned " + props.message.dispName);
        window.alert("You banned " + displayName);
      }
    } else {
      let banCheck = window.confirm("Are you sure you want to report " + displayName + "?");
      if (banCheck) {
        await octokit.request('POST /repos/{owner}/{repo}/issues', {
          owner: 'SuperViolinsADS',
          repo: 'collectorscorner.github.io',
          title: "Reporting " + displayName + " - " + auth.currentUser.uid,
          body: "",
        })
      }
    }

  }

  return (
    <div>
      <div className='message_block'>
        {replyUser && replyUser !== "" && <div className="reply-vis">Replied to <span>{replyUser}</span>: {replyText} {replyImg}</div>}
        <div className={`message &{messageClass}`}>
          <div id="container">
            <div id="menu-wrap">
              <input type="checkbox" class="toggler" />
              <div class="dots">
                <div></div>
              </div>
              <div class="menu">
                <div>
                  <ul>
                    <li><a className="link" onClick={deleteMessage}>Delete</a>
                    </li>
                    <li><a className="link" onClick={banUser}>Report</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <img className="pfp" src={photoURL} />
          <p className="dispName" style={{ color: color }}>{displayName} {uid === "qFCYD5KhCLU2BwbRKSJEjpBW7uA3" && <img src="https://collectingcommunity.com/image/badges/CCBadge.png" className="badge" />}
            {uid === "qeMvZWJABSf2YvH3yxyLxg1moCs2" && <img src="https://collectingcommunity.com/image/badges/ViolinsBadge.png" className="badge" />}
            {uid === "qJ1w9o2z3RXaWMYoN5r0LTylgeR2" && <img src="https://collectingcommunity.com/image/badges/SavinBadge.png" className="badge" />}
            {uid === "fdPmJURqfRN3jBj5OFKlcAp1p083" && <img src="https://collectingcommunity.com/image/badges/WendyBadge.png" className="badge" />}
            {(famTier || comTier) && <img src="https://collectingcommunity.com/image/badges/MemberBadge.png" className="badge" />}</p>
          <div className="msg"><Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown></div>
          {image !== "" && <img src={image} className="chatImg" />}

          <div className="reply-menu" onClick={() => { props.onReply(displayName, text, image) }}><span>&#8617;</span></div>

        </div>
      </div>
    </div>
  );



}

function CountChatMessage(props) {

  const { idVal, text, uid, photoURL, displayName, image, color, famTier, friendTier, comTier, replyUser, replyImg, replyText } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  // console.log(auth.currentUser.uid)
  // docSnap = usersRef.where("uid", "==", cur_uid).where("role", "==", "admin").get();
  const deleteMessage = async (e) => {
    // setVisible(false)
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();
    // usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get()
    // console.log(docSnap);
    // console.log(docSnap.empty);
    if (!docSnap.empty) {
      deleteDoc(doc(firestore, "countchat", props.message.idVal))
    }
    else if (auth.currentUser.uid === props.message.uid) {
      deleteDoc(doc(firestore, "countchat", props.message.idVal))
    }
    $(".toggler").prop("checked", false);
  }

  const banUser = async (e) => {
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();    // console.log(docSnap);
    const messageDocSnap = await usersRef.where("uid", "==", props.message.uid).where("role", "==", "admin").get();
    if (!docSnap.empty && messageDocSnap.empty) {
      let banCheck = window.confirm("Are you sure you want to ban " + displayName + "? (Note, if you selected chopi, this will ban whoever sent the command that triggered Chopi's response)");
      if (banCheck) {
        const bannedRef = await firestore.collection('banned');
        const res = await bannedRef.add({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid
        });

        res.set({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid,
          idVal: res.id
        })
        console.log("Banned " + props.message.dispName);
        window.alert("You banned " + displayName);
      }
    }
  }

  return (
    <div>
      <div className='message_block'>
        {replyUser && replyUser !== "" && <div className="reply-vis">Replied to <span>{replyUser}</span>: {replyText} {replyImg}</div>}
        <div className={`message &{messageClass}`}><div id="container">
          <div id="menu-wrap">
            <input type="checkbox" class="toggler" />
            <div class="dots">
              <div></div>
            </div>
            <div class="menu">
              <div>
                <ul>
                  <li><a className="link" onClick={deleteMessage}>Delete</a>
                  </li>
                  <li><a className="link" onClick={banUser}>Report</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

          <img className="pfp" src={photoURL} />
          <p className="dispName" style={{ color: color }}>{displayName} {uid === "qFCYD5KhCLU2BwbRKSJEjpBW7uA3" && <img src="https://collectingcommunity.com/image/badges/CCBadge.png" className="badge" />}
            {uid === "qeMvZWJABSf2YvH3yxyLxg1moCs2" && <img src="https://collectingcommunity.com/image/badges/ViolinsBadge.png" className="badge" />}
            {uid === "qJ1w9o2z3RXaWMYoN5r0LTylgeR2" && <img src="https://collectingcommunity.com/image/badges/SavinBadge.png" className="badge" />}
            {uid === "fdPmJURqfRN3jBj5OFKlcAp1p083" && <img src="https://collectingcommunity.com/image/badges/WendyBadge.png" className="badge" />}
            {(famTier || comTier) && <img src="https://collectingcommunity.com/image/badges/MemberBadge.png" className="badge" />}</p>

          <div className="msg"><Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown></div>
          {image !== "" && <img src={image} className="chatImg" />}
          <div className="reply-menu" onClick={() => { props.onReply(displayName, text, image) }}><span>&#8617;</span></div>

        </div>
      </div>
    </div>
  );
}

function LiveChatMessage(props) {

  const { idVal, text, uid, photoURL, displayName, image, color, famTier, friendTier, comTier, replyUser, replyImg, replyText } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  // console.log(auth.currentUser.uid)
  // docSnap = usersRef.where("uid", "==", cur_uid).where("role", "==", "admin").get();
  const deleteMessage = async (e) => {
    // setVisible(false)
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();
    // usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get()
    // console.log(docSnap);
    // console.log(docSnap.empty);
    if (!docSnap.empty) {
      deleteDoc(doc(firestore, "livechat", props.message.idVal))
    }
    else if (auth.currentUser.uid === props.message.uid) {
      deleteDoc(doc(firestore, "livechat", props.message.idVal))
    }
    $(".toggler").prop("checked", false);
  }

  const banUser = async (e) => {
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();    // console.log(docSnap);
    const messageDocSnap = await usersRef.where("uid", "==", props.message.uid).where("role", "==", "admin").get();
    if (!docSnap.empty && messageDocSnap.empty) {
      let banCheck = window.confirm("Are you sure you want to ban " + displayName + "? (Note, if you selected chopi, this will ban whoever sent the command that triggered Chopi's response)");
      if (banCheck) {
        const bannedRef = await firestore.collection('banned');
        const res = await bannedRef.add({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid
        });

        res.set({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid,
          idVal: res.id
        })
        console.log("Banned " + props.message.dispName);
        window.alert("You banned " + displayName);
      }
    }
  }

  return (
    <div>
      <div className='message_block'>
        {replyUser && replyUser !== "" && <div className="reply-vis">Replied to <span>{replyUser}</span>: {replyText} {replyImg}</div>}
        <div className={`message &{messageClass}`}><div id="container">
          <div id="menu-wrap">
            <input type="checkbox" class="toggler" />
            <div class="dots">
              <div></div>
            </div>
            <div class="menu">
              <div>
                <ul>
                  <li><a className="link" onClick={deleteMessage}>Delete</a>
                  </li>
                  <li><a className="link" onClick={banUser}>Report</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

          <img className="pfp" src={photoURL} />
          <p className="dispName" style={{ color: color }}>{displayName} {uid === "qFCYD5KhCLU2BwbRKSJEjpBW7uA3" && <img src="https://collectingcommunity.com/image/badges/CCBadge.png" className="badge" />}
            {uid === "qeMvZWJABSf2YvH3yxyLxg1moCs2" && <img src="https://collectingcommunity.com/image/badges/ViolinsBadge.png" className="badge" />}
            {uid === "qJ1w9o2z3RXaWMYoN5r0LTylgeR2" && <img src="https://collectingcommunity.com/image/badges/SavinBadge.png" className="badge" />}
            {uid === "fdPmJURqfRN3jBj5OFKlcAp1p083" && <img src="https://collectingcommunity.com/image/badges/WendyBadge.png" className="badge" />}
            {(famTier || comTier) && <img src="https://collectingcommunity.com/image/badges/MemberBadge.png" className="badge" />}</p>

          <div className="msg"><Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown></div>
          {image !== "" && <img src={image} className="chatImg" />}
          <div className="reply-menu" onClick={() => { props.onReply(displayName, text, image) }}><span>&#8617;</span></div>

        </div>
      </div>
    </div>
  );
}

function GiveawayChatMessage(props) {

  const { idVal, text, uid, photoURL, displayName, image, color, famTier, friendTier, comTier, replyUser, replyImg, replyText } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  // console.log(auth.currentUser.uid)
  // docSnap = usersRef.where("uid", "==", cur_uid).where("role", "==", "admin").get();
  const deleteMessage = async (e) => {
    // setVisible(false)
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();
    // usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get()
    // console.log(docSnap);
    // console.log(docSnap.empty);
    if (!docSnap.empty) {
      deleteDoc(doc(firestore, "giveawaychat", props.message.idVal))
    }
    else if (auth.currentUser.uid === props.message.uid) {
      deleteDoc(doc(firestore, "giveawaychat", props.message.idVal))
    }
    $(".toggler").prop("checked", false);
  }

  const banUser = async (e) => {
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();    // console.log(docSnap);
    const messageDocSnap = await usersRef.where("uid", "==", props.message.uid).where("role", "==", "admin").get();
    if (!docSnap.empty && messageDocSnap.empty) {
      let banCheck = window.confirm("Are you sure you want to ban " + displayName + "? (Note, if you selected chopi, this will ban whoever sent the command that triggered Chopi's response)");
      if (banCheck) {
        const bannedRef = await firestore.collection('banned');
        const res = await bannedRef.add({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid
        });

        res.set({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid,
          idVal: res.id
        })
        console.log("Banned " + props.message.dispName);
        window.alert("You banned " + displayName);
      }
    }
  }

  return (
    <div>
      <div className='message_block'>
        {replyUser && replyUser !== "" && <div className="reply-vis">Replied to <span>{replyUser}</span>: {replyText} {replyImg}</div>}
        <div className={`message &{messageClass}`}><div id="container">
          <div id="menu-wrap">
            <input type="checkbox" class="toggler" />
            <div class="dots">
              <div></div>
            </div>
            <div class="menu">
              <div>
                <ul>
                  <li><a className="link" onClick={deleteMessage}>Delete</a>
                  </li>
                  <li><a className="link" onClick={banUser}>Report</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

          <img className="pfp" src={photoURL} />
          <p className="dispName" style={{ color: color }}>{displayName} {uid === "qFCYD5KhCLU2BwbRKSJEjpBW7uA3" && <img src="https://collectingcommunity.com/image/badges/CCBadge.png" className="badge" />}
            {uid === "qeMvZWJABSf2YvH3yxyLxg1moCs2" && <img src="https://collectingcommunity.com/image/badges/ViolinsBadge.png" className="badge" />}
            {uid === "qJ1w9o2z3RXaWMYoN5r0LTylgeR2" && <img src="https://collectingcommunity.com/image/badges/SavinBadge.png" className="badge" />}
            {uid === "fdPmJURqfRN3jBj5OFKlcAp1p083" && <img src="https://collectingcommunity.com/image/badges/WendyBadge.png" className="badge" />}
            {(famTier || comTier) && <img src="https://collectingcommunity.com/image/badges/MemberBadge.png" className="badge" />}</p>

          <div className="msg"><Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown></div>
          {image !== "" && <img src={image} className="chatImg" />}
          <div className="reply-menu" onClick={() => { props.onReply(displayName, text, image) }}><span>&#8617;</span></div>

        </div>
      </div>
    </div>
  );



}

function AnnouncementChatMessage(props) {

  const { idVal, text, uid, photoURL, displayName, image, color, famTier, friendTier, comTier, replyUser, replyImg, replyText } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  // console.log(auth.currentUser.uid)
  // docSnap = usersRef.where("uid", "==", cur_uid).where("role", "==", "admin").get();
  const deleteMessage = async (e) => {
    // setVisible(false)
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();
    // usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get()
    // console.log(docSnap);
    // console.log(docSnap.empty);
    if (!docSnap.empty) {
      deleteDoc(doc(firestore, "announcement", props.message.idVal))
    }
    else if (auth.currentUser.uid === props.message.uid) {
      deleteDoc(doc(firestore, "announcement", props.message.idVal))
    }
    $(".toggler").prop("checked", false);
  }

  const banUser = async (e) => {
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();    // console.log(docSnap);
    const messageDocSnap = await usersRef.where("uid", "==", props.message.uid).where("role", "==", "admin").get();
    if (!docSnap.empty && messageDocSnap.empty) {
      let banCheck = window.confirm("Are you sure you want to ban " + displayName + "? (Note, if you selected chopi, this will ban whoever sent the command that triggered Chopi's response)");
      if (banCheck) {
        const bannedRef = await firestore.collection('banned');
        const res = await bannedRef.add({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid
        });

        res.set({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid,
          idVal: res.id
        })
        console.log("Banned " + props.message.dispName);
        window.alert("You banned " + displayName);
      }
    }
  }

  return (
    <div>
      <div className='message_block'>
        {replyUser && replyUser !== "" && <div className="reply-vis">Replied to <span>{replyUser}</span>: {replyText} {replyImg}</div>}
        <div className={`message &{messageClass}`}><div id="container">
          <div id="menu-wrap">
            <input type="checkbox" class="toggler" />
            <div class="dots">
              <div></div>
            </div>
            <div class="menu">
              <div>
                <ul>
                  <li><a className="link" onClick={deleteMessage}>Delete</a>
                  </li>
                  <li><a className="link" onClick={banUser}>Report</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

          <img className="pfp" src={photoURL} />
          <p className="dispName" style={{ color: color }}>{displayName} {uid === "qFCYD5KhCLU2BwbRKSJEjpBW7uA3" && <img src="https://collectingcommunity.com/image/badges/CCBadge.png" className="badge" />}
            {uid === "qeMvZWJABSf2YvH3yxyLxg1moCs2" && <img src="https://collectingcommunity.com/image/badges/ViolinsBadge.png" className="badge" />}
            {uid === "qJ1w9o2z3RXaWMYoN5r0LTylgeR2" && <img src="https://collectingcommunity.com/image/badges/SavinBadge.png" className="badge" />}
            {uid === "fdPmJURqfRN3jBj5OFKlcAp1p083" && <img src="https://collectingcommunity.com/image/badges/WendyBadge.png" className="badge" />}
            {(famTier || comTier) && <img src="https://collectingcommunity.com/image/badges/MemberBadge.png" className="badge" />}</p>

          <div className="msg"><Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown></div>
          {image !== "" && <img src={image} className="chatImg" />}
          <div className="reply-menu" onClick={() => { props.onReply(displayName, text, image) }}><span>&#8617;</span></div>

        </div>
      </div>
    </div>
  );



}

function MailChatMessage(props) {

  const { idVal, text, uid, photoURL, displayName, image, color, famTier, friendTier, comTier, replyUser, replyImg, replyText } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  // console.log(auth.currentUser.uid)
  // docSnap = usersRef.where("uid", "==", cur_uid).where("role", "==", "admin").get();
  const deleteMessage = async (e) => {
    // setVisible(false)
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();
    // usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get()
    // console.log(docSnap);
    // console.log(docSnap.empty);
    if (!docSnap.empty) {
      deleteDoc(doc(firestore, "mail", props.message.idVal))
    }
    else if (auth.currentUser.uid === props.message.uid) {
      deleteDoc(doc(firestore, "mail", props.message.idVal))
    }
    $(".toggler").prop("checked", false);
  }

  const banUser = async (e) => {
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();    // console.log(docSnap);
    const messageDocSnap = await usersRef.where("uid", "==", props.message.uid).where("role", "==", "admin").get();
    if (!docSnap.empty && messageDocSnap.empty) {
      let banCheck = window.confirm("Are you sure you want to ban " + displayName + "? (Note, if you selected chopi, this will ban whoever sent the command that triggered Chopi's response)");
      if (banCheck) {
        const bannedRef = await firestore.collection('banned');
        const res = await bannedRef.add({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid
        });

        res.set({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid,
          idVal: res.id
        })
        console.log("Banned " + props.message.dispName);
        window.alert("You banned " + displayName);
      }
    }
  }

  return (
    <div>
      <div className='message_block'>
        {replyUser && replyUser !== "" && <div className="reply-vis">Replied to <span>{replyUser}</span>: {replyText} {replyImg}</div>}
        <div className={`message &{messageClass}`}><div id="container">
          <div id="menu-wrap">
            <input type="checkbox" class="toggler" />
            <div class="dots">
              <div></div>
            </div>
            <div class="menu">
              <div>
                <ul>
                  <li><a className="link" onClick={deleteMessage}>Delete</a>
                  </li>
                  <li><a className="link" onClick={banUser}>Report</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

          <img className="pfp" src={photoURL} />
          <p className="dispName" style={{ color: color }}>{displayName} {uid === "qFCYD5KhCLU2BwbRKSJEjpBW7uA3" && <img src="https://collectingcommunity.com/image/badges/CCBadge.png" className="badge" />}
            {uid === "qeMvZWJABSf2YvH3yxyLxg1moCs2" && <img src="https://collectingcommunity.com/image/badges/ViolinsBadge.png" className="badge" />}
            {uid === "qJ1w9o2z3RXaWMYoN5r0LTylgeR2" && <img src="https://collectingcommunity.com/image/badges/SavinBadge.png" className="badge" />}
            {uid === "fdPmJURqfRN3jBj5OFKlcAp1p083" && <img src="https://collectingcommunity.com/image/badges/WendyBadge.png" className="badge" />}
            {(famTier || comTier) && <img src="https://collectingcommunity.com/image/badges/MemberBadge.png" className="badge" />}</p>

          <div className="msg"><Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown></div>
          {image !== "" && <img src={image} className="chatImg" />}
          <div className="reply-menu" onClick={() => { props.onReply(displayName, text, image) }}><span>&#8617;</span></div>

        </div>
      </div>
    </div>
  );



}

function BattleChatMessage(props) {

  const { idVal, text, uid, photoURL, displayName, image, color, famTier, friendTier, comTier, replyUser, replyImg, replyText } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  // console.log(auth.currentUser.uid)
  // docSnap = usersRef.where("uid", "==", cur_uid).where("role", "==", "admin").get();
  const deleteMessage = async (e) => {
    // setVisible(false)
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();
    // usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get()
    // console.log(docSnap);
    // console.log(docSnap.empty);
    if (!docSnap.empty) {
      deleteDoc(doc(firestore, "battlechat", props.message.idVal))
    }
    else if (auth.currentUser.uid === props.message.uid) {
      deleteDoc(doc(firestore, "battlechat", props.message.idVal))
    }
    $(".toggler").prop("checked", false);
  }

  const banUser = async (e) => {
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();    // console.log(docSnap);
    const messageDocSnap = await usersRef.where("uid", "==", props.message.uid).where("role", "==", "admin").get();
    if (!docSnap.empty && messageDocSnap.empty) {
      let banCheck = window.confirm("Are you sure you want to ban " + displayName + "? (Note, if you selected chopi, this will ban whoever sent the command that triggered Chopi's response)");
      if (banCheck) {
        const bannedRef = await firestore.collection('banned');
        const res = await bannedRef.add({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid
        });

        res.set({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid,
          idVal: res.id
        })
        console.log("Banned " + props.message.dispName);
        window.alert("You banned " + displayName);
      }
    }
  }

  return (
    <div>
      <div className='message_block'>
        {replyUser && replyUser !== "" && <div className="reply-vis">Replied to <span>{replyUser}</span>: {replyText} {replyImg}</div>}
        <div className={`message &{messageClass}`}><div id="container">
          <div id="menu-wrap">
            <input type="checkbox" class="toggler" />
            <div class="dots">
              <div></div>
            </div>
            <div class="menu">
              <div>
                <ul>
                  <li><a className="link" onClick={deleteMessage}>Delete</a>
                  </li>
                  <li><a className="link" onClick={banUser}>Report</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

          <img className="pfp" src={photoURL} />
          <p className="dispName" style={{ color: color }}>{displayName} {uid === "qFCYD5KhCLU2BwbRKSJEjpBW7uA3" && <img src="https://collectingcommunity.com/image/badges/CCBadge.png" className="badge" />}
            {uid === "qeMvZWJABSf2YvH3yxyLxg1moCs2" && <img src="https://collectingcommunity.com/image/badges/ViolinsBadge.png" className="badge" />}
            {uid === "qJ1w9o2z3RXaWMYoN5r0LTylgeR2" && <img src="https://collectingcommunity.com/image/badges/SavinBadge.png" className="badge" />}
            {uid === "fdPmJURqfRN3jBj5OFKlcAp1p083" && <img src="https://collectingcommunity.com/image/badges/WendyBadge.png" className="badge" />}
            {(famTier || comTier) && <img src="https://collectingcommunity.com/image/badges/MemberBadge.png" className="badge" />}</p>

          <div className="msg"><Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown></div>
          {image !== "" && <img src={image} className="chatImg" />}
          <div className="reply-menu" onClick={() => { props.onReply(displayName, text, image) }}><span>&#8617;</span></div>

        </div>
      </div>
    </div>
  );



}

function GameChatMessage(props) {

  const { idVal, text, uid, photoURL, displayName, image, color, badge, famTier, friendTier, comTier, replyUser, replyImg, replyText } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  // console.log(auth.currentUser.uid)
  // docSnap = usersRef.where("uid", "==", cur_uid).where("role", "==", "admin").get();
  const deleteMessage = async (e) => {
    // setVisible(false)
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();
    // usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get()
    // console.log(docSnap);
    // console.log(docSnap.empty);
    if (!docSnap.empty) {
      deleteDoc(doc(firestore, "gamechat", props.message.idVal))
    }
    else if (auth.currentUser.uid === props.message.uid) {
      deleteDoc(doc(firestore, "gamechat", props.message.idVal))
    }
    $(".toggler").prop("checked", false);
  }

  const banUser = async (e) => {
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();    // console.log(docSnap);
    const messageDocSnap = await usersRef.where("uid", "==", props.message.uid).where("role", "==", "admin").get();
    if (!docSnap.empty && messageDocSnap.empty) {
      let banCheck = window.confirm("Are you sure you want to ban " + displayName + "? (Note, if you selected chopi, this will ban whoever sent the command that triggered Chopi's response)");
      if (banCheck) {
        const bannedRef = await firestore.collection('banned');
        const res = await bannedRef.add({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid
        });

        res.set({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid,
          idVal: res.id
        })
        console.log("Banned " + props.message.dispName);
        window.alert("You banned " + displayName);
      }
    }
  }

  return (
    <div>
      <div className='message_block'>
        {replyUser && replyUser !== "" && <div className="reply-vis">Replied to <span>{replyUser}</span>: {replyText} {replyImg}</div>}
        <div className={`message &{messageClass}`}><div id="container">
          <div id="menu-wrap">
            <input type="checkbox" class="toggler" />
            <div class="dots">
              <div></div>
            </div>
            <div class="menu">
              <div>
                <ul>
                  <li><a className="link" onClick={deleteMessage}>Delete</a>
                  </li>
                  <li><a className="link" onClick={banUser}>Report</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

          <img className="pfp" src={photoURL} />
          <p className="dispName" style={{ color: color }}>{displayName} {uid === "qFCYD5KhCLU2BwbRKSJEjpBW7uA3" && <img src="https://collectingcommunity.com/image/badges/CCBadge.png" className="badge" />}
            {uid === "qeMvZWJABSf2YvH3yxyLxg1moCs2" && <img src="https://collectingcommunity.com/image/badges/ViolinsBadge.png" className="badge" />}
            {uid === "qJ1w9o2z3RXaWMYoN5r0LTylgeR2" && <img src="https://collectingcommunity.com/image/badges/SavinBadge.png" className="badge" />}
            {uid === "fdPmJURqfRN3jBj5OFKlcAp1p083" && <img src="https://collectingcommunity.com/image/badges/WendyBadge.png" className="badge" />}
            {(famTier || comTier) && <img src="https://collectingcommunity.com/image/badges/MemberBadge.png" className="badge" />}
            {<img src={badge} className="badge" />}</p>

          <div className="msg"><Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown></div>
          {image !== "" && <img src={image} className="chatImg" />}
          <div className="reply-menu" onClick={() => { props.onReply(displayName, text, image) }}><span>&#8617;</span></div>

        </div>
      </div>
    </div>
  );

}

function SelfPromoChatMessage(props) {

  const { idVal, text, uid, photoURL, displayName, image, color, famTier, friendTier, comTier, replyUser, replyImg, replyText } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  // console.log(auth.currentUser.uid)
  // docSnap = usersRef.where("uid", "==", cur_uid).where("role", "==", "admin").get();
  const deleteMessage = async (e) => {
    // setVisible(false)
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();
    // usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get()
    // console.log(docSnap);
    // console.log(docSnap.empty);
    if (!docSnap.empty) {
      deleteDoc(doc(firestore, "promo", props.message.idVal))
    }
    else if (auth.currentUser.uid === props.message.uid) {
      deleteDoc(doc(firestore, "promo", props.message.idVal))
    }
    $(".toggler").prop("checked", false);
  }

  const banUser = async (e) => {
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();    // console.log(docSnap);
    const messageDocSnap = await usersRef.where("uid", "==", props.message.uid).where("role", "==", "admin").get();
    if (!docSnap.empty && messageDocSnap.empty) {
      let banCheck = window.confirm("Are you sure you want to ban " + displayName + "? (Note, if you selected chopi, this will ban whoever sent the command that triggered Chopi's response)");
      if (banCheck) {
        const bannedRef = await firestore.collection('banned');
        const res = await bannedRef.add({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid
        });

        res.set({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid,
          idVal: res.id
        })
        console.log("Banned " + props.message.dispName);
        window.alert("You banned " + displayName);
      }
    }
  }

  return (
    <div>
      <div className='message_block'>
        {replyUser && replyUser !== "" && <div className="reply-vis">Replied to <span>{replyUser}</span>: {replyText} {replyImg}</div>}
        <div className={`message &{messageClass}`}><div id="container">
          <div id="menu-wrap">
            <input type="checkbox" class="toggler" />
            <div class="dots">
              <div></div>
            </div>
            <div class="menu">
              <div>
                <ul>
                  <li><a className="link" onClick={deleteMessage}>Delete</a>
                  </li>
                  <li><a className="link" onClick={banUser}>Report</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

          <img className="pfp" src={photoURL} />
          <p className="dispName" style={{ color: color }}>{displayName} {uid === "qFCYD5KhCLU2BwbRKSJEjpBW7uA3" && <img src="https://collectingcommunity.com/image/badges/CCBadge.png" className="badge" />}
            {uid === "qeMvZWJABSf2YvH3yxyLxg1moCs2" && <img src="https://collectingcommunity.com/image/badges/ViolinsBadge.png" className="badge" />}
            {uid === "qJ1w9o2z3RXaWMYoN5r0LTylgeR2" && <img src="https://collectingcommunity.com/image/badges/SavinBadge.png" className="badge" />}
            {uid === "fdPmJURqfRN3jBj5OFKlcAp1p083" && <img src="https://collectingcommunity.com/image/badges/WendyBadge.png" className="badge" />}
            {(famTier || comTier) && <img src="https://collectingcommunity.com/image/badges/MemberBadge.png" className="badge" />}</p>

          <div className="msg"><Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown></div>
          {image !== "" && <img src={image} className="chatImg" />}
          <div className="reply-menu" onClick={() => { props.onReply(displayName, text, image) }}><span>&#8617;</span></div>

        </div>
      </div>
    </div>
  );
}

function ShowcaseChatMessage(props) {

  const { idVal, text, uid, photoURL, displayName, image, color, famTier, friendTier, comTier, replyUser, replyImg, replyText } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  // console.log(auth.currentUser.uid)
  // docSnap = usersRef.where("uid", "==", cur_uid).where("role", "==", "admin").get();
  const deleteMessage = async (e) => {
    // setVisible(false)
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();
    // usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get()
    // console.log(docSnap);
    // console.log(docSnap.empty);
    if (!docSnap.empty) {
      deleteDoc(doc(firestore, "showcase", props.message.idVal))
    }
    else if (auth.currentUser.uid === props.message.uid) {
      deleteDoc(doc(firestore, "showcase", props.message.idVal))
    }
    $(".toggler").prop("checked", false);
  }

  const banUser = async (e) => {
    const usersRef = await firestore.collection('users');
    const docSnap = await usersRef.where("uid", "==", auth.currentUser.uid).where("role", "==", "admin").get();    // console.log(docSnap);
    const messageDocSnap = await usersRef.where("uid", "==", props.message.uid).where("role", "==", "admin").get();
    if (!docSnap.empty && messageDocSnap.empty) {
      let banCheck = window.confirm("Are you sure you want to ban " + displayName + "? (Note, if you selected chopi, this will ban whoever sent the command that triggered Chopi's response)");
      if (banCheck) {
        const bannedRef = await firestore.collection('banned');
        const res = await bannedRef.add({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid
        });

        res.set({
          bannedAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: props.message.uid,
          idVal: res.id
        })
        console.log("Banned " + props.message.dispName);
        window.alert("You banned " + displayName);
      }
    }
  }

  return (
    <div>
      <div className='message_block'>
        {replyUser && replyUser !== "" && <div className="reply-vis">Replied to <span>{replyUser}</span>: {replyText} {replyImg}</div>}
        <div className={`message &{messageClass}`}><div id="container">
          <div id="menu-wrap">
            <input type="checkbox" class="toggler" />
            <div class="dots">
              <div></div>
            </div>
            <div class="menu">
              <div>
                <ul>
                  <li><a className="link" onClick={deleteMessage}>Delete</a>
                  </li>
                  <li><a className="link" onClick={banUser}>Report</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

          <img className="pfp" src={photoURL} />
          <p className="dispName" style={{ color: color }}>{displayName} {uid === "qFCYD5KhCLU2BwbRKSJEjpBW7uA3" && <img src="https://collectingcommunity.com/image/badges/CCBadge.png" className="badge" />}
            {uid === "qeMvZWJABSf2YvH3yxyLxg1moCs2" && <img src="https://collectingcommunity.com/image/badges/ViolinsBadge.png" className="badge" />}
            {uid === "qJ1w9o2z3RXaWMYoN5r0LTylgeR2" && <img src="https://collectingcommunity.com/image/badges/SavinBadge.png" className="badge" />}
            {uid === "fdPmJURqfRN3jBj5OFKlcAp1p083" && <img src="https://collectingcommunity.com/image/badges/WendyBadge.png" className="badge" />}
            {(famTier || comTier) && <img src="https://collectingcommunity.com/image/badges/MemberBadge.png" className="badge" />}</p>

          <div className="msg"><Markdown remarkPlugins={[remarkGfm]}>{text}</Markdown></div>
          {image !== "" && <img src={image} className="chatImg" />}
          <div className="reply-menu" onClick={() => { props.onReply(displayName, text, image) }}><span>&#8617;</span></div>

        </div>
      </div>
    </div>
  );



}

export default App;
