import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article2() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <h2><span style={{ fontWeight: "bold" }}>ARTICLE:</span> When everything is premium nothing is... except the price: A
            brief look at the different “premium” products released for the Pokémon TCG</h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-11">November 11, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> Savin Brass</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <p>If you have been a part of the Pokémon TCG hobby for any length of time you have no doubt heard the word
            'Premium' a dozen times the multitude of collections and ultra collections. But are you really getting a premium
            product or just paying for a premium disappointment? Today we'll look at examples of the different items bearing
            the word Premium in their name and help you decide for yourself.</p>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1173167839474106458/IMG_2688.jpg?ex=6562f8b1&is=655083b1&hm=b350f660cd7d2ddf9738c528a8fd5dc0c778b0813563254f501987d258d9ad77&"
            alt="" className="images" />
          <p>Sword and Shield Ultra Premium Collection: Charizard</p>
          <br></br>
          <p>
            Price: $100 USD</p>
          <br></br>
          <p>
            Contents:</p>
          <ul>
            <li>3 etched foil promo cards: Charizard V, Charizard VMAX, and Charizard VSTAR</li>
            <li>1 playmat featuring Gigantamax Charizard</li>
            <li>65 card sleeves featuring Gigantamax Charizard</li>
            <li>1 metal coin featuring Gigantamax Charizard</li>
            <li>6 metal damage-counter dice</li>
            <li>2 metal condition markers</li>
            <li>1 acrylic VSTAR marker</li>
            <li>16 Pokémon TCG booster packs from the Sword & Shield Series</li>
            <li>A player's guide to the entire Sword & Shield Series</li>
            <li>A code card for Pokémon TCG Live</li>

          </ul>
          <br></br>
          <p>
            The (reprints) Pack Spread: 3 Astral Radiance, 2 Lost Origin, 5 Fusion Strike, 3 Brilliant Stars, 3 Evolving
            Skies. (Previously contained Darkness Ablaze and Vivid Voltage).
          </p><br></br>
          <p>
            First Impressions: Following on the heels of the widely successful and sought after Celebrations UPC, the
            Charizard UPC was one of the most anticipated products of 2022 and for most people it was one of the most
            disappointing. The mat, the gold metal accessories, the little drawers which made the box great for storage, and
            the art of the promos were all fantastic. However in an attempt to combat scalping that plagued the celebrations
            UPC The Pokémon Company printed the charizard UPC to an insane degree that lowered even the MSRP and forced
            retailers to cut prices on it. This sounds good on the surface but the rush to print so many lead to many boxes
            having damaged, vastly off-centered, or flat out missing promos. This print quality extended to the packs with
            many cards being pulled with dings and terrible centering. And the cherry on top is the abysmal pull rates of
            the packs from the UPC with some UPCs not even having a single hit at all.
          </p><br></br>
          <p>
            Is it worth the 'Premium' price?: If you want something fun and cool to open and don't mind losing money or
            don't care about getting a PSA 10, the Charizard UPC is a nice product to open at least once. But if you want
            more bang for your buck you can buy the promos alongside with the accessories (and even the box) for far less
            than the price of the UPC itself.
          </p>
          <br></br><br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1173168146274865173/IMG_2689.jpg?ex=6562f8fa&is=655083fa&hm=2fe3fbbe662ccae0a7c84539c4753c43f4ff5efb8eac25e0d47fc1984b3bd69d&"
            alt="" className="images" />

          <p>Crown Zenith Premium Figure Collection (Shiny Zacian/Zamazenta)
          </p>
          <br></br>
          <p>Price: $60 USD</p>
          <br></br>

          <p>Contents:</p>
          <ul>
            <li>1 etched foil promo card featuring Shiny Zacian V or Shiny Zamazenta V</li>
            <li>1 sculpted figure featuring Shiny Zacian or Shiny Zamazenta</li>
            <li>1 collector's pin featuring Shiny Zacian or Shiny Zamazenta</li>
            <li>65 card sleeves featuring both Shiny Zacian and Shiny Zamazenta</li>
            <li>11 Pokémon TCG: Crown Zenith booster packs</li>
            <li>A code card for Pokémon TCG Live</li>
          </ul>
          <br></br>
          <p>
            First Impressions : This box looks good for giveaway channels and in sealed collections or a set piece for
            streamers. And who doesn't like pins and figures? There's no jumbo card which depending on where you stand is
            disappointing or a relief. And the sleeves are your usual ugly matte finish with an ugly design on the back that
            doesn't even properly show off the legendary dogs. They should have made a unique sleeve design for each dog for
            their specific box. And much like the Charizard UPC this box has been plagued with terrible pull rates.
          </p>
          <br></br>
          <p>
            Is it worth the 'Premium' price: Unless you want to keep it sealed or use it for an impressive looking giveaway,
            I can't really suggest this one. It's easy to find the pin and figure for cheap. And you can often find the
            Galarian Bird tins for $20 each which have 5 packs of Crown Zenith, a cool promo, and a very good pull rates. So
            for the same $60 you get 15 packs instead of 11, 3 promos, the tins themselves, and a decent chance at hits. So
            for me, it's a hard pass.
          </p>
          <br></br><br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1173168526928908298/IMG_2690.jpg?ex=6562f955&is=65508455&hm=3a55b8a5fe1bf67d2c59d2696f929b7bb2e641e5110d1fdc31421a2d68c220ad&"
            alt="" className="images" />
          <p>Evolving Powers Premium Collection</p>
          <br></br>
          <p>Price: $50-$60</p>

          <br></br>

          <p>Contents:</p>
          <ul>
            <li>3 foil cards featuring Magnezone ex, Magnezone V, and Magnezone VSTAR</li>
            <li>2 cards featuring Magnemite and Magneton</li>
            <li>3 foil cards featuring Gardevoir ex, Gardevoir V, and Gardevoir VMAX</li>
            <li>2 cards featuring Ralts and Kirlia</li>
            <li>7 Pokémon TCG booster packs</li>
            <li>A code card for Pokémon TCG Live</li>
          </ul>
          <p>Pack Spread: 2 Astral Radiance, 2 Paldea Evolved, 3 Obsidian Flames</p>
          <br></br>
          <p>
            First Impressions: Representing 'store exclusive' boxes we have this box featuring two of my favorite Pokémon. I
            wanted to love this but… First off making it a Target exclusive can make it hard for people without access to a
            Target and that's not fun. Secondly all the cards are the exact same ones you can get in booster packs or buy as
            singles for a buck or less, they couldn't even be bothered to flip the artwork and pretend it's 'exclusive to
            the
            box.'' All this on top of a bland selection of easily obtained and still in print packs.
          </p>
          <br></br>
          <p>
            Is it worth the 'Premium' price?: No, Nope. Nein, Nyet, and any other word that means no. These store exclusives
            boxes (another example is Amazon's arceus box) are a giant rip-off even at half the price. They don't even look
            that cool for a sealed collection. Save your money and move along.
          </p>
          <br></br><br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1173168867850326046/IMG_2691.jpg?ex=6562f9a6&is=655084a6&hm=33ed40bd5e00ebd6795a5f0d66fc6802cbd35721a26ded699c48c58ca750f44b&"
            alt="" className="images" />
          <p>151 Ultra Premium Collection (Mew)</p>
          <br></br>
          <p>
            Price: $100</p>

          <br></br>
          <p>

            Contents:</p>
          <ul>
            <li>1 etched foil promo card featuring Mew ex</li>
            <li>1 full-art foil promo card featuring Mewtwo</li>
            <li>1 etched metal card featuring Gold Mew ex</li>
            <li>1 playmat</li>
            <li>1 deck box</li>
            <li>1 metallic coin featuring Mew</li>
            <li>6 damage-counter dice</li>
            <li>2 plastic condition markers</li>
            <li>16 booster packs from Pokémon TCG: Scarlet & Violet—151</li>
            <li>A code card for Pokémon TCG Live</li>
          </ul>
          <br></br>
          <p>
            First Impressions: The newest UPC on the market and a much different item than the Charizard UPC. The promos
            feature a combination of normal promo cards with beautiful artwork and a very nice looking metal gold mew, the
            accessories are all nice though they went cheap with things like the status markers which are plastic. And
            unlike
            the the Charizard UPC which featured packs from normal sets, the 151 UPC features exclusively 151 packs as well
            as
            a deck box which the charizard UPC lacked. The UPC is plentiful but the print quality is (in my opinion) better
            for the promos and packs than what I saw in the Charizard UPC. That being said the inside the box is sparse with
            cheap plastic holding everything inside of the nice looking drawers, which kinda takes something away from the
            presentation however the way the promos are showed in a removable envelope attached to the lid is a nice touch.
            And finally the hit rares are only marginally better than the Charizard UPC but that is likely due to the
            generous
            nature of 151.
          </p><br></br>
          <p>

            Is it worth the 'Premium' price?: Much like the Charizard UPC if you are opening for fun and not profit, I
            highly
            recommend it. The box still looks cool and will look nice on a shelf, the promos are gorgeous, the accessories
            are
            nice, and 151 is a great set to open. But if you are looking to get the most packs for your money, you can do
            better. And given how plentiful they are I don't know if it will make a good long term investment.
          </p>
          <br></br><br></br>
          <img
            src="https://cdn.discordapp.com/attachments/884994368912629790/1173169153855729674/IMG_2692.jpg?ex=6562f9ea&is=655084ea&hm=63f6e0efb37816c83ea0b7cde0db3dc16319fb99a837b02420c376a3addfcfeb&"
            alt="" className="images" />
          <p>
            Charizard EX Premium Collection</p>
          <br></br>
          <p>
            Price: $30-$40</p>
          <br></br>
          <p>

            Contents:
          </p>
          <ul>
            <li>1 etched foil promo card featuring Charizard ex </li>
            <li>2 foil cards featuring Charmander and Charmeleon </li>
            <li>6 Pokemon TCG booster packs </li>
            <li>1 magnetic card protector with display base </li>
            <li>65 card sleeves featuring Charizard as a Tera Pokemon </li>
            <li>A code card for Pokemon TCG Live</li>
          </ul>
          <p>
            Pack Spread: 2 Scarlet&Violet Base, 2 Paldea Evolved, 2 Obsidian Flames</p>
          <br></br>
          <p>

            First Impressions: Now here's something you don't see in English product much, a magnetic display case which
            while
            it looks good can only hold an unsleeved card (possibly one in a perfect fit.) The sleeves are nice though still
            matte. The promo is gorgeous and indeed is different artwork from the standard charizard ex with two foil promos
            of its two previous forms with decent print quality . All with 2 packs from the first 3 sets of the scarlet and
            violet era with moderate pull rates. All for a pretty reasonable price especially if found in the $30 to $35
            range.
          </p><br></br>
          <p>
            Is it worth the 'Premium' cost?: Absolutely! Affordable, nice way to get into the SV era of cards, great looking
            promo, and I hope to see them work on display cases that can hold sleeved cards better and add them to future
            boxes. Fun to open and makes a nice display piece for sealed collectors.
          </p>
          <br></br><br></br>
          In closing: Ultimately as long as you going into buying a premium product just for the fun and enjoyment of
          opening something fancy and extravagant, you will likely find that UPCs are right up your alley. And most premium
          collection boxes are anything but premium, but once in a while you might be surprised (like the Charizard EX box
          surprised me.) And most importantly, do your own research. Read reviews, watch some videos, shop around for the
          best price, and never let fear of missing out make you rush to buy something when it first comes out.
        </section>

      </main>

    </>
  )
}

export default Article2;
