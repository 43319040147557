import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article2_com() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <h2><span style={{ fontWeight: "bold" }}>POEMS: </span>W3nderful Haikus</h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-12-08">December 8, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> W3nderful</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <img
            src="https://cdn.discordapp.com/attachments/867970381426880562/1182921286058512394/IMG_2837.webp?ex=6586744c&is=6573ff4c&hm=0ef01d1642fe41521b58763095609e67ec36068add5bdf8b3474857809f937e7&"
            alt="" className="images" />
          <h3>CC reads Poem</h3>
          <p>Watch CC read poems</p>
          <p>Go to his YouTube site now</p>
          <p>Will not disappoint</p>
          <br></br>
          <h3>Collecting Community Hype</h3>
          <p>The Best Site in Town</p>
          <p>Collecting Community</p>
          <p>Join for fun and games</p>
          <br></br>
          <h3>Shiny Cardboard</h3>
          <p>Cool Pokemon Cards</p>
          <p>Collecting since we were young</p>
          <p>Now we are all broke🤣🤣🤣</p>
          <br></br>

        </section>
      </main>

    </>
  )
}

export default Article2_com;
