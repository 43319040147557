import React from 'react'
import './events.css';
import NavBar from './NavBar'


function Events() {

  return (
    <>
      <NavBar />
      <header>
        <p><span>Pokemon TCG</span> Events!</p>
      </header>
      <main className="events">

        <section className="event">
          <h2><span style={{ fontWeight: "bold" }}>San Antonio Pokemon Regional Championships</span>
          </h2>
          <p>&#160;</p>
          <p><span style={{ fontWeight: "bold" }}>Event Date: <time>December 16, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> CC</p>
          <p>&#160;</p>

          <p> 13 Events will take place ranging from TCG to Pokemon GO, where enthusiasts of the Pokemon Trading Card Game
            and Pokemon video games can come together. Enjoy the company of fellow Pokemon players who share your passion
            for collecting and playing, creating a fun and engaging community experience. Learn more <a
              href="https://events.pokemon.com/en-us/tournaments/a238efa7-26c7-4371-90d0-2e8fbf54f949">here</a> </p>

        </section>
        <br></br><br></br>

        <section className="event">
          <h2><span style={{ fontWeight: "bold" }}>Portland Pokemon Regional Championships</span>
          </h2>
          <p>&#160;</p>
          <p><span style={{ fontWeight: "bold" }}>Event Date: <time>January 6, 2024</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> CC</p>
          <p>&#160;</p>

          <p> 5 Events will take place at the Portland convention center ranging from TCG to Pokemon GO, where enthusiasts
            of the Pokemon Trading Card Game and Pokemon video games can come together. Enjoy the company of fellow Pokemon
            players who share your passion for collecting and playing, creating a fun and engaging community experience.
            Learn more <a href="https://events.pokemon.com/en-us/group/46524c6f-dad5-44f9-a46c-2b566f49cbb6">here</a> </p>

        </section>
        <br></br><br></br>
        <section className="event">
          <h2><span style={{ fontWeight: "bold" }}>Comic-Con 2024 Registration Opens!</span></h2>
          <p>&#160;</p>
          <p><span style={{ fontWeight: "bold" }}>Event Date: <time>November 11, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> CC</p>
          <p>&#160;</p>

          <p>On November 18,2023 at 8:00AM PST the waiting rooms to buy your 2024 Comic-con pass will be available! For all
            the details, check out their offical site <a
              href="https://www.comic-con.org/toucan/open-registration-the-basics/">here</a> </p>


        </section>

        <br></br><br></br>
        <section className="event">
          <h2><span style={{ fontWeight: "bold" }}>Trading Card Game Con!</span></h2>
          <p>&#160;</p>
          <p><span style={{ fontWeight: "bold" }}>Event Date: <time>February 10, 2024</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> CC</p>
          <p>&#160;</p>

          <p>On February 10, 2024 Trading Card Game Con will be held in Huston! They will have a variety of TCGs including
            "Pokemon, Yu-Gi-Oh!, Magic: The Gathering, One Piece, Lorcana, Digimon, Dragon Ball Super, Cardfight!, Metazoo,
            Sports Cards, Funko POP!" Check out there webpage <a href="https://tcg-con.com/">here</a> </p>


        </section>

      </main >

    </>
  )
}

export default Events;
