import React from 'react'
import './rules.css';
import NavBar from './NavBar'


function Members() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Memberships</p>
      </header>
      <main className="rulesMain">
        <section className="scroll-box">
          <h3>Membership Perks</h3>
          <br></br>
          <h4>🫶$1: Friend Tier🫶</h4>
          <p>Members Only Username Color</p>
          <p>Our heartfelt thanks</p>
          <p>Your name on the thank you page</p>
          <p><a href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-43076693E2701324MMWXD6NQ">Join the Friend Tier</a></p>
          <br></br>
          <h4>✨$5: Fam Tier✨</h4>
          <p>Members Only Username Color</p>
          <p>Badge</p>
          <p>Emojis (in a future update)</p>
          <p>Entry Into an End of the Month Giveaway</p>
          <p>Your name on the thank you page </p>
          <p><a href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-6G243345V83495723MWXD3LI">Join the Fam Tier</a></p>
          <br></br>
          <h4>💎$10: Community Tier💎</h4>
          <p>Members Only Username Color</p>
          <p>Badge</p>
          <p>Emojis (in a future update)</p>
          <p>Entry Into an End of the Month Giveaway</p>
          <p>10 packs of all giveaway box breaks will be set aside just for members of this tier and above, given out raffle style. A member may win a max of 1 members pack per stream this way.</p>
          <p>(If there are fewer members than packs, everyone will receive one and the remaining ones raffled off. And you can still win packs from then holiday hunt game too.)</p>
          <p>(This will increase in the future as we get more members.)</p>
          <p>Your name on the Thank You page AND space for a small banner AD for your YouTube or Twitch channel</p>
          <p>Members only chat room (in a future update)</p>
          <p>A written thank you note from CC himself as well as something extra for those who ended the month without any wins.</p>
          <p><a href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-8BN738337R3523130MWXDR4I">Join the Community Tier</a></p>

        </section >
        <br></br>
        <section className="scroll-box">
          <h3 style={{ textAlign: "center" }}>Thank You to all our Members!</h3>
          <table style={{ marginLeft: "auto", marginRight: "auto", border: "none" }}>
            <tr style={{ border: "none" }}>
              <th style={{ border: "none" }}><h4>💎Community Tier Members💎</h4></th>
              <th style={{ border: "none" }}><h4>✨Fam Tier Members✨</h4></th>
              <th style={{ border: "none" }}><h4>🫶Friend Tier Members🫶</h4></th>
            </tr>
            <tr style={{ border: "none" }}>
              <td style={{ border: "none" }}>
                <p>SupremeSalamence <a href="https://www.youtube.com/@supremesalamence9546">YT</a></p>
                <p>Gren <a href="https://www.youtube.com/channel/UC_YBdTuqPFQeo-bYgLUH_sA">YT</a></p>
                <p>Savin Brass <a href="https://www.youtube.com/channel/UCFsycf8JH_pYJI9kAzOACGA">YT</a></p>
                <p>SuperViolinsADS <a href="https://www.youtube.com/channel/UCSzs9b9ljihwjYXSrF-h2iw">YT</a></p>
                <p>W3nderful <a href="https://www.youtube.com/channel/UCff2gkqvLLpxAfpPQfiWODA">YT</a></p>
                <p>Dadmonmce <a href="https://www.youtube.com/channel/UCwrlQl5Bne-0m7R3rHKjqkw">YT</a></p>
                <p>Pokemazing <a href="https://www.youtube.com/@POKEMAZING_">YT</a></p>
                <p>RavinWolf <a href="https://www.youtube.com/@alishaboudreau8453">YT</a></p>
                <p>Joseph theblastoise champ <a href="https://www.youtube.com/channel/UCm_jhtd5s38LirGxo5EPPaA">YT</a></p>
                <p>Rick VMAX <a href="https://www.youtube.com/@rickuti86">YT</a></p>
                <p>AnriSparce <a href="https://www.youtube.com/@EoUAnriSparceKurokaze">YT</a></p>
              </td>
              <td style={{ border: "none" }}>
                <p>TamFam</p>
                <p>Cactus</p>
              </td>
              <td style={{ border: "none" }}>
                <p>Jeff</p>
              </td>

            </tr>

          </table>
        </section>
      </main >

    </>
  )
}

export default Members;
