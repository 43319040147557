import React from 'react'
import './articles.css';
import NavBar from './NavBar'


function Article5_com() {

  return (
    <>
      <NavBar />
      <header>
        <p>Collecting<span>Community</span> Articles</p>
      </header>
      <main>
        <section className="content">
          <h2><span style={{ fontWeight: "bold" }}>ARTICLE:</span> Rating Pika Copies</h2>
          <p><span style={{ fontWeight: "bold" }}>on: <time datetime="2023-11-25">December 8, 2023</time></span></p>
          <p><span style={{ fontWeight: "bold" }}>Author:</span> Pokeviolins</p>
          <p>&#160;</p>
          <p>&#160;</p>
          <br></br>
          <p>This is by no means how much I like the Pokémon, I am rating them by how creative, special, and given originality to be slightly different from Pikachu. If this was how much I liked them this would be very different.
          </p>
          <br></br>
          <h4>Number 12: Dedenne</h4>
          <br></br>
          <p>When I give Dedenne last place, I'm not saying I don't like it. In fact, Dedenne is in my top 10 Pokémon of all time. In terms of pika-copies however, Dedenne is low. I love the idea of it being a fairy type, but it doesn't really look like one in any way. Also, in a way, Dedenne looks like a small Raichu. In terms of originality and creativity, Dedenne is last.
          </p>
          <br></br>
          <img
            src="https://assets.pokemon.com/assets/cms2/img/pokedex/full/702.png"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 11: Togedemaru</h4>
          <br></br>
          <p>Togedemaru is an awesome Pokémon that I love; however; I don't see how Togedemaru is a Pikachu copy in any way other than the cheeks. It is a great Pokémon, but to me, it doesn't deserve to be called a Pikachu copy.
          </p>
          <br></br>
          <img
            src="https://img.pokemondb.net/artwork/large/togedemaru.jpg"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 10: Pachirisu</h4>
          <br></br>
          <p>Once again there is nothing against Pachirisu, it's just that I don't see anything particularly special about it. The only great traits given to Pachirisu are its colors, and its long tail. Also, in my opinion other than Togedemaru, Pachirisu looks least like Pikachu.
          </p>
          <br></br>
          <img
            src="https://img.pokemondb.net/artwork/large/pachirisu.jpg"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 9: Pichu</h4>
          <br></br>
          <p>They did an amazing job making Pichu. Even though some wouldn't consider it a Pikachu copy, I do. Pichu really looks like it could be the pre evolution of Pikachu. It's not too similar to Pikachu making it basically a smaller one. It's also not too different from Pikachu, and that makes it great
          </p>
          <br></br>
          <img
            src="https://assets.pokemon.com/assets/cms2/img/pokedex/full/172.png"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 8/7: Plusle/Minun</h4>
          <br></br>
          <p>It really was a great idea to make Plusle and Minun, but when you actually look at the facts, their attacks are practically the same. Even though they're both electric types, I feel like they should have more to make the duo make sense. You can say that positive and negative are equal, which is true, but they needed to show that more with Plusle and Minun.
          </p>
          <br></br>
          <img
            src="https://static.wikia.nocookie.net/carly-pressman-friends/images/4/4e/Plusle_and_minun.png"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 6: Pawmot</h4>
          <br></br>
          <p>Pawmot is an amazing Pokémon, and nobody can convince me it isn't. The only problem is that I feel like it's slightly too different from Raichu which is why it's not higher. I love the whole concept and creativity of making another Pikachu chain, and that is a new idea which is also why Pawmot is not lower. (Higher means better and lower means worse)
          </p>
          <br></br>
          <img
            src="https://assets.pokemon.com/assets/cms2/img/pokedex/full/923.png"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 5: Pawmi</h4>
          <br></br>
          <p>Pawmi is my favorite pika copy, and also in my top ten Pokémon of all time, so now you know I'm not ranking it on how much I like them. I absolutely love the idea of a crawling pika copy. However, this evolution chain is supposed to be like Pichu, Pikachu, and Raichu. This might just be me, but when I see Pawnmi, I think of Pikachu instead of Pichu, and that is way Pawmi is not higher on this list.
          </p>
          <br></br>
          <img
            src="https://assets.pokemon.com/assets/cms2/img/pokedex/full/921.png"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 4: Pawmo</h4>
          <br></br>
          <p>Pawmo is a perfect copy of Pikachu. They made it so Pawmo looks like Pikachu, but has a bunch of its own things that make it original. Pawmo in my opinion is the perfect copy of Pikachu, but it has nothing outstanding to make it higher.
          </p>
          <br></br>
          <img
            src="https://assets.pokemon.com/assets/cms2/img/pokedex/full/922.png"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 3: Morpeko</h4>
          <br></br>
          <p>The idea to make a Pikachu copy with two forms of Full Belly mode, and a Hangry mode should win a prize. Morpeko is a great Pikachu copy without the second form, but that originality lands it extremely high on the list. Morpeko is a very fun and great Pokémon.
          </p>
          <br></br>
          <img
            src="https://img.pokemondb.net/artwork/large/morpeko-full-belly.jpg"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 2: Emolga</h4>
          <br></br>
          <p>The idea to make a gliding Pikachu copy struck my mind when I watched the anime. It's not that they just made a flying one, which would've been great, but they made one that glides. They elevated this idea to be not just flying, but even gliding. Emolga is also the cutest name that fits that type of Pokémon so well. They just did an amazing job.
          </p>
          <br></br>
          <img
            src="https://assets.pokemon.com/assets/cms2/img/pokedex/full/587.png"
            alt="" className="images" />
          <br></br><br></br>
          <h4>Number 1: Pikachu</h4>
          <br></br>
          <p> I don't know who decided to make a yellow mouse, with chubby red cheeks, and black ears the star of the company, but they did an amazing job. Pikachu has to be number 1 because without it, none of those other ones would even exist. When you ask someone who knows nothing about Pokémon to name some, Pikachu will always be first on the list. Without a doubt Pikachu rightfully takes the throne of first place.
          </p>
          <br></br>
          <img
            src="https://upload.wikimedia.org/wikipedia/en/a/a6/Pok%C3%A9mon_Pikachu_art.png"
            alt="" className="images" />
          <br></br><br></br>
          <h4>How much I like each pika copy (I absolutely love Pikachu, and all of the electric rodents) </h4>
          <br></br>
          <p>1st Pawmi.   In top 10 ever</p>
          <p>2nd Pichu.   In top 10 ever</p>
          <p>3rd Dedenne.    In top 10 ever</p>
          <p>4th Pachirisu.  In top 20 ever</p>
          <p>5th Pikachu.   In top 20 ever </p>
          <p>6th Emolga.    In top 30 ever</p>
          <p>7th Minun.     In top 30 ever</p>
          <p>8th Plusle.   In top 30 ever</p>
          <p>9th Pawmot. In top 50 ever</p>
          <p>10th Pawmo.  In top 50 ever</p>
          <p>11th Morpeko   Above 50</p>
          <p>12th Togedemaru   Above 50</p>
          <br></br>
          <p>I didn't include Raichu because I felt it shouldn't be included. It feels wrong to consider Raichu as a pika-copy when Pikachu could just be a random Pokémon at the time they made gen 1. However, these other ones were made specifically to be pika-copies. That's why.
          </p>
          <br></br>

        </section>
      </main>

    </>
  )
}

export default Article5_com;
